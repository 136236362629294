/*---------------------------------------*/
/*--------   Table of contents   --------*/
/*---------------------------------------*/
/*
1. External module
	1.1 Bootstrap
	1.2 Slick carousel
	1.4 Magnific Popup
	1.5 Bootstrap Datepicker
2. Fonts Icons
3. Variables
4. Typography
5. Header
6. Content layout
7. Gallery
8. News
9. Form
10. Modal
11. Back To Top
12. Footer

---------------------------------------*/
/*---------------------------------------*/
/*--------   1. External module  --------*/
/*---------------------------------------*/
/*-------  1.1 Bootstrap  --------*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 ----------------------------------------------------
@import "../external/bootstrap/scss/bootstrap.scss";
 */
 *,
 *::before,
 *::after {
   box-sizing: border-box;
 }
 
 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   -ms-overflow-style: scrollbar;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 @-ms-viewport {
   width: device-width;
 }
 article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
   display: block;
 }
 
 [tabIndex="-1"]:focus {
   outline: 0 !important;
 }
 
 hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
 }
 
 h1, h2, h3, h4, h5, h6 {
   margin-top: 0;
 }
 
 p {
   margin-top: 0;
 }
 
 abbr[title],
 abbr[data-original-title] {
   text-decoration: underline;
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   border-bottom: 0;
 }
 
 address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
 }
 
 ol,
 ul,
 dl {
   margin-top: 0;
 }
 
 ol ol,
 ul ul,
 ol ul,
 ul ol {
   margin-bottom: 0;
 }
 
 dt {
   font-weight: 700;
 }
 
 dd {
   margin-bottom: 0.5rem;
   margin-left: 0;
 }
 
 dfn {
   font-style: italic;
 }
 
 b,
 strong {
   font-weight: bolder;
 }
 
 small {
   font-size: 80%;
 }
 
 sub,
 sup {
   position: relative;
   font-size: 75%;
   line-height: 0;
   vertical-align: baseline;
 }
 
 a {
   color: #007bff;
   text-decoration: none;
   background-color: transparent;
   -webkit-text-decoration-skip: objects;
 }
 
 a:hover {
   color: #0056b3;
   text-decoration: underline;
 }
 
 a:not([href]):not([tabIndex]) {
   color: inherit;
   text-decoration: none;
 }
 
 a:not([href]):not([tabIndex]):hover, a:not([href]):not([tabIndex]):focus {
   color: inherit;
   text-decoration: none;
 }
 
 a:not([href]):not([tabIndex]):focus {
   outline: 0;
 }
 
 pre,
 code,
 kbd,
 samp {
   font-family: monospace, monospace;
   font-size: 1em;
 }
 
 pre {
   margin-top: 0;
   overflow: auto;
   -ms-overflow-style: scrollbar;
 }
 
 img {
   vertical-align: middle;
   border-style: none;
 }
 
 svg:not(:root) {
   overflow: hidden;
 }
 
 table {
   border-collapse: collapse;
 }
 
 caption {
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   color: #6c757d;
   text-align: left;
   caption-side: bottom;
 }
 
 th {
   text-align: inherit;
 }
 
 label {
   display: inline-block;
 }
 
 button {
   border-radius: 0;
 }
 
 button:focus {
   outline: 1px dotted;
   outline: 5px auto -webkit-focus-ring-color;
 }
 
 input,
 button,
 select,
 optgroup,
 textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
 }
 
 button,
 input {
   overflow: visible;
 }
 
 button,
 select {
   text-transform: none;
 }
 
 button,
 html [type=button],
 [type=reset],
 [type=submit] {
   -webkit-appearance: button;
 }
 
 button::-moz-focus-inner,
 [type=button]::-moz-focus-inner,
 [type=reset]::-moz-focus-inner,
 [type=submit]::-moz-focus-inner {
   padding: 0;
   border-style: none;
 }
 
 input[type=radio],
 input[type=checkbox] {
   box-sizing: border-box;
   padding: 0;
 }
 
 input[type=date],
 input[type=time],
 input[type=datetime-local],
 input[type=month] {
   -webkit-appearance: listbox;
 }
 
 textarea {
   overflow: auto;
   resize: vertical;
 }
 
 fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
 }
 
 legend {
   display: block;
   width: 100%;
   max-width: 100%;
   padding: 0;
   line-height: inherit;
   color: inherit;
   white-space: normal;
 }
 
 progress {
   vertical-align: baseline;
 }
 
 [type=number]::-webkit-inner-spin-button,
 [type=number]::-webkit-outer-spin-button {
   height: auto;
 }
 
 [type=search] {
   outline-offset: -2px;
   -webkit-appearance: none;
 }
 
 [type=search]::-webkit-search-cancel-button,
 [type=search]::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 
 ::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button;
 }
 
 output {
   display: inline-block;
 }
 
 summary {
   display: list-item;
   cursor: pointer;
 }
 
 template {
   display: none;
 }
 
 [hidden] {
   display: none !important;
 }
 
 .container {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
 }
 
 @media (min-width: 576px) {
   .container {
     max-width: 546px;
   }
 }
 @media (min-width: 791px) {
   .container {
     max-width: 770px;
   }
 }
 @media (min-width: 1025px) {
   .container {
     max-width: 990px;
   }
 }
 @media (min-width: 1240px) {
   .container {
     max-width: 1200px;
   }
 }
 .container-fluid {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
 }
 
 .row {
   display: flex;
   flex-wrap: wrap;
   margin-right: -15px;
   margin-left: -15px;
 }
 
 .no-gutters {
   margin-right: 0;
   margin-left: 0;
 }
 
 .no-gutters > .col,
 .no-gutters > [class*=col-] {
   padding-right: 0;
   padding-left: 0;
 }
 
 .col-xl,
 .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
 .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
 .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
 .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
 .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
   position: relative;
   width: 100%;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
 }
 
 .col {
   flex-basis: 0;
   flex-grow: 1;
   max-width: 100%;
 }
 
 .col-auto {
   flex: 0 0 auto;
   width: auto;
   max-width: none;
 }
 
 .col-1 {
   flex: 0 0 8.3333333333%;
   max-width: 8.3333333333%;
 }
 
 .col-2 {
   flex: 0 0 16.6666666667%;
   max-width: 16.6666666667%;
 }
 
 .col-3 {
   flex: 0 0 25%;
   max-width: 25%;
 }
 
 .col-4 {
   flex: 0 0 33.3333333333%;
   max-width: 33.3333333333%;
 }
 
 .col-5 {
   flex: 0 0 41.6666666667%;
   max-width: 41.6666666667%;
 }
 
 .col-6 {
   flex: 0 0 50%;
   max-width: 50%;
 }
 
 .col-7 {
   flex: 0 0 58.3333333333%;
   max-width: 58.3333333333%;
 }
 
 .col-8 {
   flex: 0 0 66.6666666667%;
   max-width: 66.6666666667%;
 }
 
 .col-9 {
   flex: 0 0 75%;
   max-width: 75%;
 }
 
 .col-10 {
   flex: 0 0 83.3333333333%;
   max-width: 83.3333333333%;
 }
 
 .col-11 {
   flex: 0 0 91.6666666667%;
   max-width: 91.6666666667%;
 }
 
 .col-12 {
   flex: 0 0 100%;
   max-width: 100%;
 }
 
 .order-first {
   order: -1;
 }
 
 .order-last {
   order: 13;
 }
 
 .order-0 {
   order: 0;
 }
 
 .order-1 {
   order: 1;
 }
 
 .order-2 {
   order: 2;
 }
 
 .order-3 {
   order: 3;
 }
 
 .order-4 {
   order: 4;
 }
 
 .order-5 {
   order: 5;
 }
 
 .order-6 {
   order: 6;
 }
 
 .order-7 {
   order: 7;
 }
 
 .order-8 {
   order: 8;
 }
 
 .order-9 {
   order: 9;
 }
 
 .order-10 {
   order: 10;
 }
 
 .order-11 {
   order: 11;
 }
 
 .order-12 {
   order: 12;
 }
 
 .offset-1 {
   margin-left: 8.3333333333%;
 }
 
 .offset-2 {
   margin-left: 16.6666666667%;
 }
 
 .offset-3 {
   margin-left: 25%;
 }
 
 .offset-4 {
   margin-left: 33.3333333333%;
 }
 
 .offset-5 {
   margin-left: 41.6666666667%;
 }
 
 .offset-6 {
   margin-left: 50%;
 }
 
 .offset-7 {
   margin-left: 58.3333333333%;
 }
 
 .offset-8 {
   margin-left: 66.6666666667%;
 }
 
 .offset-9 {
   margin-left: 75%;
 }
 
 .offset-10 {
   margin-left: 83.3333333333%;
 }
 
 .offset-11 {
   margin-left: 91.6666666667%;
 }
 
 @media (min-width: 576px) {
   .col-sm {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
 
   .col-sm-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: none;
   }
 
   .col-sm-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }
 
   .col-sm-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 
   .col-sm-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
 
   .col-sm-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }
 
   .col-sm-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }
 
   .col-sm-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
 
   .col-sm-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }
 
   .col-sm-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }
 
   .col-sm-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
 
   .col-sm-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }
 
   .col-sm-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }
 
   .col-sm-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .order-sm-first {
     order: -1;
   }
 
   .order-sm-last {
     order: 13;
   }
 
   .order-sm-0 {
     order: 0;
   }
 
   .order-sm-1 {
     order: 1;
   }
 
   .order-sm-2 {
     order: 2;
   }
 
   .order-sm-3 {
     order: 3;
   }
 
   .order-sm-4 {
     order: 4;
   }
 
   .order-sm-5 {
     order: 5;
   }
 
   .order-sm-6 {
     order: 6;
   }
 
   .order-sm-7 {
     order: 7;
   }
 
   .order-sm-8 {
     order: 8;
   }
 
   .order-sm-9 {
     order: 9;
   }
 
   .order-sm-10 {
     order: 10;
   }
 
   .order-sm-11 {
     order: 11;
   }
 
   .order-sm-12 {
     order: 12;
   }
 
   .offset-sm-0 {
     margin-left: 0;
   }
 
   .offset-sm-1 {
     margin-left: 8.3333333333%;
   }
 
   .offset-sm-2 {
     margin-left: 16.6666666667%;
   }
 
   .offset-sm-3 {
     margin-left: 25%;
   }
 
   .offset-sm-4 {
     margin-left: 33.3333333333%;
   }
 
   .offset-sm-5 {
     margin-left: 41.6666666667%;
   }
 
   .offset-sm-6 {
     margin-left: 50%;
   }
 
   .offset-sm-7 {
     margin-left: 58.3333333333%;
   }
 
   .offset-sm-8 {
     margin-left: 66.6666666667%;
   }
 
   .offset-sm-9 {
     margin-left: 75%;
   }
 
   .offset-sm-10 {
     margin-left: 83.3333333333%;
   }
 
   .offset-sm-11 {
     margin-left: 91.6666666667%;
   }
 }
 @media (min-width: 791px) {
   .col-md {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
 
   .col-md-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: none;
   }
 
   .col-md-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }
 
   .col-md-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 
   .col-md-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
 
   .col-md-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }
 
   .col-md-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }
 
   .col-md-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
 
   .col-md-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }
 
   .col-md-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }
 
   .col-md-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
 
   .col-md-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }
 
   .col-md-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }
 
   .col-md-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .order-md-first {
     order: -1;
   }
 
   .order-md-last {
     order: 13;
   }
 
   .order-md-0 {
     order: 0;
   }
 
   .order-md-1 {
     order: 1;
   }
 
   .order-md-2 {
     order: 2;
   }
 
   .order-md-3 {
     order: 3;
   }
 
   .order-md-4 {
     order: 4;
   }
 
   .order-md-5 {
     order: 5;
   }
 
   .order-md-6 {
     order: 6;
   }
 
   .order-md-7 {
     order: 7;
   }
 
   .order-md-8 {
     order: 8;
   }
 
   .order-md-9 {
     order: 9;
   }
 
   .order-md-10 {
     order: 10;
   }
 
   .order-md-11 {
     order: 11;
   }
 
   .order-md-12 {
     order: 12;
   }
 
   .offset-md-0 {
     margin-left: 0;
   }
 
   .offset-md-1 {
     margin-left: 8.3333333333%;
   }
 
   .offset-md-2 {
     margin-left: 16.6666666667%;
   }
 
   .offset-md-3 {
     margin-left: 25%;
   }
 
   .offset-md-4 {
     margin-left: 33.3333333333%;
   }
 
   .offset-md-5 {
     margin-left: 41.6666666667%;
   }
 
   .offset-md-6 {
     margin-left: 50%;
   }
 
   .offset-md-7 {
     margin-left: 58.3333333333%;
   }
 
   .offset-md-8 {
     margin-left: 66.6666666667%;
   }
 
   .offset-md-9 {
     margin-left: 75%;
   }
 
   .offset-md-10 {
     margin-left: 83.3333333333%;
   }
 
   .offset-md-11 {
     margin-left: 91.6666666667%;
   }
 }
 @media (min-width: 1025px) {
   .col-lg {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
 
   .col-lg-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: none;
   }
 
   .col-lg-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }
 
   .col-lg-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 
   .col-lg-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
 
   .col-lg-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }
 
   .col-lg-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }
 
   .col-lg-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
 
   .col-lg-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }
 
   .col-lg-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }
 
   .col-lg-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
 
   .col-lg-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }
 
   .col-lg-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }
 
   .col-lg-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .order-lg-first {
     order: -1;
   }
 
   .order-lg-last {
     order: 13;
   }
 
   .order-lg-0 {
     order: 0;
   }
 
   .order-lg-1 {
     order: 1;
   }
 
   .order-lg-2 {
     order: 2;
   }
 
   .order-lg-3 {
     order: 3;
   }
 
   .order-lg-4 {
     order: 4;
   }
 
   .order-lg-5 {
     order: 5;
   }
 
   .order-lg-6 {
     order: 6;
   }
 
   .order-lg-7 {
     order: 7;
   }
 
   .order-lg-8 {
     order: 8;
   }
 
   .order-lg-9 {
     order: 9;
   }
 
   .order-lg-10 {
     order: 10;
   }
 
   .order-lg-11 {
     order: 11;
   }
 
   .order-lg-12 {
     order: 12;
   }
 
   .offset-lg-0 {
     margin-left: 0;
   }
 
   .offset-lg-1 {
     margin-left: 8.3333333333%;
   }
 
   .offset-lg-2 {
     margin-left: 16.6666666667%;
   }
 
   .offset-lg-3 {
     margin-left: 25%;
   }
 
   .offset-lg-4 {
     margin-left: 33.3333333333%;
   }
 
   .offset-lg-5 {
     margin-left: 41.6666666667%;
   }
 
   .offset-lg-6 {
     margin-left: 50%;
   }
 
   .offset-lg-7 {
     margin-left: 58.3333333333%;
   }
 
   .offset-lg-8 {
     margin-left: 66.6666666667%;
   }
 
   .offset-lg-9 {
     margin-left: 75%;
   }
 
   .offset-lg-10 {
     margin-left: 83.3333333333%;
   }
 
   .offset-lg-11 {
     margin-left: 91.6666666667%;
   }
 }
 @media (min-width: 1240px) {
   .col-xl {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
 
   .col-xl-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: none;
   }
 
   .col-xl-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }
 
   .col-xl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 
   .col-xl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
 
   .col-xl-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }
 
   .col-xl-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }
 
   .col-xl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
 
   .col-xl-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }
 
   .col-xl-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }
 
   .col-xl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
 
   .col-xl-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }
 
   .col-xl-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }
 
   .col-xl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .order-xl-first {
     order: -1;
   }
 
   .order-xl-last {
     order: 13;
   }
 
   .order-xl-0 {
     order: 0;
   }
 
   .order-xl-1 {
     order: 1;
   }
 
   .order-xl-2 {
     order: 2;
   }
 
   .order-xl-3 {
     order: 3;
   }
 
   .order-xl-4 {
     order: 4;
   }
 
   .order-xl-5 {
     order: 5;
   }
 
   .order-xl-6 {
     order: 6;
   }
 
   .order-xl-7 {
     order: 7;
   }
 
   .order-xl-8 {
     order: 8;
   }
 
   .order-xl-9 {
     order: 9;
   }
 
   .order-xl-10 {
     order: 10;
   }
 
   .order-xl-11 {
     order: 11;
   }
 
   .order-xl-12 {
     order: 12;
   }
 
   .offset-xl-0 {
     margin-left: 0;
   }
 
   .offset-xl-1 {
     margin-left: 8.3333333333%;
   }
 
   .offset-xl-2 {
     margin-left: 16.6666666667%;
   }
 
   .offset-xl-3 {
     margin-left: 25%;
   }
 
   .offset-xl-4 {
     margin-left: 33.3333333333%;
   }
 
   .offset-xl-5 {
     margin-left: 41.6666666667%;
   }
 
   .offset-xl-6 {
     margin-left: 50%;
   }
 
   .offset-xl-7 {
     margin-left: 58.3333333333%;
   }
 
   .offset-xl-8 {
     margin-left: 66.6666666667%;
   }
 
   .offset-xl-9 {
     margin-left: 75%;
   }
 
   .offset-xl-10 {
     margin-left: 83.3333333333%;
   }
 
   .offset-xl-11 {
     margin-left: 91.6666666667%;
   }
 }
 .form-control {
   display: block;
   width: 100%;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 0;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }
 
 .form-control::-ms-expand {
   background-color: transparent;
   border: 0;
 }
 
 .form-control:focus {
   color: #495057;
   background-color: #fff;
   border-color: #80bdff;
   outline: 0;
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }
 
 .form-control::-moz-placeholder {
   color: #6c757d;
   opacity: 1;
 }
 
 .form-control:-ms-input-placeholder {
   color: #6c757d;
   opacity: 1;
 }
 
 .form-control::-webkit-input-placeholder {
   color: #6c757d;
   opacity: 1;
 }
 
 .form-control::-ms-input-placeholder {
   color: #6c757d;
   opacity: 1;
 }
 
 .form-control::placeholder {
   color: #6c757d;
   opacity: 1;
 }
 
 .form-control:disabled, .form-control[readonly] {
   background-color: #e9ecef;
   opacity: 1;
 }
 
 select.form-control:not([size]):not([multiple]) {
   height: calc(2.25rem + 2px);
 }
 
 select.form-control:focus::-ms-value {
   color: #495057;
   background-color: #fff;
 }
 
 .form-control-file,
 .form-control-range {
   display: block;
   width: 100%;
 }
 
 .col-form-label {
   padding-top: calc(0.375rem + 1px);
   padding-bottom: calc(0.375rem + 1px);
   margin-bottom: 0;
   font-size: inherit;
   line-height: 1.5;
 }
 
 .col-form-label-lg {
   padding-top: calc(0.5rem + 1px);
   padding-bottom: calc(0.5rem + 1px);
   font-size: 1.25rem;
   line-height: 1.5;
 }
 
 .col-form-label-sm {
   padding-top: calc(0.25rem + 1px);
   padding-bottom: calc(0.25rem + 1px);
   font-size: 0.875rem;
   line-height: 1.5;
 }
 
 .form-control-plaintext {
   display: block;
   width: 100%;
   padding-top: 0.375rem;
   padding-bottom: 0.375rem;
   margin-bottom: 0;
   line-height: 1.5;
   background-color: transparent;
   border: solid transparent;
   border-width: 1px 0;
 }
 
 .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
 .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
 .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
 .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
 .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
 .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
 .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
 .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
 .input-group-lg > .input-group-append > .form-control-plaintext.btn {
   padding-right: 0;
   padding-left: 0;
 }
 
 .form-control-sm, .input-group-sm > .form-control,
 .input-group-sm > .input-group-prepend > .input-group-text,
 .input-group-sm > .input-group-append > .input-group-text,
 .input-group-sm > .input-group-prepend > .btn,
 .input-group-sm > .input-group-append > .btn {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0;
 }
 
 select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
 .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
 .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
 .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
 .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
   height: calc(1.8125rem + 2px);
 }
 
 .form-control-lg, .input-group-lg > .form-control,
 .input-group-lg > .input-group-prepend > .input-group-text,
 .input-group-lg > .input-group-append > .input-group-text,
 .input-group-lg > .input-group-prepend > .btn,
 .input-group-lg > .input-group-append > .btn {
   padding: 0.5rem 1rem;
   font-size: 1.25rem;
   line-height: 1.5;
   border-radius: 0;
 }
 
 select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
 .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
 .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
 .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
 .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
   height: calc(2.875rem + 2px);
 }
 
 .form-group {
   margin-bottom: 1rem;
 }
 
 .form-text {
   display: block;
   margin-top: 0.25rem;
 }
 
 .form-row {
   display: flex;
   flex-wrap: wrap;
   margin-right: -5px;
   margin-left: -5px;
 }
 
 .form-row > .col,
 .form-row > [class*=col-] {
   padding-right: 5px;
   padding-left: 5px;
 }
 
 .form-check {
   position: relative;
   display: block;
   padding-left: 1.25rem;
 }
 
 .form-check-input {
   position: absolute;
   margin-top: 0.3rem;
   margin-left: -1.25rem;
 }
 
 .form-check-input:disabled ~ .form-check-label {
   color: #6c757d;
 }
 
 .form-check-label {
   margin-bottom: 0;
 }
 
 .form-check-inline {
   display: inline-flex;
   align-items: center;
   padding-left: 0;
   margin-right: 0.75rem;
 }
 
 .form-check-inline .form-check-input {
   position: static;
   margin-top: 0;
   margin-right: 0.3125rem;
   margin-left: 0;
 }
 
 .valid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #28a745;
 }
 
 .valid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.5rem;
   margin-top: 0.1rem;
   font-size: 0.875rem;
   line-height: 1;
   color: #fff;
   background-color: rgba(40, 167, 69, 0.8);
   border-radius: 0.2rem;
 }
 
 .was-validated .form-control:valid, .form-control.is-valid,
 .was-validated .custom-select:valid,
 .custom-select.is-valid {
   border-color: #28a745;
 }
 
 .was-validated .form-control:valid:focus, .form-control.is-valid:focus,
 .was-validated .custom-select:valid:focus,
 .custom-select.is-valid:focus {
   border-color: #28a745;
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
 }
 
 .was-validated .form-control:valid ~ .valid-feedback,
 .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
 .form-control.is-valid ~ .valid-tooltip,
 .was-validated .custom-select:valid ~ .valid-feedback,
 .was-validated .custom-select:valid ~ .valid-tooltip,
 .custom-select.is-valid ~ .valid-feedback,
 .custom-select.is-valid ~ .valid-tooltip {
   display: block;
 }
 
 .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
   color: #28a745;
 }
 
 .was-validated .form-check-input:valid ~ .valid-feedback,
 .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
 .form-check-input.is-valid ~ .valid-tooltip {
   display: block;
 }
 
 .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
   color: #28a745;
 }
 
 .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
   background-color: #71dd8a;
 }
 
 .was-validated .custom-control-input:valid ~ .valid-feedback,
 .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
 .custom-control-input.is-valid ~ .valid-tooltip {
   display: block;
 }
 
 .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
   background-color: #34ce57;
 }
 
 .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
 }
 
 .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
   border-color: #28a745;
 }
 
 .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
   border-color: inherit;
 }
 
 .was-validated .custom-file-input:valid ~ .valid-feedback,
 .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
 .custom-file-input.is-valid ~ .valid-tooltip {
   display: block;
 }
 
 .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
 }
 
 .invalid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
 }
 
 .invalid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.5rem;
   margin-top: 0.1rem;
   font-size: 0.875rem;
   line-height: 1;
   color: #fff;
   background-color: rgba(220, 53, 69, 0.8);
   border-radius: 0.2rem;
 }
 
 .was-validated .form-control:invalid, .form-control.is-invalid,
 .was-validated .custom-select:invalid,
 .custom-select.is-invalid {
   border-color: #dc3545;
 }
 
 .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
 .was-validated .custom-select:invalid:focus,
 .custom-select.is-invalid:focus {
   border-color: #dc3545;
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
 }
 
 .was-validated .form-control:invalid ~ .invalid-feedback,
 .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
 .form-control.is-invalid ~ .invalid-tooltip,
 .was-validated .custom-select:invalid ~ .invalid-feedback,
 .was-validated .custom-select:invalid ~ .invalid-tooltip,
 .custom-select.is-invalid ~ .invalid-feedback,
 .custom-select.is-invalid ~ .invalid-tooltip {
   display: block;
 }
 
 .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
   color: #dc3545;
 }
 
 .was-validated .form-check-input:invalid ~ .invalid-feedback,
 .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
 .form-check-input.is-invalid ~ .invalid-tooltip {
   display: block;
 }
 
 .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
   color: #dc3545;
 }
 
 .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
   background-color: #efa2a9;
 }
 
 .was-validated .custom-control-input:invalid ~ .invalid-feedback,
 .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
 .custom-control-input.is-invalid ~ .invalid-tooltip {
   display: block;
 }
 
 .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
   background-color: #e4606d;
 }
 
 .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
 }
 
 .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
   border-color: #dc3545;
 }
 
 .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
   border-color: inherit;
 }
 
 .was-validated .custom-file-input:invalid ~ .invalid-feedback,
 .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
 .custom-file-input.is-invalid ~ .invalid-tooltip {
   display: block;
 }
 
 .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
 }
 
 .form-inline {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
 }
 
 .form-inline .form-check {
   width: 100%;
 }
 
 @media (min-width: 576px) {
   .form-inline label {
     display: flex;
     align-items: center;
     justify-content: center;
     margin-bottom: 0;
   }
 
   .form-inline .form-group {
     display: flex;
     flex: 0 0 auto;
     flex-flow: row wrap;
     align-items: center;
     margin-bottom: 0;
   }
 
   .form-inline .form-control {
     display: inline-block;
     width: auto;
     vertical-align: middle;
   }
 
   .form-inline .form-control-plaintext {
     display: inline-block;
   }
 
   .form-inline .input-group {
     width: auto;
   }
 
   .form-inline .form-check {
     display: flex;
     align-items: center;
     justify-content: center;
     width: auto;
     padding-left: 0;
   }
 
   .form-inline .form-check-input {
     position: relative;
     margin-top: 0;
     margin-right: 0.25rem;
     margin-left: 0;
   }
 
   .form-inline .custom-control {
     align-items: center;
     justify-content: center;
   }
 
   .form-inline .custom-control-label {
     margin-bottom: 0;
   }
 }
 .btn {
   display: inline-block;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: 0;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }
 
 .btn:hover, .btn:focus {
   text-decoration: none;
 }
 
 .btn:focus, .btn.focus {
   outline: 0;
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }
 
 .btn.disabled, .btn:disabled {
   opacity: 0.65;
 }
 
 .btn:not(:disabled):not(.disabled) {
   cursor: pointer;
 }
 
 .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
   background-image: none;
 }
 
 a.btn.disabled,
 fieldset:disabled a.btn {
   pointer-events: none;
 }
 
 .btn-primary {
   color: #fff;
   background-color: #007bff;
   border-color: #007bff;
 }
 
 .btn-primary:hover {
   color: #fff;
   background-color: #0069d9;
   border-color: #0062cc;
 }
 
 .btn-primary:focus, .btn-primary.focus {
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
 }
 
 .btn-primary.disabled, .btn-primary:disabled {
   color: #fff;
   background-color: #007bff;
   border-color: #007bff;
 }
 
 .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
   color: #fff;
   background-color: #0062cc;
   border-color: #005cbf;
 }
 
 .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
 }
 
 .btn-secondary {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-secondary:hover {
   color: #fff;
   background-color: #5a6268;
   border-color: #545b62;
 }
 
 .btn-secondary:focus, .btn-secondary.focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }
 
 .btn-secondary.disabled, .btn-secondary:disabled {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
   color: #fff;
   background-color: #545b62;
   border-color: #4e555b;
 }
 
 .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }
 
 .btn-success {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-success:hover {
   color: #fff;
   background-color: #218838;
   border-color: #1e7e34;
 }
 
 .btn-success:focus, .btn-success.focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }
 
 .btn-success.disabled, .btn-success:disabled {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
   color: #fff;
   background-color: #1e7e34;
   border-color: #1c7430;
 }
 
 .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }
 
 .btn-info {
   color: #fff;
   background-color: #17a2b8;
   border-color: #17a2b8;
 }
 
 .btn-info:hover {
   color: #fff;
   background-color: #138496;
   border-color: #117a8b;
 }
 
 .btn-info:focus, .btn-info.focus {
   box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
 }
 
 .btn-info.disabled, .btn-info:disabled {
   color: #fff;
   background-color: #17a2b8;
   border-color: #17a2b8;
 }
 
 .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
   color: #fff;
   background-color: #117a8b;
   border-color: #10707f;
 }
 
 .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
 }
 
 .btn-warning {
   color: #212529;
   background-color: #ffc107;
   border-color: #ffc107;
 }
 
 .btn-warning:hover {
   color: #212529;
   background-color: #e0a800;
   border-color: #d39e00;
 }
 
 .btn-warning:focus, .btn-warning.focus {
   box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
 }
 
 .btn-warning.disabled, .btn-warning:disabled {
   color: #212529;
   background-color: #ffc107;
   border-color: #ffc107;
 }
 
 .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
   color: #212529;
   background-color: #d39e00;
   border-color: #c69500;
 }
 
 .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
 }
 
 .btn-danger {
   color: #fff;
   background-color: #dc3545;
   border-color: #dc3545;
 }
 
 .btn-danger:hover {
   color: #fff;
   background-color: #c82333;
   border-color: #bd2130;
 }
 
 .btn-danger:focus, .btn-danger.focus {
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
 }
 
 .btn-danger.disabled, .btn-danger:disabled {
   color: #fff;
   background-color: #dc3545;
   border-color: #dc3545;
 }
 
 .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
   color: #fff;
   background-color: #bd2130;
   border-color: #b21f2d;
 }
 
 .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
 }
 
 .btn-light {
   color: #212529;
   background-color: #f8f9fa;
   border-color: #f8f9fa;
 }
 
 .btn-light:hover {
   color: #212529;
   background-color: #e2e6ea;
   border-color: #dae0e5;
 }
 
 .btn-light:focus, .btn-light.focus {
   box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
 }
 
 .btn-light.disabled, .btn-light:disabled {
   color: #212529;
   background-color: #f8f9fa;
   border-color: #f8f9fa;
 }
 
 .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
   color: #212529;
   background-color: #dae0e5;
   border-color: #d3d9df;
 }
 
 .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
 }
 
 .btn-dark {
   color: #fff;
   background-color: #343a40;
   border-color: #343a40;
 }
 
 .btn-dark:hover {
   color: #fff;
   background-color: #23272b;
   border-color: #1d2124;
 }
 
 .btn-dark:focus, .btn-dark.focus {
   box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
 }
 
 .btn-dark.disabled, .btn-dark:disabled {
   color: #fff;
   background-color: #343a40;
   border-color: #343a40;
 }
 
 .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
   color: #fff;
   background-color: #1d2124;
   border-color: #171a1d;
 }
 
 .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
 }
 
 .btn-outline-primary {
   color: #007bff;
   background-color: transparent;
   background-image: none;
   border-color: #007bff;
 }
 
 .btn-outline-primary:hover {
   color: #fff;
   background-color: #007bff;
   border-color: #007bff;
 }
 
 .btn-outline-primary:focus, .btn-outline-primary.focus {
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
 }
 
 .btn-outline-primary.disabled, .btn-outline-primary:disabled {
   color: #007bff;
   background-color: transparent;
 }
 
 .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
   color: #fff;
   background-color: #007bff;
   border-color: #007bff;
 }
 
 .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
 }
 
 .btn-outline-secondary {
   color: #6c757d;
   background-color: transparent;
   background-image: none;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:hover {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:focus, .btn-outline-secondary.focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }
 
 .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
   color: #6c757d;
   background-color: transparent;
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
 }
 
 .btn-outline-success {
   color: #28a745;
   background-color: transparent;
   background-image: none;
   border-color: #28a745;
 }
 
 .btn-outline-success:hover {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-outline-success:focus, .btn-outline-success.focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }
 
 .btn-outline-success.disabled, .btn-outline-success:disabled {
   color: #28a745;
   background-color: transparent;
 }
 
 .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
   color: #fff;
   background-color: #28a745;
   border-color: #28a745;
 }
 
 .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
 }
 
 .btn-outline-info {
   color: #17a2b8;
   background-color: transparent;
   background-image: none;
   border-color: #17a2b8;
 }
 
 .btn-outline-info:hover {
   color: #fff;
   background-color: #17a2b8;
   border-color: #17a2b8;
 }
 
 .btn-outline-info:focus, .btn-outline-info.focus {
   box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
 }
 
 .btn-outline-info.disabled, .btn-outline-info:disabled {
   color: #17a2b8;
   background-color: transparent;
 }
 
 .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
   color: #fff;
   background-color: #17a2b8;
   border-color: #17a2b8;
 }
 
 .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
 }
 
 .btn-outline-warning {
   color: #ffc107;
   background-color: transparent;
   background-image: none;
   border-color: #ffc107;
 }
 
 .btn-outline-warning:hover {
   color: #212529;
   background-color: #ffc107;
   border-color: #ffc107;
 }
 
 .btn-outline-warning:focus, .btn-outline-warning.focus {
   box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
 }
 
 .btn-outline-warning.disabled, .btn-outline-warning:disabled {
   color: #ffc107;
   background-color: transparent;
 }
 
 .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
   color: #212529;
   background-color: #ffc107;
   border-color: #ffc107;
 }
 
 .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
 }
 
 .btn-outline-danger {
   color: #dc3545;
   background-color: transparent;
   background-image: none;
   border-color: #dc3545;
 }
 
 .btn-outline-danger:hover {
   color: #fff;
   background-color: #dc3545;
   border-color: #dc3545;
 }
 
 .btn-outline-danger:focus, .btn-outline-danger.focus {
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
 }
 
 .btn-outline-danger.disabled, .btn-outline-danger:disabled {
   color: #dc3545;
   background-color: transparent;
 }
 
 .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
   color: #fff;
   background-color: #dc3545;
   border-color: #dc3545;
 }
 
 .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
 }
 
 .btn-outline-light {
   color: #f8f9fa;
   background-color: transparent;
   background-image: none;
   border-color: #f8f9fa;
 }
 
 .btn-outline-light:hover {
   color: #212529;
   background-color: #f8f9fa;
   border-color: #f8f9fa;
 }
 
 .btn-outline-light:focus, .btn-outline-light.focus {
   box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
 }
 
 .btn-outline-light.disabled, .btn-outline-light:disabled {
   color: #f8f9fa;
   background-color: transparent;
 }
 
 .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
   color: #212529;
   background-color: #f8f9fa;
   border-color: #f8f9fa;
 }
 
 .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
 }
 
 .btn-outline-dark {
   color: #343a40;
   background-color: transparent;
   background-image: none;
   border-color: #343a40;
 }
 
 .btn-outline-dark:hover {
   color: #fff;
   background-color: #343a40;
   border-color: #343a40;
 }
 
 .btn-outline-dark:focus, .btn-outline-dark.focus {
   box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
 }
 
 .btn-outline-dark.disabled, .btn-outline-dark:disabled {
   color: #343a40;
   background-color: transparent;
 }
 
 .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
   color: #fff;
   background-color: #343a40;
   border-color: #343a40;
 }
 
 .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
   box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
 }
 
 .btn-link {
   font-weight: 400;
   color: #007bff;
   background-color: transparent;
 }
 
 .btn-link:hover {
   color: #0056b3;
   text-decoration: underline;
   background-color: transparent;
   border-color: transparent;
 }
 
 .btn-link:focus, .btn-link.focus {
   text-decoration: underline;
   border-color: transparent;
   box-shadow: none;
 }
 
 .btn-link:disabled, .btn-link.disabled {
   color: #6c757d;
 }
 
 .btn-lg {
   padding: 0.5rem 1rem;
   font-size: 1.25rem;
   line-height: 1.5;
   border-radius: 0;
 }
 
 .btn-sm {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0;
 }
 
 .btn-block {
   display: block;
   width: 100%;
 }
 
 .btn-block + .btn-block {
   margin-top: 0.5rem;
 }
 
 input[type=submit].btn-block,
 input[type=reset].btn-block,
 input[type=button].btn-block {
   width: 100%;
 }
 
 .input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%;
 }
 
 .input-group > .form-control,
 .input-group > .custom-select,
 .input-group > .custom-file {
   position: relative;
   flex: 1 1 auto;
   width: 1%;
   margin-bottom: 0;
 }
 
 .input-group > .form-control:focus,
 .input-group > .custom-select:focus,
 .input-group > .custom-file:focus {
   z-index: 3;
 }
 
 .input-group > .form-control + .form-control,
 .input-group > .form-control + .custom-select,
 .input-group > .form-control + .custom-file,
 .input-group > .custom-select + .form-control,
 .input-group > .custom-select + .custom-select,
 .input-group > .custom-select + .custom-file,
 .input-group > .custom-file + .form-control,
 .input-group > .custom-file + .custom-select,
 .input-group > .custom-file + .custom-file {
   margin-left: -1px;
 }
 
 .input-group > .form-control:not(:last-child),
 .input-group > .custom-select:not(:last-child) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 
 .input-group > .form-control:not(:first-child),
 .input-group > .custom-select:not(:first-child) {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }
 
 .input-group > .custom-file {
   display: flex;
   align-items: center;
 }
 
 .input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::before {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 
 .input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label::before {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }
 
 .input-group-prepend,
 .input-group-append {
   display: flex;
 }
 
 .input-group-prepend .btn,
 .input-group-append .btn {
   position: relative;
   z-index: 2;
 }
 
 .input-group-prepend .btn + .btn,
 .input-group-prepend .btn + .input-group-text,
 .input-group-prepend .input-group-text + .input-group-text,
 .input-group-prepend .input-group-text + .btn,
 .input-group-append .btn + .btn,
 .input-group-append .btn + .input-group-text,
 .input-group-append .input-group-text + .input-group-text,
 .input-group-append .input-group-text + .btn {
   margin-left: -1px;
 }
 
 .input-group-prepend {
   margin-right: -1px;
 }
 
 .input-group-append {
   margin-left: -1px;
 }
 
 .input-group-text {
   display: flex;
   align-items: center;
   padding: 0.375rem 0.75rem;
   margin-bottom: 0;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   text-align: center;
   white-space: nowrap;
   background-color: #e9ecef;
   border: 1px solid #ced4da;
   border-radius: 0;
 }
 
 .input-group-text input[type=radio],
 .input-group-text input[type=checkbox] {
   margin-top: 0;
 }
 
 .input-group > .input-group-prepend > .btn,
 .input-group > .input-group-prepend > .input-group-text,
 .input-group > .input-group-append:not(:last-child) > .btn,
 .input-group > .input-group-append:not(:last-child) > .input-group-text,
 .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
 .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 
 .input-group > .input-group-append > .btn,
 .input-group > .input-group-append > .input-group-text,
 .input-group > .input-group-prepend:not(:first-child) > .btn,
 .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
 .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
 .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }
 
 .modal-open {
   overflow: hidden;
 }
 
 .modal {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 25;
   display: none;
   overflow: hidden;
   outline: 0;
 }
 
 .modal-open .modal {
   overflow-x: hidden;
   overflow-y: auto;
 }
 
 .modal-dialog {
   position: relative;
   width: auto;
   margin: 10px;
   pointer-events: none;
 }
 
 .modal.fade .modal-dialog {
   transition: -webkit-transform 0.3s ease-out;
   transition: transform 0.3s ease-out;
   transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
   -webkit-transform: translate(0, -25%);
   transform: translate(0, -25%);
 }
 
 .modal.show .modal-dialog {
   -webkit-transform: translate(0, 0);
   transform: translate(0, 0);
 }
 
 .modal-dialog-centered {
   display: flex;
   align-items: center;
   min-height: calc(100% - 20px);
 }
 
 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: transparent;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0;
   outline: 0;
 }
 
 .modal-backdrop {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 24;
   background-color: #000;
 }
 
 .modal-backdrop.fade {
   opacity: 0;
 }
 
 .modal-backdrop.show {
   opacity: 0.5;
 }
 
 .modal-header {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   padding: 0;
   border-bottom: 1px solid #e9ecef;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }
 
 .modal-header .close {
   padding: 0;
   margin: 0 0 0 auto;
 }
 
 .modal-title {
   margin-bottom: 0;
   line-height: 1.5;
 }
 
 .modal-body {
   position: relative;
   flex: 1 1 auto;
   padding: 15px;
 }
 
 .modal-footer {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding: 15px;
   border-top: 1px solid #e9ecef;
 }
 
 .modal-footer > :not(:first-child) {
   margin-left: 0.25rem;
 }
 
 .modal-footer > :not(:last-child) {
   margin-right: 0.25rem;
 }
 
 .modal-scrollbar-measure {
   position: absolute;
   top: -9999px;
   width: 50px;
   height: 50px;
   overflow: scroll;
 }
 
 @media (min-width: 576px) {
   .modal-dialog {
     max-width: 500px;
     margin: 30px auto;
   }
 
   .modal-dialog-centered {
     min-height: calc(100% - 60px);
   }
 
   .modal-sm {
     max-width: 300px;
   }
 }
 @media (min-width: 1025px) {
   .modal-lg {
     max-width: 800px;
   }
 }
 .align-baseline {
   vertical-align: baseline !important;
 }
 
 .align-top {
   vertical-align: top !important;
 }
 
 .align-middle {
   vertical-align: middle !important;
 }
 
 .align-bottom {
   vertical-align: bottom !important;
 }
 
 .align-text-bottom {
   vertical-align: text-bottom !important;
 }
 
 .align-text-top {
   vertical-align: text-top !important;
 }
 
 .bg-primary {
   background-color: #007bff !important;
 }
 
 a.bg-primary:hover, a.bg-primary:focus,
 button.bg-primary:hover,
 button.bg-primary:focus {
   background-color: #0062cc !important;
 }
 
 .bg-secondary {
   background-color: #6c757d !important;
 }
 
 a.bg-secondary:hover, a.bg-secondary:focus,
 button.bg-secondary:hover,
 button.bg-secondary:focus {
   background-color: #545b62 !important;
 }
 
 .bg-success {
   background-color: #28a745 !important;
 }
 
 a.bg-success:hover, a.bg-success:focus,
 button.bg-success:hover,
 button.bg-success:focus {
   background-color: #1e7e34 !important;
 }
 
 .bg-info {
   background-color: #17a2b8 !important;
 }
 
 a.bg-info:hover, a.bg-info:focus,
 button.bg-info:hover,
 button.bg-info:focus {
   background-color: #117a8b !important;
 }
 
 .bg-warning {
   background-color: #ffc107 !important;
 }
 
 a.bg-warning:hover, a.bg-warning:focus,
 button.bg-warning:hover,
 button.bg-warning:focus {
   background-color: #d39e00 !important;
 }
 
 .bg-danger {
   background-color: #dc3545 !important;
 }
 
 a.bg-danger:hover, a.bg-danger:focus,
 button.bg-danger:hover,
 button.bg-danger:focus {
   background-color: #bd2130 !important;
 }
 
 .bg-light {
   background-color: #f8f9fa !important;
 }
 
 a.bg-light:hover, a.bg-light:focus,
 button.bg-light:hover,
 button.bg-light:focus {
   background-color: #dae0e5 !important;
 }
 
 .bg-dark {
   background-color: #343a40 !important;
 }
 
 a.bg-dark:hover, a.bg-dark:focus,
 button.bg-dark:hover,
 button.bg-dark:focus {
   background-color: #1d2124 !important;
 }
 
 .bg-white {
   background-color: #fff !important;
 }
 
 .bg-transparent {
   background-color: transparent !important;
 }
 
 .border {
   border: 1px solid #dee2e6 !important;
 }
 
 .border-top {
   border-top: 1px solid #dee2e6 !important;
 }
 
 .border-right {
   border-right: 1px solid #dee2e6 !important;
 }
 
 .border-bottom {
   border-bottom: 1px solid #dee2e6 !important;
 }
 
 .border-left {
   border-left: 1px solid #dee2e6 !important;
 }
 
 .border-0 {
   border: 0 !important;
 }
 
 .border-top-0 {
   border-top: 0 !important;
 }
 
 .border-right-0 {
   border-right: 0 !important;
 }
 
 .border-bottom-0 {
   border-bottom: 0 !important;
 }
 
 .border-left-0 {
   border-left: 0 !important;
 }
 
 .border-primary {
   border-color: #007bff !important;
 }
 
 .border-secondary {
   border-color: #6c757d !important;
 }
 
 .border-success {
   border-color: #28a745 !important;
 }
 
 .border-info {
   border-color: #17a2b8 !important;
 }
 
 .border-warning {
   border-color: #ffc107 !important;
 }
 
 .border-danger {
   border-color: #dc3545 !important;
 }
 
 .border-light {
   border-color: #f8f9fa !important;
 }
 
 .border-dark {
   border-color: #343a40 !important;
 }
 
 .border-white {
   border-color: #fff !important;
 }
 
 .rounded {
   border-radius: 0 !important;
 }
 
 .rounded-top {
   border-top-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
 }
 
 .rounded-right {
   border-top-right-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
 }
 
 .rounded-bottom {
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
 }
 
 .rounded-left {
   border-top-left-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
 }
 
 .rounded-circle {
   border-radius: 50% !important;
 }
 
 .rounded-0 {
   border-radius: 0 !important;
 }
 
 .clearfix::after {
   display: block;
   clear: both;
   content: "";
 }
 
 .d-none {
   display: none !important;
 }
 
 .d-inline {
   display: inline !important;
 }
 
 .d-inline-block {
   display: inline-block !important;
 }
 
 .d-block {
   display: block !important;
 }
 
 .d-table {
   display: table !important;
 }
 
 .d-table-row {
   display: table-row !important;
 }
 
 .d-table-cell {
   display: table-cell !important;
 }
 
 .d-flex {
   display: flex !important;
 }
 
 .d-inline-flex {
   display: inline-flex !important;
 }
 
 @media (min-width: 576px) {
   .d-sm-none {
     display: none !important;
   }
 
   .d-sm-inline {
     display: inline !important;
   }
 
   .d-sm-inline-block {
     display: inline-block !important;
   }
 
   .d-sm-block {
     display: block !important;
   }
 
   .d-sm-table {
     display: table !important;
   }
 
   .d-sm-table-row {
     display: table-row !important;
   }
 
   .d-sm-table-cell {
     display: table-cell !important;
   }
 
   .d-sm-flex {
     display: flex !important;
   }
 
   .d-sm-inline-flex {
     display: inline-flex !important;
   }
 }
 @media (min-width: 791px) {
   .d-md-none {
     display: none !important;
   }
 
   .d-md-inline {
     display: inline !important;
   }
 
   .d-md-inline-block {
     display: inline-block !important;
   }
 
   .d-md-block {
     display: block !important;
   }
 
   .d-md-table {
     display: table !important;
   }
 
   .d-md-table-row {
     display: table-row !important;
   }
 
   .d-md-table-cell {
     display: table-cell !important;
   }
 
   .d-md-flex {
     display: flex !important;
   }
 
   .d-md-inline-flex {
     display: inline-flex !important;
   }
 }
 @media (min-width: 1025px) {
   .d-lg-none {
     display: none !important;
   }
 
   .d-lg-inline {
     display: inline !important;
   }
 
   .d-lg-inline-block {
     display: inline-block !important;
   }
 
   .d-lg-block {
     display: block !important;
   }
 
   .d-lg-table {
     display: table !important;
   }
 
   .d-lg-table-row {
     display: table-row !important;
   }
 
   .d-lg-table-cell {
     display: table-cell !important;
   }
 
   .d-lg-flex {
     display: flex !important;
   }
 
   .d-lg-inline-flex {
     display: inline-flex !important;
   }
 }
 @media (min-width: 1240px) {
   .d-xl-none {
     display: none !important;
   }
 
   .d-xl-inline {
     display: inline !important;
   }
 
   .d-xl-inline-block {
     display: inline-block !important;
   }
 
   .d-xl-block {
     display: block !important;
   }
 
   .d-xl-table {
     display: table !important;
   }
 
   .d-xl-table-row {
     display: table-row !important;
   }
 
   .d-xl-table-cell {
     display: table-cell !important;
   }
 
   .d-xl-flex {
     display: flex !important;
   }
 
   .d-xl-inline-flex {
     display: inline-flex !important;
   }
 }
 @media print {
   .d-print-none {
     display: none !important;
   }
 
   .d-print-inline {
     display: inline !important;
   }
 
   .d-print-inline-block {
     display: inline-block !important;
   }
 
   .d-print-block {
     display: block !important;
   }
 
   .d-print-table {
     display: table !important;
   }
 
   .d-print-table-row {
     display: table-row !important;
   }
 
   .d-print-table-cell {
     display: table-cell !important;
   }
 
   .d-print-flex {
     display: flex !important;
   }
 
   .d-print-inline-flex {
     display: inline-flex !important;
   }
 }
 .embed-responsive {
   position: relative;
   display: block;
   width: 100%;
   padding: 0;
   overflow: hidden;
 }
 
 .embed-responsive::before {
   display: block;
   content: "";
 }
 
 .embed-responsive .embed-responsive-item,
 .embed-responsive iframe,
 .embed-responsive embed,
 .embed-responsive object,
 .embed-responsive video {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: 0;
 }
 
 .embed-responsive-21by9::before {
   padding-top: 42.8571428571%;
 }
 
 .embed-responsive-16by9::before {
   padding-top: 56.25%;
 }
 
 .embed-responsive-4by3::before {
   padding-top: 75%;
 }
 
 .embed-responsive-1by1::before {
   padding-top: 100%;
 }
 
 .flex-row {
   flex-direction: row !important;
 }
 
 .flex-column {
   flex-direction: column !important;
 }
 
 .flex-row-reverse {
   flex-direction: row-reverse !important;
 }
 
 .flex-column-reverse {
   flex-direction: column-reverse !important;
 }
 
 .flex-wrap {
   flex-wrap: wrap !important;
 }
 
 .flex-nowrap {
   flex-wrap: nowrap !important;
 }
 
 .flex-wrap-reverse {
   flex-wrap: wrap-reverse !important;
 }
 
 .justify-content-start {
   justify-content: flex-start !important;
 }
 
 .justify-content-end {
   justify-content: flex-end !important;
 }
 
 .justify-content-center {
   justify-content: center !important;
 }
 
 .justify-content-between {
   justify-content: space-between !important;
 }
 
 .justify-content-around {
   justify-content: space-around !important;
 }
 
 .align-items-start {
   align-items: flex-start !important;
 }
 
 .align-items-end {
   align-items: flex-end !important;
 }
 
 .align-items-center {
   align-items: center !important;
 }
 
 .align-items-baseline {
   align-items: baseline !important;
 }
 
 .align-items-stretch {
   align-items: stretch !important;
 }
 
 .align-content-start {
   align-content: flex-start !important;
 }
 
 .align-content-end {
   align-content: flex-end !important;
 }
 
 .align-content-center {
   align-content: center !important;
 }
 
 .align-content-between {
   align-content: space-between !important;
 }
 
 .align-content-around {
   align-content: space-around !important;
 }
 
 .align-content-stretch {
   align-content: stretch !important;
 }
 
 .align-self-auto {
   align-self: auto !important;
 }
 
 .align-self-start {
   align-self: flex-start !important;
 }
 
 .align-self-end {
   align-self: flex-end !important;
 }
 
 .align-self-center {
   align-self: center !important;
 }
 
 .align-self-baseline {
   align-self: baseline !important;
 }
 
 .align-self-stretch {
   align-self: stretch !important;
 }
 
 @media (min-width: 576px) {
   .flex-sm-row {
     flex-direction: row !important;
   }
 
   .flex-sm-column {
     flex-direction: column !important;
   }
 
   .flex-sm-row-reverse {
     flex-direction: row-reverse !important;
   }
 
   .flex-sm-column-reverse {
     flex-direction: column-reverse !important;
   }
 
   .flex-sm-wrap {
     flex-wrap: wrap !important;
   }
 
   .flex-sm-nowrap {
     flex-wrap: nowrap !important;
   }
 
   .flex-sm-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
 
   .justify-content-sm-start {
     justify-content: flex-start !important;
   }
 
   .justify-content-sm-end {
     justify-content: flex-end !important;
   }
 
   .justify-content-sm-center {
     justify-content: center !important;
   }
 
   .justify-content-sm-between {
     justify-content: space-between !important;
   }
 
   .justify-content-sm-around {
     justify-content: space-around !important;
   }
 
   .align-items-sm-start {
     align-items: flex-start !important;
   }
 
   .align-items-sm-end {
     align-items: flex-end !important;
   }
 
   .align-items-sm-center {
     align-items: center !important;
   }
 
   .align-items-sm-baseline {
     align-items: baseline !important;
   }
 
   .align-items-sm-stretch {
     align-items: stretch !important;
   }
 
   .align-content-sm-start {
     align-content: flex-start !important;
   }
 
   .align-content-sm-end {
     align-content: flex-end !important;
   }
 
   .align-content-sm-center {
     align-content: center !important;
   }
 
   .align-content-sm-between {
     align-content: space-between !important;
   }
 
   .align-content-sm-around {
     align-content: space-around !important;
   }
 
   .align-content-sm-stretch {
     align-content: stretch !important;
   }
 
   .align-self-sm-auto {
     align-self: auto !important;
   }
 
   .align-self-sm-start {
     align-self: flex-start !important;
   }
 
   .align-self-sm-end {
     align-self: flex-end !important;
   }
 
   .align-self-sm-center {
     align-self: center !important;
   }
 
   .align-self-sm-baseline {
     align-self: baseline !important;
   }
 
   .align-self-sm-stretch {
     align-self: stretch !important;
   }
 }
 @media (min-width: 791px) {
   .flex-md-row {
     flex-direction: row !important;
   }
 
   .flex-md-column {
     flex-direction: column !important;
   }
 
   .flex-md-row-reverse {
     flex-direction: row-reverse !important;
   }
 
   .flex-md-column-reverse {
     flex-direction: column-reverse !important;
   }
 
   .flex-md-wrap {
     flex-wrap: wrap !important;
   }
 
   .flex-md-nowrap {
     flex-wrap: nowrap !important;
   }
 
   .flex-md-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
 
   .justify-content-md-start {
     justify-content: flex-start !important;
   }
 
   .justify-content-md-end {
     justify-content: flex-end !important;
   }
 
   .justify-content-md-center {
     justify-content: center !important;
   }
 
   .justify-content-md-between {
     justify-content: space-between !important;
   }
 
   .justify-content-md-around {
     justify-content: space-around !important;
   }
 
   .align-items-md-start {
     align-items: flex-start !important;
   }
 
   .align-items-md-end {
     align-items: flex-end !important;
   }
 
   .align-items-md-center {
     align-items: center !important;
   }
 
   .align-items-md-baseline {
     align-items: baseline !important;
   }
 
   .align-items-md-stretch {
     align-items: stretch !important;
   }
 
   .align-content-md-start {
     align-content: flex-start !important;
   }
 
   .align-content-md-end {
     align-content: flex-end !important;
   }
 
   .align-content-md-center {
     align-content: center !important;
   }
 
   .align-content-md-between {
     align-content: space-between !important;
   }
 
   .align-content-md-around {
     align-content: space-around !important;
   }
 
   .align-content-md-stretch {
     align-content: stretch !important;
   }
 
   .align-self-md-auto {
     align-self: auto !important;
   }
 
   .align-self-md-start {
     align-self: flex-start !important;
   }
 
   .align-self-md-end {
     align-self: flex-end !important;
   }
 
   .align-self-md-center {
     align-self: center !important;
   }
 
   .align-self-md-baseline {
     align-self: baseline !important;
   }
 
   .align-self-md-stretch {
     align-self: stretch !important;
   }
 }
 @media (min-width: 1025px) {
   .flex-lg-row {
     flex-direction: row !important;
   }
 
   .flex-lg-column {
     flex-direction: column !important;
   }
 
   .flex-lg-row-reverse {
     flex-direction: row-reverse !important;
   }
 
   .flex-lg-column-reverse {
     flex-direction: column-reverse !important;
   }
 
   .flex-lg-wrap {
     flex-wrap: wrap !important;
   }
 
   .flex-lg-nowrap {
     flex-wrap: nowrap !important;
   }
 
   .flex-lg-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
 
   .justify-content-lg-start {
     justify-content: flex-start !important;
   }
 
   .justify-content-lg-end {
     justify-content: flex-end !important;
   }
 
   .justify-content-lg-center {
     justify-content: center !important;
   }
 
   .justify-content-lg-between {
     justify-content: space-between !important;
   }
 
   .justify-content-lg-around {
     justify-content: space-around !important;
   }
 
   .align-items-lg-start {
     align-items: flex-start !important;
   }
 
   .align-items-lg-end {
     align-items: flex-end !important;
   }
 
   .align-items-lg-center {
     align-items: center !important;
   }
 
   .align-items-lg-baseline {
     align-items: baseline !important;
   }
 
   .align-items-lg-stretch {
     align-items: stretch !important;
   }
 
   .align-content-lg-start {
     align-content: flex-start !important;
   }
 
   .align-content-lg-end {
     align-content: flex-end !important;
   }
 
   .align-content-lg-center {
     align-content: center !important;
   }
 
   .align-content-lg-between {
     align-content: space-between !important;
   }
 
   .align-content-lg-around {
     align-content: space-around !important;
   }
 
   .align-content-lg-stretch {
     align-content: stretch !important;
   }
 
   .align-self-lg-auto {
     align-self: auto !important;
   }
 
   .align-self-lg-start {
     align-self: flex-start !important;
   }
 
   .align-self-lg-end {
     align-self: flex-end !important;
   }
 
   .align-self-lg-center {
     align-self: center !important;
   }
 
   .align-self-lg-baseline {
     align-self: baseline !important;
   }
 
   .align-self-lg-stretch {
     align-self: stretch !important;
   }
 }
 @media (min-width: 1240px) {
   .flex-xl-row {
     flex-direction: row !important;
   }
 
   .flex-xl-column {
     flex-direction: column !important;
   }
 
   .flex-xl-row-reverse {
     flex-direction: row-reverse !important;
   }
 
   .flex-xl-column-reverse {
     flex-direction: column-reverse !important;
   }
 
   .flex-xl-wrap {
     flex-wrap: wrap !important;
   }
 
   .flex-xl-nowrap {
     flex-wrap: nowrap !important;
   }
 
   .flex-xl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
 
   .justify-content-xl-start {
     justify-content: flex-start !important;
   }
 
   .justify-content-xl-end {
     justify-content: flex-end !important;
   }
 
   .justify-content-xl-center {
     justify-content: center !important;
   }
 
   .justify-content-xl-between {
     justify-content: space-between !important;
   }
 
   .justify-content-xl-around {
     justify-content: space-around !important;
   }
 
   .align-items-xl-start {
     align-items: flex-start !important;
   }
 
   .align-items-xl-end {
     align-items: flex-end !important;
   }
 
   .align-items-xl-center {
     align-items: center !important;
   }
 
   .align-items-xl-baseline {
     align-items: baseline !important;
   }
 
   .align-items-xl-stretch {
     align-items: stretch !important;
   }
 
   .align-content-xl-start {
     align-content: flex-start !important;
   }
 
   .align-content-xl-end {
     align-content: flex-end !important;
   }
 
   .align-content-xl-center {
     align-content: center !important;
   }
 
   .align-content-xl-between {
     align-content: space-between !important;
   }
 
   .align-content-xl-around {
     align-content: space-around !important;
   }
 
   .align-content-xl-stretch {
     align-content: stretch !important;
   }
 
   .align-self-xl-auto {
     align-self: auto !important;
   }
 
   .align-self-xl-start {
     align-self: flex-start !important;
   }
 
   .align-self-xl-end {
     align-self: flex-end !important;
   }
 
   .align-self-xl-center {
     align-self: center !important;
   }
 
   .align-self-xl-baseline {
     align-self: baseline !important;
   }
 
   .align-self-xl-stretch {
     align-self: stretch !important;
   }
 }
 .float-left {
   float: left !important;
 }
 
 .float-right {
   float: right !important;
 }
 
 .float-none {
   float: none !important;
 }
 
 @media (min-width: 576px) {
   .float-sm-left {
     float: left !important;
   }
 
   .float-sm-right {
     float: right !important;
   }
 
   .float-sm-none {
     float: none !important;
   }
 }
 @media (min-width: 791px) {
   .float-md-left {
     float: left !important;
   }
 
   .float-md-right {
     float: right !important;
   }
 
   .float-md-none {
     float: none !important;
   }
 }
 @media (min-width: 1025px) {
   .float-lg-left {
     float: left !important;
   }
 
   .float-lg-right {
     float: right !important;
   }
 
   .float-lg-none {
     float: none !important;
   }
 }
 @media (min-width: 1240px) {
   .float-xl-left {
     float: left !important;
   }
 
   .float-xl-right {
     float: right !important;
   }
 
   .float-xl-none {
     float: none !important;
   }
 }
 .position-static {
   position: static !important;
 }
 
 .position-relative {
   position: relative !important;
 }
 
 .position-absolute {
   position: absolute !important;
 }
 
 .position-fixed {
   position: fixed !important;
 }
 
 .position-sticky {
   position: sticky !important;
 }
 
 .fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 23;
 }
 
 .fixed-bottom {
   position: fixed;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 23;
 }
 
 @supports (position: sticky) {
   .sticky-top {
     position: sticky;
     top: 0;
     z-index: 22;
   }
 }
 .sr-only {
   position: absolute;
   width: 1px;
   height: 1px;
   padding: 0;
   overflow: hidden;
   clip: rect(0, 0, 0, 0);
   white-space: nowrap;
   -webkit-clip-path: inset(50%);
   clip-path: inset(50%);
   border: 0;
 }
 
 .sr-only-focusable:active, .sr-only-focusable:focus {
   position: static;
   width: auto;
   height: auto;
   overflow: visible;
   clip: auto;
   white-space: normal;
   -webkit-clip-path: none;
   clip-path: none;
 }
 
 .w-25 {
   width: 25% !important;
 }
 
 .w-50 {
   width: 50% !important;
 }
 
 .w-75 {
   width: 75% !important;
 }
 
 .w-100 {
   width: 100% !important;
 }
 
 .h-25 {
   height: 25% !important;
 }
 
 .h-50 {
   height: 50% !important;
 }
 
 .h-75 {
   height: 75% !important;
 }
 
 .h-100 {
   height: 100% !important;
 }
 
 .mw-100 {
   max-width: 100% !important;
 }
 
 .mh-100 {
   max-height: 100% !important;
 }
 
 .m-0 {
   margin: 0 !important;
 }
 
 .mt-0,
 .my-0 {
   margin-top: 0 !important;
 }
 
 .mr-0,
 .mx-0 {
   margin-right: 0 !important;
 }
 
 .mb-0,
 .my-0 {
   margin-bottom: 0 !important;
 }
 
 .ml-0,
 .mx-0 {
   margin-left: 0 !important;
 }
 
 .m-1 {
   margin: 0.25rem !important;
 }
 
 .mt-1,
 .my-1 {
   margin-top: 0.25rem !important;
 }
 
 .mr-1,
 .mx-1 {
   margin-right: 0.25rem !important;
 }
 
 .mb-1,
 .my-1 {
   margin-bottom: 0.25rem !important;
 }
 
 .ml-1,
 .mx-1 {
   margin-left: 0.25rem !important;
 }
 
 .m-2 {
   margin: 0.5rem !important;
 }
 
 .mt-2,
 .my-2 {
   margin-top: 0.5rem !important;
 }
 
 .mr-2,
 .mx-2 {
   margin-right: 0.5rem !important;
 }
 
 .mb-2,
 .my-2 {
   margin-bottom: 0.5rem !important;
 }
 
 .ml-2,
 .mx-2 {
   margin-left: 0.5rem !important;
 }
 
 .m-3 {
   margin: 1rem !important;
 }
 
 .mt-3,
 .my-3 {
   margin-top: 1rem !important;
 }
 
 .mr-3,
 .mx-3 {
   margin-right: 1rem !important;
 }
 
 .mb-3,
 .my-3 {
   margin-bottom: 1rem !important;
 }
 
 .ml-3,
 .mx-3 {
   margin-left: 1rem !important;
 }
 
 .m-4 {
   margin: 1.5rem !important;
 }
 
 .mt-4,
 .my-4 {
   margin-top: 1.5rem !important;
 }
 
 .mr-4,
 .mx-4 {
   margin-right: 1.5rem !important;
 }
 
 .mb-4,
 .my-4 {
   margin-bottom: 1.5rem !important;
 }
 
 .ml-4,
 .mx-4 {
   margin-left: 1.5rem !important;
 }
 
 .m-5 {
   margin: 3rem !important;
 }
 
 .mt-5,
 .my-5 {
   margin-top: 3rem !important;
 }
 
 .mr-5,
 .mx-5 {
   margin-right: 3rem !important;
 }
 
 .mb-5,
 .my-5 {
   margin-bottom: 3rem !important;
 }
 
 .ml-5,
 .mx-5 {
   margin-left: 3rem !important;
 }
 
 .p-0 {
   padding: 0 !important;
 }
 
 .pt-0,
 .py-0 {
   padding-top: 0 !important;
 }
 
 .pr-0,
 .px-0 {
   padding-right: 0 !important;
 }
 
 .pb-0,
 .py-0 {
   padding-bottom: 0 !important;
 }
 
 .pl-0,
 .px-0 {
   padding-left: 0 !important;
 }
 
 .p-1 {
   padding: 0.25rem !important;
 }
 
 .pt-1,
 .py-1 {
   padding-top: 0.25rem !important;
 }
 
 .pr-1,
 .px-1 {
   padding-right: 0.25rem !important;
 }
 
 .pb-1,
 .py-1 {
   padding-bottom: 0.25rem !important;
 }
 
 .pl-1,
 .px-1 {
   padding-left: 0.25rem !important;
 }
 
 .p-2 {
   padding: 0.5rem !important;
 }
 
 .pt-2,
 .py-2 {
   padding-top: 0.5rem !important;
 }
 
 .pr-2,
 .px-2 {
   padding-right: 0.5rem !important;
 }
 
 .pb-2,
 .py-2 {
   padding-bottom: 0.5rem !important;
 }
 
 .pl-2,
 .px-2 {
   padding-left: 0.5rem !important;
 }
 
 .p-3 {
   padding: 1rem !important;
 }
 
 .pt-3,
 .py-3 {
   padding-top: 1rem !important;
 }
 
 .pr-3,
 .px-3 {
   padding-right: 1rem !important;
 }
 
 .pb-3,
 .py-3 {
   padding-bottom: 1rem !important;
 }
 
 .pl-3,
 .px-3 {
   padding-left: 1rem !important;
 }
 
 .p-4 {
   padding: 1.5rem !important;
 }
 
 .pt-4,
 .py-4 {
   padding-top: 1.5rem !important;
 }
 
 .pr-4,
 .px-4 {
   padding-right: 1.5rem !important;
 }
 
 .pb-4,
 .py-4 {
   padding-bottom: 1.5rem !important;
 }
 
 .pl-4,
 .px-4 {
   padding-left: 1.5rem !important;
 }
 
 .p-5 {
   padding: 3rem !important;
 }
 
 .pt-5,
 .py-5 {
   padding-top: 3rem !important;
 }
 
 .pr-5,
 .px-5 {
   padding-right: 3rem !important;
 }
 
 .pb-5,
 .py-5 {
   padding-bottom: 3rem !important;
 }
 
 .pl-5,
 .px-5 {
   padding-left: 3rem !important;
 }
 
 .m-auto {
   margin: auto !important;
 }
 
 .mt-auto,
 .my-auto {
   margin-top: auto !important;
 }
 
 .mr-auto,
 .mx-auto {
   margin-right: auto !important;
 }
 
 .mb-auto,
 .my-auto {
   margin-bottom: auto !important;
 }
 
 .ml-auto,
 .mx-auto {
   margin-left: auto !important;
 }
 
 @media (min-width: 576px) {
   .m-sm-0 {
     margin: 0 !important;
   }
 
   .mt-sm-0,
 .my-sm-0 {
     margin-top: 0 !important;
   }
 
   .mr-sm-0,
 .mx-sm-0 {
     margin-right: 0 !important;
   }
 
   .mb-sm-0,
 .my-sm-0 {
     margin-bottom: 0 !important;
   }
 
   .ml-sm-0,
 .mx-sm-0 {
     margin-left: 0 !important;
   }
 
   .m-sm-1 {
     margin: 0.25rem !important;
   }
 
   .mt-sm-1,
 .my-sm-1 {
     margin-top: 0.25rem !important;
   }
 
   .mr-sm-1,
 .mx-sm-1 {
     margin-right: 0.25rem !important;
   }
 
   .mb-sm-1,
 .my-sm-1 {
     margin-bottom: 0.25rem !important;
   }
 
   .ml-sm-1,
 .mx-sm-1 {
     margin-left: 0.25rem !important;
   }
 
   .m-sm-2 {
     margin: 0.5rem !important;
   }
 
   .mt-sm-2,
 .my-sm-2 {
     margin-top: 0.5rem !important;
   }
 
   .mr-sm-2,
 .mx-sm-2 {
     margin-right: 0.5rem !important;
   }
 
   .mb-sm-2,
 .my-sm-2 {
     margin-bottom: 0.5rem !important;
   }
 
   .ml-sm-2,
 .mx-sm-2 {
     margin-left: 0.5rem !important;
   }
 
   .m-sm-3 {
     margin: 1rem !important;
   }
 
   .mt-sm-3,
 .my-sm-3 {
     margin-top: 1rem !important;
   }
 
   .mr-sm-3,
 .mx-sm-3 {
     margin-right: 1rem !important;
   }
 
   .mb-sm-3,
 .my-sm-3 {
     margin-bottom: 1rem !important;
   }
 
   .ml-sm-3,
 .mx-sm-3 {
     margin-left: 1rem !important;
   }
 
   .m-sm-4 {
     margin: 1.5rem !important;
   }
 
   .mt-sm-4,
 .my-sm-4 {
     margin-top: 1.5rem !important;
   }
 
   .mr-sm-4,
 .mx-sm-4 {
     margin-right: 1.5rem !important;
   }
 
   .mb-sm-4,
 .my-sm-4 {
     margin-bottom: 1.5rem !important;
   }
 
   .ml-sm-4,
 .mx-sm-4 {
     margin-left: 1.5rem !important;
   }
 
   .m-sm-5 {
     margin: 3rem !important;
   }
 
   .mt-sm-5,
 .my-sm-5 {
     margin-top: 3rem !important;
   }
 
   .mr-sm-5,
 .mx-sm-5 {
     margin-right: 3rem !important;
   }
 
   .mb-sm-5,
 .my-sm-5 {
     margin-bottom: 3rem !important;
   }
 
   .ml-sm-5,
 .mx-sm-5 {
     margin-left: 3rem !important;
   }
 
   .p-sm-0 {
     padding: 0 !important;
   }
 
   .pt-sm-0,
 .py-sm-0 {
     padding-top: 0 !important;
   }
 
   .pr-sm-0,
 .px-sm-0 {
     padding-right: 0 !important;
   }
 
   .pb-sm-0,
 .py-sm-0 {
     padding-bottom: 0 !important;
   }
 
   .pl-sm-0,
 .px-sm-0 {
     padding-left: 0 !important;
   }
 
   .p-sm-1 {
     padding: 0.25rem !important;
   }
 
   .pt-sm-1,
 .py-sm-1 {
     padding-top: 0.25rem !important;
   }
 
   .pr-sm-1,
 .px-sm-1 {
     padding-right: 0.25rem !important;
   }
 
   .pb-sm-1,
 .py-sm-1 {
     padding-bottom: 0.25rem !important;
   }
 
   .pl-sm-1,
 .px-sm-1 {
     padding-left: 0.25rem !important;
   }
 
   .p-sm-2 {
     padding: 0.5rem !important;
   }
 
   .pt-sm-2,
 .py-sm-2 {
     padding-top: 0.5rem !important;
   }
 
   .pr-sm-2,
 .px-sm-2 {
     padding-right: 0.5rem !important;
   }
 
   .pb-sm-2,
 .py-sm-2 {
     padding-bottom: 0.5rem !important;
   }
 
   .pl-sm-2,
 .px-sm-2 {
     padding-left: 0.5rem !important;
   }
 
   .p-sm-3 {
     padding: 1rem !important;
   }
 
   .pt-sm-3,
 .py-sm-3 {
     padding-top: 1rem !important;
   }
 
   .pr-sm-3,
 .px-sm-3 {
     padding-right: 1rem !important;
   }
 
   .pb-sm-3,
 .py-sm-3 {
     padding-bottom: 1rem !important;
   }
 
   .pl-sm-3,
 .px-sm-3 {
     padding-left: 1rem !important;
   }
 
   .p-sm-4 {
     padding: 1.5rem !important;
   }
 
   .pt-sm-4,
 .py-sm-4 {
     padding-top: 1.5rem !important;
   }
 
   .pr-sm-4,
 .px-sm-4 {
     padding-right: 1.5rem !important;
   }
 
   .pb-sm-4,
 .py-sm-4 {
     padding-bottom: 1.5rem !important;
   }
 
   .pl-sm-4,
 .px-sm-4 {
     padding-left: 1.5rem !important;
   }
 
   .p-sm-5 {
     padding: 3rem !important;
   }
 
   .pt-sm-5,
 .py-sm-5 {
     padding-top: 3rem !important;
   }
 
   .pr-sm-5,
 .px-sm-5 {
     padding-right: 3rem !important;
   }
 
   .pb-sm-5,
 .py-sm-5 {
     padding-bottom: 3rem !important;
   }
 
   .pl-sm-5,
 .px-sm-5 {
     padding-left: 3rem !important;
   }
 
   .m-sm-auto {
     margin: auto !important;
   }
 
   .mt-sm-auto,
 .my-sm-auto {
     margin-top: auto !important;
   }
 
   .mr-sm-auto,
 .mx-sm-auto {
     margin-right: auto !important;
   }
 
   .mb-sm-auto,
 .my-sm-auto {
     margin-bottom: auto !important;
   }
 
   .ml-sm-auto,
 .mx-sm-auto {
     margin-left: auto !important;
   }
 }
 @media (min-width: 791px) {
   .m-md-0 {
     margin: 0 !important;
   }
 
   .mt-md-0,
 .my-md-0 {
     margin-top: 0 !important;
   }
 
   .mr-md-0,
 .mx-md-0 {
     margin-right: 0 !important;
   }
 
   .mb-md-0,
 .my-md-0 {
     margin-bottom: 0 !important;
   }
 
   .ml-md-0,
 .mx-md-0 {
     margin-left: 0 !important;
   }
 
   .m-md-1 {
     margin: 0.25rem !important;
   }
 
   .mt-md-1,
 .my-md-1 {
     margin-top: 0.25rem !important;
   }
 
   .mr-md-1,
 .mx-md-1 {
     margin-right: 0.25rem !important;
   }
 
   .mb-md-1,
 .my-md-1 {
     margin-bottom: 0.25rem !important;
   }
 
   .ml-md-1,
 .mx-md-1 {
     margin-left: 0.25rem !important;
   }
 
   .m-md-2 {
     margin: 0.5rem !important;
   }
 
   .mt-md-2,
 .my-md-2 {
     margin-top: 0.5rem !important;
   }
 
   .mr-md-2,
 .mx-md-2 {
     margin-right: 0.5rem !important;
   }
 
   .mb-md-2,
 .my-md-2 {
     margin-bottom: 0.5rem !important;
   }
 
   .ml-md-2,
 .mx-md-2 {
     margin-left: 0.5rem !important;
   }
 
   .m-md-3 {
     margin: 1rem !important;
   }
 
   .mt-md-3,
 .my-md-3 {
     margin-top: 1rem !important;
   }
 
   .mr-md-3,
 .mx-md-3 {
     margin-right: 1rem !important;
   }
 
   .mb-md-3,
 .my-md-3 {
     margin-bottom: 1rem !important;
   }
 
   .ml-md-3,
 .mx-md-3 {
     margin-left: 1rem !important;
   }
 
   .m-md-4 {
     margin: 1.5rem !important;
   }
 
   .mt-md-4,
 .my-md-4 {
     margin-top: 1.5rem !important;
   }
 
   .mr-md-4,
 .mx-md-4 {
     margin-right: 1.5rem !important;
   }
 
   .mb-md-4,
 .my-md-4 {
     margin-bottom: 1.5rem !important;
   }
 
   .ml-md-4,
 .mx-md-4 {
     margin-left: 1.5rem !important;
   }
 
   .m-md-5 {
     margin: 3rem !important;
   }
 
   .mt-md-5,
 .my-md-5 {
     margin-top: 3rem !important;
   }
 
   .mr-md-5,
 .mx-md-5 {
     margin-right: 3rem !important;
   }
 
   .mb-md-5,
 .my-md-5 {
     margin-bottom: 3rem !important;
   }
 
   .ml-md-5,
 .mx-md-5 {
     margin-left: 3rem !important;
   }
 
   .p-md-0 {
     padding: 0 !important;
   }
 
   .pt-md-0,
 .py-md-0 {
     padding-top: 0 !important;
   }
 
   .pr-md-0,
 .px-md-0 {
     padding-right: 0 !important;
   }
 
   .pb-md-0,
 .py-md-0 {
     padding-bottom: 0 !important;
   }
 
   .pl-md-0,
 .px-md-0 {
     padding-left: 0 !important;
   }
 
   .p-md-1 {
     padding: 0.25rem !important;
   }
 
   .pt-md-1,
 .py-md-1 {
     padding-top: 0.25rem !important;
   }
 
   .pr-md-1,
 .px-md-1 {
     padding-right: 0.25rem !important;
   }
 
   .pb-md-1,
 .py-md-1 {
     padding-bottom: 0.25rem !important;
   }
 
   .pl-md-1,
 .px-md-1 {
     padding-left: 0.25rem !important;
   }
 
   .p-md-2 {
     padding: 0.5rem !important;
   }
 
   .pt-md-2,
 .py-md-2 {
     padding-top: 0.5rem !important;
   }
 
   .pr-md-2,
 .px-md-2 {
     padding-right: 0.5rem !important;
   }
 
   .pb-md-2,
 .py-md-2 {
     padding-bottom: 0.5rem !important;
   }
 
   .pl-md-2,
 .px-md-2 {
     padding-left: 0.5rem !important;
   }
 
   .p-md-3 {
     padding: 1rem !important;
   }
 
   .pt-md-3,
 .py-md-3 {
     padding-top: 1rem !important;
   }
 
   .pr-md-3,
 .px-md-3 {
     padding-right: 1rem !important;
   }
 
   .pb-md-3,
 .py-md-3 {
     padding-bottom: 1rem !important;
   }
 
   .pl-md-3,
 .px-md-3 {
     padding-left: 1rem !important;
   }
 
   .p-md-4 {
     padding: 1.5rem !important;
   }
 
   .pt-md-4,
 .py-md-4 {
     padding-top: 1.5rem !important;
   }
 
   .pr-md-4,
 .px-md-4 {
     padding-right: 1.5rem !important;
   }
 
   .pb-md-4,
 .py-md-4 {
     padding-bottom: 1.5rem !important;
   }
 
   .pl-md-4,
 .px-md-4 {
     padding-left: 1.5rem !important;
   }
 
   .p-md-5 {
     padding: 3rem !important;
   }
 
   .pt-md-5,
 .py-md-5 {
     padding-top: 3rem !important;
   }
 
   .pr-md-5,
 .px-md-5 {
     padding-right: 3rem !important;
   }
 
   .pb-md-5,
 .py-md-5 {
     padding-bottom: 3rem !important;
   }
 
   .pl-md-5,
 .px-md-5 {
     padding-left: 3rem !important;
   }
 
   .m-md-auto {
     margin: auto !important;
   }
 
   .mt-md-auto,
 .my-md-auto {
     margin-top: auto !important;
   }
 
   .mr-md-auto,
 .mx-md-auto {
     margin-right: auto !important;
   }
 
   .mb-md-auto,
 .my-md-auto {
     margin-bottom: auto !important;
   }
 
   .ml-md-auto,
 .mx-md-auto {
     margin-left: auto !important;
   }
 }
 @media (min-width: 1025px) {
   .m-lg-0 {
     margin: 0 !important;
   }
 
   .mt-lg-0,
 .my-lg-0 {
     margin-top: 0 !important;
   }
 
   .mr-lg-0,
 .mx-lg-0 {
     margin-right: 0 !important;
   }
 
   .mb-lg-0,
 .my-lg-0 {
     margin-bottom: 0 !important;
   }
 
   .ml-lg-0,
 .mx-lg-0 {
     margin-left: 0 !important;
   }
 
   .m-lg-1 {
     margin: 0.25rem !important;
   }
 
   .mt-lg-1,
 .my-lg-1 {
     margin-top: 0.25rem !important;
   }
 
   .mr-lg-1,
 .mx-lg-1 {
     margin-right: 0.25rem !important;
   }
 
   .mb-lg-1,
 .my-lg-1 {
     margin-bottom: 0.25rem !important;
   }
 
   .ml-lg-1,
 .mx-lg-1 {
     margin-left: 0.25rem !important;
   }
 
   .m-lg-2 {
     margin: 0.5rem !important;
   }
 
   .mt-lg-2,
 .my-lg-2 {
     margin-top: 0.5rem !important;
   }
 
   .mr-lg-2,
 .mx-lg-2 {
     margin-right: 0.5rem !important;
   }
 
   .mb-lg-2,
 .my-lg-2 {
     margin-bottom: 0.5rem !important;
   }
 
   .ml-lg-2,
 .mx-lg-2 {
     margin-left: 0.5rem !important;
   }
 
   .m-lg-3 {
     margin: 1rem !important;
   }
 
   .mt-lg-3,
 .my-lg-3 {
     margin-top: 1rem !important;
   }
 
   .mr-lg-3,
 .mx-lg-3 {
     margin-right: 1rem !important;
   }
 
   .mb-lg-3,
 .my-lg-3 {
     margin-bottom: 1rem !important;
   }
 
   .ml-lg-3,
 .mx-lg-3 {
     margin-left: 1rem !important;
   }
 
   .m-lg-4 {
     margin: 1.5rem !important;
   }
 
   .mt-lg-4,
 .my-lg-4 {
     margin-top: 1.5rem !important;
   }
 
   .mr-lg-4,
 .mx-lg-4 {
     margin-right: 1.5rem !important;
   }
 
   .mb-lg-4,
 .my-lg-4 {
     margin-bottom: 1.5rem !important;
   }
 
   .ml-lg-4,
 .mx-lg-4 {
     margin-left: 1.5rem !important;
   }
 
   .m-lg-5 {
     margin: 3rem !important;
   }
 
   .mt-lg-5,
 .my-lg-5 {
     margin-top: 3rem !important;
   }
 
   .mr-lg-5,
 .mx-lg-5 {
     margin-right: 3rem !important;
   }
 
   .mb-lg-5,
 .my-lg-5 {
     margin-bottom: 3rem !important;
   }
 
   .ml-lg-5,
 .mx-lg-5 {
     margin-left: 3rem !important;
   }
 
   .p-lg-0 {
     padding: 0 !important;
   }
 
   .pt-lg-0,
 .py-lg-0 {
     padding-top: 0 !important;
   }
 
   .pr-lg-0,
 .px-lg-0 {
     padding-right: 0 !important;
   }
 
   .pb-lg-0,
 .py-lg-0 {
     padding-bottom: 0 !important;
   }
 
   .pl-lg-0,
 .px-lg-0 {
     padding-left: 0 !important;
   }
 
   .p-lg-1 {
     padding: 0.25rem !important;
   }
 
   .pt-lg-1,
 .py-lg-1 {
     padding-top: 0.25rem !important;
   }
 
   .pr-lg-1,
 .px-lg-1 {
     padding-right: 0.25rem !important;
   }
 
   .pb-lg-1,
 .py-lg-1 {
     padding-bottom: 0.25rem !important;
   }
 
   .pl-lg-1,
 .px-lg-1 {
     padding-left: 0.25rem !important;
   }
 
   .p-lg-2 {
     padding: 0.5rem !important;
   }
 
   .pt-lg-2,
 .py-lg-2 {
     padding-top: 0.5rem !important;
   }
 
   .pr-lg-2,
 .px-lg-2 {
     padding-right: 0.5rem !important;
   }
 
   .pb-lg-2,
 .py-lg-2 {
     padding-bottom: 0.5rem !important;
   }
 
   .pl-lg-2,
 .px-lg-2 {
     padding-left: 0.5rem !important;
   }
 
   .p-lg-3 {
     padding: 1rem !important;
   }
 
   .pt-lg-3,
 .py-lg-3 {
     padding-top: 1rem !important;
   }
 
   .pr-lg-3,
 .px-lg-3 {
     padding-right: 1rem !important;
   }
 
   .pb-lg-3,
 .py-lg-3 {
     padding-bottom: 1rem !important;
   }
 
   .pl-lg-3,
 .px-lg-3 {
     padding-left: 1rem !important;
   }
 
   .p-lg-4 {
     padding: 1.5rem !important;
   }
 
   .pt-lg-4,
 .py-lg-4 {
     padding-top: 1.5rem !important;
   }
 
   .pr-lg-4,
 .px-lg-4 {
     padding-right: 1.5rem !important;
   }
 
   .pb-lg-4,
 .py-lg-4 {
     padding-bottom: 1.5rem !important;
   }
 
   .pl-lg-4,
 .px-lg-4 {
     padding-left: 1.5rem !important;
   }
 
   .p-lg-5 {
     padding: 3rem !important;
   }
 
   .pt-lg-5,
 .py-lg-5 {
     padding-top: 3rem !important;
   }
 
   .pr-lg-5,
 .px-lg-5 {
     padding-right: 3rem !important;
   }
 
   .pb-lg-5,
 .py-lg-5 {
     padding-bottom: 3rem !important;
   }
 
   .pl-lg-5,
 .px-lg-5 {
     padding-left: 3rem !important;
   }
 
   .m-lg-auto {
     margin: auto !important;
   }
 
   .mt-lg-auto,
 .my-lg-auto {
     margin-top: auto !important;
   }
 
   .mr-lg-auto,
 .mx-lg-auto {
     margin-right: auto !important;
   }
 
   .mb-lg-auto,
 .my-lg-auto {
     margin-bottom: auto !important;
   }
 
   .ml-lg-auto,
 .mx-lg-auto {
     margin-left: auto !important;
   }
 }
 @media (min-width: 1240px) {
   .m-xl-0 {
     margin: 0 !important;
   }
 
   .mt-xl-0,
 .my-xl-0 {
     margin-top: 0 !important;
   }
 
   .mr-xl-0,
 .mx-xl-0 {
     margin-right: 0 !important;
   }
 
   .mb-xl-0,
 .my-xl-0 {
     margin-bottom: 0 !important;
   }
 
   .ml-xl-0,
 .mx-xl-0 {
     margin-left: 0 !important;
   }
 
   .m-xl-1 {
     margin: 0.25rem !important;
   }
 
   .mt-xl-1,
 .my-xl-1 {
     margin-top: 0.25rem !important;
   }
 
   .mr-xl-1,
 .mx-xl-1 {
     margin-right: 0.25rem !important;
   }
 
   .mb-xl-1,
 .my-xl-1 {
     margin-bottom: 0.25rem !important;
   }
 
   .ml-xl-1,
 .mx-xl-1 {
     margin-left: 0.25rem !important;
   }
 
   .m-xl-2 {
     margin: 0.5rem !important;
   }
 
   .mt-xl-2,
 .my-xl-2 {
     margin-top: 0.5rem !important;
   }
 
   .mr-xl-2,
 .mx-xl-2 {
     margin-right: 0.5rem !important;
   }
 
   .mb-xl-2,
 .my-xl-2 {
     margin-bottom: 0.5rem !important;
   }
 
   .ml-xl-2,
 .mx-xl-2 {
     margin-left: 0.5rem !important;
   }
 
   .m-xl-3 {
     margin: 1rem !important;
   }
 
   .mt-xl-3,
 .my-xl-3 {
     margin-top: 1rem !important;
   }
 
   .mr-xl-3,
 .mx-xl-3 {
     margin-right: 1rem !important;
   }
 
   .mb-xl-3,
 .my-xl-3 {
     margin-bottom: 1rem !important;
   }
 
   .ml-xl-3,
 .mx-xl-3 {
     margin-left: 1rem !important;
   }
 
   .m-xl-4 {
     margin: 1.5rem !important;
   }
 
   .mt-xl-4,
 .my-xl-4 {
     margin-top: 1.5rem !important;
   }
 
   .mr-xl-4,
 .mx-xl-4 {
     margin-right: 1.5rem !important;
   }
 
   .mb-xl-4,
 .my-xl-4 {
     margin-bottom: 1.5rem !important;
   }
 
   .ml-xl-4,
 .mx-xl-4 {
     margin-left: 1.5rem !important;
   }
 
   .m-xl-5 {
     margin: 3rem !important;
   }
 
   .mt-xl-5,
 .my-xl-5 {
     margin-top: 3rem !important;
   }
 
   .mr-xl-5,
 .mx-xl-5 {
     margin-right: 3rem !important;
   }
 
   .mb-xl-5,
 .my-xl-5 {
     margin-bottom: 3rem !important;
   }
 
   .ml-xl-5,
 .mx-xl-5 {
     margin-left: 3rem !important;
   }
 
   .p-xl-0 {
     padding: 0 !important;
   }
 
   .pt-xl-0,
 .py-xl-0 {
     padding-top: 0 !important;
   }
 
   .pr-xl-0,
 .px-xl-0 {
     padding-right: 0 !important;
   }
 
   .pb-xl-0,
 .py-xl-0 {
     padding-bottom: 0 !important;
   }
 
   .pl-xl-0,
 .px-xl-0 {
     padding-left: 0 !important;
   }
 
   .p-xl-1 {
     padding: 0.25rem !important;
   }
 
   .pt-xl-1,
 .py-xl-1 {
     padding-top: 0.25rem !important;
   }
 
   .pr-xl-1,
 .px-xl-1 {
     padding-right: 0.25rem !important;
   }
 
   .pb-xl-1,
 .py-xl-1 {
     padding-bottom: 0.25rem !important;
   }
 
   .pl-xl-1,
 .px-xl-1 {
     padding-left: 0.25rem !important;
   }
 
   .p-xl-2 {
     padding: 0.5rem !important;
   }
 
   .pt-xl-2,
 .py-xl-2 {
     padding-top: 0.5rem !important;
   }
 
   .pr-xl-2,
 .px-xl-2 {
     padding-right: 0.5rem !important;
   }
 
   .pb-xl-2,
 .py-xl-2 {
     padding-bottom: 0.5rem !important;
   }
 
   .pl-xl-2,
 .px-xl-2 {
     padding-left: 0.5rem !important;
   }
 
   .p-xl-3 {
     padding: 1rem !important;
   }
 
   .pt-xl-3,
 .py-xl-3 {
     padding-top: 1rem !important;
   }
 
   .pr-xl-3,
 .px-xl-3 {
     padding-right: 1rem !important;
   }
 
   .pb-xl-3,
 .py-xl-3 {
     padding-bottom: 1rem !important;
   }
 
   .pl-xl-3,
 .px-xl-3 {
     padding-left: 1rem !important;
   }
 
   .p-xl-4 {
     padding: 1.5rem !important;
   }
 
   .pt-xl-4,
 .py-xl-4 {
     padding-top: 1.5rem !important;
   }
 
   .pr-xl-4,
 .px-xl-4 {
     padding-right: 1.5rem !important;
   }
 
   .pb-xl-4,
 .py-xl-4 {
     padding-bottom: 1.5rem !important;
   }
 
   .pl-xl-4,
 .px-xl-4 {
     padding-left: 1.5rem !important;
   }
 
   .p-xl-5 {
     padding: 3rem !important;
   }
 
   .pt-xl-5,
 .py-xl-5 {
     padding-top: 3rem !important;
   }
 
   .pr-xl-5,
 .px-xl-5 {
     padding-right: 3rem !important;
   }
 
   .pb-xl-5,
 .py-xl-5 {
     padding-bottom: 3rem !important;
   }
 
   .pl-xl-5,
 .px-xl-5 {
     padding-left: 3rem !important;
   }
 
   .m-xl-auto {
     margin: auto !important;
   }
 
   .mt-xl-auto,
 .my-xl-auto {
     margin-top: auto !important;
   }
 
   .mr-xl-auto,
 .mx-xl-auto {
     margin-right: auto !important;
   }
 
   .mb-xl-auto,
 .my-xl-auto {
     margin-bottom: auto !important;
   }
 
   .ml-xl-auto,
 .mx-xl-auto {
     margin-left: auto !important;
   }
 }
 .text-justify {
   text-align: justify !important;
 }
 
 .text-nowrap {
   white-space: nowrap !important;
 }
 
 .text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }
 
 .text-left {
   text-align: left !important;
 }
 
 .text-right {
   text-align: right !important;
 }
 
 .text-center {
   text-align: center !important;
 }
 
 @media (min-width: 576px) {
   .text-sm-left {
     text-align: left !important;
   }
 
   .text-sm-right {
     text-align: right !important;
   }
 
   .text-sm-center {
     text-align: center !important;
   }
 }
 @media (min-width: 791px) {
   .text-md-left {
     text-align: left !important;
   }
 
   .text-md-right {
     text-align: right !important;
   }
 
   .text-md-center {
     text-align: center !important;
   }
 }
 @media (min-width: 1025px) {
   .text-lg-left {
     text-align: left !important;
   }
 
   .text-lg-right {
     text-align: right !important;
   }
 
   .text-lg-center {
     text-align: center !important;
   }
 }
 @media (min-width: 1240px) {
   .text-xl-left {
     text-align: left !important;
   }
 
   .text-xl-right {
     text-align: right !important;
   }
 
   .text-xl-center {
     text-align: center !important;
   }
 }
 .text-lowercase {
   text-transform: lowercase !important;
 }
 
 .text-uppercase {
   text-transform: uppercase !important;
 }
 
 .text-capitalize {
   text-transform: capitalize !important;
 }
 
 .font-weight-light {
   font-weight: 300 !important;
 }
 
 .font-weight-normal {
   font-weight: 400 !important;
 }
 
 .font-weight-bold {
   font-weight: 700 !important;
 }
 
 .font-italic {
   font-style: italic !important;
 }
 
 .text-white {
   color: #fff !important;
 }
 
 .text-primary {
   color: #007bff !important;
 }
 
 a.text-primary:hover, a.text-primary:focus {
   color: #0062cc !important;
 }
 
 .text-secondary {
   color: #6c757d !important;
 }
 
 a.text-secondary:hover, a.text-secondary:focus {
   color: #545b62 !important;
 }
 
 .text-success {
   color: #28a745 !important;
 }
 
 a.text-success:hover, a.text-success:focus {
   color: #1e7e34 !important;
 }
 
 .text-info {
   color: #17a2b8 !important;
 }
 
 a.text-info:hover, a.text-info:focus {
   color: #117a8b !important;
 }
 
 .text-warning {
   color: #ffc107 !important;
 }
 
 a.text-warning:hover, a.text-warning:focus {
   color: #d39e00 !important;
 }
 
 .text-danger {
   color: #dc3545 !important;
 }
 
 a.text-danger:hover, a.text-danger:focus {
   color: #bd2130 !important;
 }
 
 .text-light {
   color: #f8f9fa !important;
 }
 
 a.text-light:hover, a.text-light:focus {
   color: #dae0e5 !important;
 }
 
 .text-dark {
   color: #343a40 !important;
 }
 
 a.text-dark:hover, a.text-dark:focus {
   color: #1d2124 !important;
 }
 
 .text-muted {
   color: #6c757d !important;
 }
 
 .text-hide {
   font: 0/0 a;
   color: transparent;
   text-shadow: none;
   background-color: transparent;
   border: 0;
 }
 
 .visible {
   visibility: visible !important;
 }
 
 .invisible {
   visibility: hidden !important;
 }
 
 @media print {
   *,
 *::before,
 *::after {
     text-shadow: none !important;
     box-shadow: none !important;
   }
 
   a:not(.btn) {
     text-decoration: underline;
   }
 
   abbr[title]::after {
     content: " (" attr(title) ")";
   }
 
   pre {
     white-space: pre-wrap !important;
   }
 
   pre,
 blockquote {
     border: 1px solid #999;
     page-break-inside: avoid;
   }
 
   thead {
     display: table-header-group;
   }
 
   tr,
 img {
     page-break-inside: avoid;
   }
 
   p,
 h2,
 h3 {
     orphans: 3;
     widows: 3;
   }
 
   h2,
 h3 {
     page-break-after: avoid;
   }
 
   @page {
     size: a3;
   }
   body {
     min-width: 1025px !important;
   }
 
   .container {
     min-width: 1025px !important;
   }
 
   .navbar {
     display: none;
   }
 
   .badge {
     border: 1px solid #000;
   }
 
   .table {
     border-collapse: collapse !important;
   }
 
   .table td,
 .table th {
     background-color: #fff !important;
   }
 
   .table-bordered th,
 .table-bordered td {
     border: 1px solid #ddd !important;
   }
 }
 /*-------  1.2 Slick carousel  --------*/
 /* Slider */
 .slick-slider {
   position: relative;
   display: block;
   box-sizing: border-box;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   touch-action: pan-y;
   -webkit-tap-highlight-color: transparent;
 }
 
 .slick-list {
   position: relative;
   overflow: hidden;
   display: block;
   margin: 0;
   padding: 0;
 }
 .slick-list:focus {
   outline: none;
 }
 .slick-list.dragging {
   cursor: pointer;
   cursor: hand;
 }
 
 .slick-slider .slick-track,
 .slick-slider .slick-list {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
 }
 
 .slick-track {
   position: relative;
   left: 0;
   top: 0;
   display: block;
   margin-left: auto;
   margin-right: auto;
 }
 .slick-track:before, .slick-track:after {
   content: "";
   display: table;
 }
 .slick-track:after {
   clear: both;
 }
 .slick-loading .slick-track {
   visibility: hidden;
 }
 
 .slick-slide {
   float: left;
   height: 100%;
   min-height: 1px;
   display: none;
 }
 [dir=rtl] .slick-slide {
   float: right;
 }
 .slick-slide img {
   display: block;
 }
 .slick-slide.slick-loading img {
   display: none;
 }
 .slick-slide.dragging img {
   pointer-events: none;
 }
 .slick-initialized .slick-slide {
   display: block;
 }
 .slick-loading .slick-slide {
   visibility: hidden;
 }
 .slick-vertical .slick-slide {
   display: block;
   height: auto;
   border: 1px solid transparent;
 }
 
 .slick-arrow.slick-hidden {
   display: none;
 }
 
 /*-------  1.3 sumoselect  --------*/
 .SumoSelect p {
   margin: 0;
 }
 
 .SumoSelect {
   width: 100%;
 }
 
 .SelectBox {
   padding: 5px 8px;
 }
 
 .sumoStopScroll {
   overflow: hidden;
 }
 
 /* Filtering style */
 .SumoSelect .hidden {
   display: none;
 }
 
 .SumoSelect .search-txt {
   display: none;
   outline: none;
 }
 
 .SumoSelect .no-match {
   display: none;
   padding: 6px;
 }
 
 .SumoSelect.open .search-txt {
   display: inline-block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   margin: 0;
   padding: 5px 8px;
   border: none;
   box-sizing: border-box;
   border-radius: 5px;
 }
 
 .SumoSelect.open > .search > span, .SumoSelect.open > .search > label {
   visibility: hidden;
 }
 
 /*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
 .SelectClass, .SumoUnder {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 100%;
   width: 100%;
   border: none;
   box-sizing: border-box;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter: alpha(opacity=0);
   -moz-opacity: 0;
   -khtml-opacity: 0;
   opacity: 0;
 }
 
 .SelectClass {
   z-index: 1;
 }
 
 .SumoSelect > .optWrapper > .options li.opt label, .SumoSelect > .CaptionCont, .SumoSelect .select-all > label {
   -ms-user-select: none;
   user-select: none;
   -o-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
 }
 
 .SumoSelect {
   display: inline-block;
   position: relative;
   outline: none;
 }
 
 .SumoSelect:focus > .CaptionCont, .SumoSelect:hover > .CaptionCont, .SumoSelect.open > .CaptionCont {
   box-shadow: 0 0 2px #7799D0;
   border-color: #7799D0;
 }
 
 .SumoSelect > .CaptionCont {
   position: relative;
   border: 1px solid #A4A4A4;
   min-height: 14px;
   background-color: #fff;
   border-radius: 2px;
   margin: 0;
 }
 
 .SumoSelect > .CaptionCont > span {
   display: block;
   padding-right: 30px;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   cursor: default;
 }
 
 /*placeholder style*/
 .SumoSelect > .CaptionCont > span.placeholder {
   color: #ccc;
   font-style: italic;
 }
 
 .SumoSelect > .CaptionCont > label {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   width: 30px;
 }
 
 .SumoSelect > .CaptionCont > label > i {
   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wMdBhAJ/fwnjwAAAGFJREFUKM9jYBh+gBFKuzEwMKQwMDB8xaOWlYGB4T4DA0MrsuapDAwM//HgNwwMDDbYTJuGQ8MHBgYGJ1xOYGNgYJiBpuEpAwODHSF/siDZ+ISBgcGClEDqZ2Bg8B6CkQsAPRga0cpRtDEAAAAASUVORK5CYII=");
   background-position: center center;
   width: 16px;
   height: 16px;
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   background-repeat: no-repeat;
   opacity: 0.8;
 }
 
 .SumoSelect > .optWrapper {
   display: none;
   z-index: 1000;
   top: 30px;
   width: 100%;
   position: absolute;
   left: 0;
   box-sizing: border-box;
   background: #fff;
   border: 1px solid #ddd;
   box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.11);
   border-radius: 3px;
   overflow: hidden;
 }
 
 .SumoSelect.open > .optWrapper {
   top: 35px;
   display: block;
 }
 
 .SumoSelect.open > .optWrapper.up {
   top: auto;
   bottom: 100%;
   margin-bottom: 5px;
 }
 
 .SumoSelect > .optWrapper ul {
   list-style: none;
   display: block;
   padding: 0;
   margin: 0;
   overflow: auto;
 }
 
 .SumoSelect > .optWrapper > .options {
   border-radius: 2px;
   position: relative;
   /*Set the height of pop up here (only for desktop mode)*/
   max-height: 250px;
   /*height*/
 }
 
 .SumoSelect > .optWrapper.okCancelInMulti > .options {
   border-radius: 2px 2px 0 0;
 }
 
 .SumoSelect > .optWrapper.selall > .options {
   border-radius: 0 0 2px 2px;
 }
 
 .SumoSelect > .optWrapper.selall.okCancelInMulti > .options {
   border-radius: 0;
 }
 
 .SumoSelect > .optWrapper > .options li.group.disabled > label {
   opacity: 0.5;
 }
 
 .SumoSelect > .optWrapper > .options li ul li.opt {
   padding-left: 22px;
 }
 
 .SumoSelect > .optWrapper.multiple > .options li ul li.opt {
   padding-left: 50px;
 }
 
 .SumoSelect > .optWrapper.isFloating > .options {
   max-height: 100%;
   box-shadow: 0 0 100px #595959;
 }
 
 .SumoSelect > .optWrapper > .options li.opt {
   padding: 6px 6px;
   position: relative;
   border-bottom: 1px solid #f5f5f5;
 }
 
 .SumoSelect > .optWrapper > .options > li.opt:first-child {
   border-radius: 2px 2px 0 0;
 }
 
 .SumoSelect > .optWrapper.selall > .options > li.opt:first-child {
   border-radius: 0;
 }
 
 .SumoSelect > .optWrapper > .options > li.opt:last-child {
   border-radius: 0 0 2px 2px;
   border-bottom: none;
 }
 
 .SumoSelect > .optWrapper.okCancelInMulti > .options > li.opt:last-child {
   border-radius: 0;
 }
 
 .SumoSelect > .optWrapper > .options li.opt:hover {
   background-color: #E4E4E4;
 }
 
 .SumoSelect > .optWrapper > .options li.opt.sel, .SumoSelect .select-all.sel {
   background-color: #a1c0e4;
 }
 
 .SumoSelect > .optWrapper > .options li label {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   display: block;
   cursor: pointer;
 }
 
 .SumoSelect > .optWrapper > .options li span {
   display: none;
 }
 
 .SumoSelect > .optWrapper > .options li.group > label {
   cursor: default;
   padding: 8px 6px;
   font-weight: bold;
 }
 
 /*Floating styles*/
 .SumoSelect > .optWrapper.isFloating {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   width: 90%;
   bottom: 0;
   margin: auto;
   max-height: 90%;
 }
 
 /*disabled state*/
 .SumoSelect > .optWrapper > .options li.opt.disabled {
   background-color: inherit;
   pointer-events: none;
 }
 
 .SumoSelect > .optWrapper > .options li.opt.disabled * {
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
   /* IE 5-7 */
   filter: alpha(opacity=50);
   /* Netscape */
   -moz-opacity: 0.5;
   /* Safari 1.x */
   -khtml-opacity: 0.5;
   /* Good browsers */
   opacity: 0.5;
 }
 
 /*styling for multiple select*/
 .SumoSelect > .optWrapper.multiple > .options li.opt {
   padding-left: 35px;
   cursor: pointer;
 }
 
 .SumoSelect > .optWrapper.multiple > .options li.opt span, .SumoSelect .select-all > span {
   position: absolute;
   display: block;
   width: 30px;
   top: 0;
   bottom: 0;
   margin-left: -35px;
 }
 
 .SumoSelect > .optWrapper.multiple > .options li.opt span i, .SumoSelect .select-all > span i {
   position: absolute;
   margin: auto;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   width: 14px;
   height: 14px;
   border: 1px solid #AEAEAE;
   border-radius: 2px;
   box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
   background-color: #fff;
 }
 
 .SumoSelect > .optWrapper > .MultiControls {
   display: none;
   border-top: 1px solid #ddd;
   background-color: #fff;
   box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
   border-radius: 0 0 3px 3px;
 }
 
 .SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
   display: block;
   margin-top: 5px;
   position: absolute;
   bottom: 0;
   width: 100%;
 }
 
 .SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
   display: block;
 }
 
 .SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
   padding: 6px;
 }
 
 .SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p:focus {
   box-shadow: 0 0 2px #a1c0e4;
   border-color: #a1c0e4;
   outline: none;
   background-color: #a1c0e4;
 }
 
 .SumoSelect > .optWrapper.multiple > .MultiControls > p {
   display: inline-block;
   cursor: pointer;
   padding: 12px;
   width: 50%;
   box-sizing: border-box;
   text-align: center;
 }
 
 .SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
   background-color: #f1f1f1;
 }
 
 .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
   border-right: 1px solid #DBDBDB;
   border-radius: 0 0 0 3px;
 }
 
 .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnCancel {
   border-radius: 0 0 3px 0;
 }
 
 /*styling for select on popup mode*/
 .SumoSelect > .optWrapper.isFloating > .options li.opt {
   padding: 12px 6px;
 }
 
 /*styling for only multiple select on popup mode*/
 .SumoSelect > .optWrapper.multiple.isFloating > .options li.opt {
   padding-left: 35px;
 }
 
 .SumoSelect > .optWrapper.multiple.isFloating {
   padding-bottom: 43px;
 }
 
 .SumoSelect > .optWrapper.multiple > .options li.opt.selected span i, .SumoSelect .select-all.selected > span i, .SumoSelect .select-all.partial > span i {
   background-color: rgb(17, 169, 17);
   box-shadow: none;
   border-color: transparent;
   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC");
   background-repeat: no-repeat;
   background-position: center center;
 }
 
 /*disabled state*/
 .SumoSelect.disabled {
   opacity: 0.7;
   cursor: not-allowed;
 }
 
 .SumoSelect.disabled > .CaptionCont {
   border-color: #ccc;
   box-shadow: none;
 }
 
 /**Select all button**/
 .SumoSelect .select-all {
   border-radius: 3px 3px 0 0;
   position: relative;
   border-bottom: 1px solid #ddd;
   background-color: #fff;
   padding: 8px 0 3px 35px;
   height: 20px;
   cursor: pointer;
 }
 
 .SumoSelect .select-all > label, .SumoSelect .select-all > span i {
   cursor: pointer;
 }
 
 .SumoSelect .select-all.partial > span i {
   background-color: #ccc;
 }
 
 /*styling for optgroups*/
 .SumoSelect > .optWrapper > .options li.optGroup {
   padding-left: 5px;
   text-decoration: underline;
 }
 
 /*-------  1.4 Magnific Popup  --------*/
 /* Magnific Popup CSS */
 .mfp-bg {
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1042;
   overflow: hidden;
   position: fixed;
   background: #0b0b0b;
   opacity: 0.8;
 }
 
 .mfp-wrap {
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1043;
   position: fixed;
   outline: none !important;
   -webkit-backface-visibility: hidden;
 }
 
 .mfp-container {
   text-align: center;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   padding: 0 8px;
   box-sizing: border-box;
 }
 
 .mfp-container:before {
   content: "";
   display: inline-block;
   height: 100%;
   vertical-align: middle;
 }
 
 .mfp-align-top .mfp-container:before {
   display: none;
 }
 
 .mfp-content {
   position: relative;
   display: inline-block;
   vertical-align: middle;
   margin: 0 auto;
   text-align: left;
   z-index: 1045;
 }
 
 .mfp-inline-holder .mfp-content,
 .mfp-ajax-holder .mfp-content {
   width: 100%;
   cursor: auto;
 }
 
 .mfp-ajax-cur {
   cursor: progress;
 }
 
 .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
   cursor: -webkit-zoom-out;
   cursor: zoom-out;
 }
 
 .mfp-zoom {
   cursor: pointer;
   cursor: -webkit-zoom-in;
   cursor: zoom-in;
 }
 
 .mfp-auto-cursor .mfp-content {
   cursor: auto;
 }
 
 .mfp-close,
 .mfp-arrow,
 .mfp-preloader,
 .mfp-counter {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 .mfp-loading.mfp-figure {
   display: none;
 }
 
 .mfp-hide {
   display: none !important;
 }
 
 .mfp-preloader {
   color: #CCC;
   position: absolute;
   top: 50%;
   width: auto;
   text-align: center;
   margin-top: -0.8em;
   left: 8px;
   right: 8px;
   z-index: 1044;
 }
 
 .mfp-preloader a {
   color: #CCC;
 }
 
 .mfp-preloader a:hover {
   color: #FFF;
 }
 
 .mfp-s-ready .mfp-preloader {
   display: none;
 }
 
 .mfp-s-error .mfp-content {
   display: none;
 }
 
 button.mfp-close,
 button.mfp-arrow {
   overflow: visible;
   cursor: pointer;
   background: transparent;
   border: 0;
   -webkit-appearance: none;
   display: block;
   outline: none;
   padding: 0;
   z-index: 1046;
   box-shadow: none;
   touch-action: manipulation;
 }
 
 button::-moz-focus-inner {
   padding: 0;
   border: 0;
 }
 
 .mfp-close {
   width: 44px;
   height: 44px;
   line-height: 44px;
   position: absolute;
   right: 0;
   top: 0;
   text-decoration: none;
   text-align: center;
   opacity: 0.65;
   padding: 0 0 18px 10px;
   color: #FFF;
   font-style: normal;
   font-size: 28px;
   font-family: Arial, Baskerville, monospace;
 }
 
 .mfp-close:hover,
 .mfp-close:focus {
   opacity: 1;
 }
 
 .mfp-close:active {
   top: 1px;
 }
 
 .mfp-close-btn-in .mfp-close {
   color: #333;
 }
 
 .mfp-image-holder .mfp-close,
 .mfp-iframe-holder .mfp-close {
   color: #FFF;
   right: -6px;
   text-align: right;
   padding-right: 6px;
   width: 100%;
 }
 
 .mfp-counter {
   position: absolute;
   top: 0;
   right: 0;
   color: #CCC;
   font-size: 12px;
   line-height: 18px;
   white-space: nowrap;
 }
 
 .mfp-arrow {
   position: absolute;
   opacity: 0.65;
   margin: 0;
   top: 50%;
   margin-top: -55px;
   padding: 0;
   width: 90px;
   height: 110px;
   -webkit-tap-highlight-color: transparent;
 }
 
 .mfp-arrow:active {
   margin-top: -54px;
 }
 
 .mfp-arrow:hover,
 .mfp-arrow:focus {
   opacity: 1;
 }
 
 .mfp-arrow:before,
 .mfp-arrow:after {
   content: "";
   display: block;
   width: 0;
   height: 0;
   position: absolute;
   left: 0;
   top: 0;
   margin-top: 35px;
   margin-left: 35px;
   border: medium inset transparent;
 }
 
 .mfp-arrow:after {
   border-top-width: 13px;
   border-bottom-width: 13px;
   top: 8px;
 }
 
 .mfp-arrow:before {
   border-top-width: 21px;
   border-bottom-width: 21px;
   opacity: 0.7;
 }
 
 .mfp-arrow-left {
   left: 0;
 }
 
 .mfp-arrow-left:after {
   border-right: 17px solid #FFF;
   margin-left: 31px;
 }
 
 .mfp-arrow-left:before {
   margin-left: 25px;
   border-right: 27px solid #3F3F3F;
 }
 
 .mfp-arrow-right {
   right: 0;
 }
 
 .mfp-arrow-right:after {
   border-left: 17px solid #FFF;
   margin-left: 39px;
 }
 
 .mfp-arrow-right:before {
   border-left: 27px solid #3F3F3F;
 }
 
 .mfp-iframe-holder {
   padding-top: 40px;
   padding-bottom: 40px;
 }
 
 .mfp-iframe-holder .mfp-content {
   line-height: 0;
   width: 100%;
   max-width: 900px;
 }
 
 .mfp-iframe-holder .mfp-close {
   top: -40px;
 }
 
 .mfp-iframe-scaler {
   width: 100%;
   height: 0;
   overflow: hidden;
   padding-top: 56.25%;
 }
 
 .mfp-iframe-scaler iframe {
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
   background: #000;
 }
 
 /* Main image in popup */
 img.mfp-img {
   width: auto;
   max-width: 100%;
   height: auto;
   display: block;
   line-height: 0;
   box-sizing: border-box;
   padding: 40px 0 40px;
   margin: 0 auto;
 }
 
 /* The shadow behind the image */
 .mfp-figure {
   line-height: 0;
 }
 
 .mfp-figure:after {
   content: "";
   position: absolute;
   left: 0;
   top: 40px;
   bottom: 40px;
   display: block;
   right: 0;
   width: auto;
   height: auto;
   z-index: -1;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
   background: #444;
 }
 
 .mfp-figure small {
   color: #BDBDBD;
   display: block;
   font-size: 12px;
   line-height: 14px;
 }
 
 .mfp-figure figure {
   margin: 0;
 }
 
 .mfp-bottom-bar {
   margin-top: -36px;
   position: absolute;
   top: 100%;
   left: 0;
   width: 100%;
   cursor: auto;
 }
 
 .mfp-title {
   text-align: left;
   line-height: 18px;
   color: #F3F3F3;
   word-wrap: break-word;
   padding-right: 36px;
 }
 
 .mfp-image-holder .mfp-content {
   max-width: 100%;
 }
 
 .mfp-gallery .mfp-image-holder .mfp-figure {
   cursor: pointer;
 }
 
 @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
   /**
     * Remove all paddings around the image on small screen
     */
   .mfp-img-mobile .mfp-image-holder {
     padding-left: 0;
     padding-right: 0;
   }
 
   .mfp-img-mobile img.mfp-img {
     padding: 0;
   }
 
   .mfp-img-mobile .mfp-figure:after {
     top: 0;
     bottom: 0;
   }
 
   .mfp-img-mobile .mfp-figure small {
     display: inline;
     margin-left: 5px;
   }
 
   .mfp-img-mobile .mfp-bottom-bar {
     background: rgba(0, 0, 0, 0.6);
     bottom: 0;
     margin: 0;
     top: auto;
     padding: 3px 5px;
     position: fixed;
     box-sizing: border-box;
   }
 
   .mfp-img-mobile .mfp-bottom-bar:empty {
     padding: 0;
   }
 
   .mfp-img-mobile .mfp-counter {
     right: 5px;
     top: 3px;
   }
 
   .mfp-img-mobile .mfp-close {
     top: 0;
     right: 0;
     width: 35px;
     height: 35px;
     line-height: 35px;
     background: rgba(0, 0, 0, 0.6);
     position: fixed;
     text-align: center;
     padding: 0;
   }
 }
 @media all and (max-width: 900px) {
   .mfp-arrow {
     -webkit-transform: scale(0.75);
     transform: scale(0.75);
   }
 
   .mfp-arrow-left {
     -webkit-transform-origin: 0;
     transform-origin: 0;
   }
 
   .mfp-arrow-right {
     -webkit-transform-origin: 100%;
     transform-origin: 100%;
   }
 
   .mfp-container {
     padding-left: 15px;
     padding-right: 15px;
   }
 }
 /*-------  1.5 Bootstrap Datepicker  --------*/
 .datepicker--cell-day.-other-month-,
 .datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .datepicker--cell-day.-other-month-:hover,
 .datepicker--cell-year.-other-decade-:hover {
   color: #c5c5c5;
 }
 
 .-disabled-.-focus-.datepicker--cell-day.-other-month-,
 .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .-selected-.datepicker--cell-day.-other-month-,
 .-selected-.datepicker--cell-year.-other-decade- {
   color: #fff;
   background: #a2ddf6;
 }
 
 .-selected-.-focus-.datepicker--cell-day.-other-month-,
 .-selected-.-focus-.datepicker--cell-year.-other-decade- {
   background: #8ad5f4;
 }
 
 .-in-range-.datepicker--cell-day.-other-month-,
 .-in-range-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.1);
   color: #cccccc;
 }
 
 .-in-range-.-focus-.datepicker--cell-day.-other-month-,
 .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell-day.-other-month-:empty,
 .datepicker--cell-year.-other-decade-:empty {
   background: none;
   border: none;
 }
 
 /* -------------------------------------------------
     Datepicker cells
    ------------------------------------------------- */
 .datepicker--cells {
   display: flex;
   flex-wrap: wrap;
 }
 
 .datepicker--cell {
   border-radius: 4px;
   box-sizing: border-box;
   cursor: pointer;
   display: flex;
   position: relative;
   align-items: center;
   justify-content: center;
   height: 32px;
   z-index: 1;
 }
 
 .datepicker--cell.-focus- {
   background: #f0f0f0;
 }
 
 .datepicker--cell.-current- {
   color: #4EB5E6;
 }
 
 .datepicker--cell.-current-.-focus- {
   color: #4a4a4a;
 }
 
 .datepicker--cell.-current-.-in-range- {
   color: #4EB5E6;
 }
 
 .datepicker--cell.-in-range- {
   background: rgba(92, 196, 239, 0.1);
   color: #4a4a4a;
   border-radius: 0;
 }
 
 .datepicker--cell.-in-range-.-focus- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell.-disabled- {
   cursor: default;
   color: #aeaeae;
 }
 
 .datepicker--cell.-disabled-.-focus- {
   color: #aeaeae;
 }
 
 .datepicker--cell.-disabled-.-in-range- {
   color: #a1a1a1;
 }
 
 .datepicker--cell.-disabled-.-current-.-focus- {
   color: #aeaeae;
 }
 
 .datepicker--cell.-range-from- {
   border: 1px solid rgba(92, 196, 239, 0.5);
   background-color: rgba(92, 196, 239, 0.1);
   border-radius: 4px 0 0 4px;
 }
 
 .datepicker--cell.-range-to- {
   border: 1px solid rgba(92, 196, 239, 0.5);
   background-color: rgba(92, 196, 239, 0.1);
   border-radius: 0 4px 4px 0;
 }
 
 .datepicker--cell.-range-from-.-range-to- {
   border-radius: 4px;
 }
 
 .datepicker--cell.-selected- {
   color: #fff;
   border: none;
   background: #5cc4ef;
 }
 
 .datepicker--cell.-selected-.-current- {
   color: #fff;
   background: #5cc4ef;
 }
 
 .datepicker--cell.-selected-.-focus- {
   background: #45bced;
 }
 
 .datepicker--cell:empty {
   cursor: default;
 }
 
 .datepicker--days-names {
   display: flex;
   flex-wrap: wrap;
   margin: 8px 0 3px;
 }
 
 .datepicker--day-name {
   color: #FF9A19;
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 1;
   text-align: center;
   text-transform: uppercase;
   font-size: 0.8em;
 }
 
 .datepicker--cell-day {
   width: 14.28571%;
 }
 
 .datepicker--cells-months {
   height: 170px;
 }
 
 .datepicker--cell-month {
   width: 33.33%;
   height: 25%;
 }
 
 .datepicker--years {
   height: 170px;
 }
 
 .datepicker--cells-years {
   height: 170px;
 }
 
 .datepicker--cell-year {
   width: 25%;
   height: 33.33%;
 }
 
 .datepicker--cell-day.-other-month-,
 .datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .datepicker--cell-day.-other-month-:hover,
 .datepicker--cell-year.-other-decade-:hover {
   color: #c5c5c5;
 }
 
 .-disabled-.-focus-.datepicker--cell-day.-other-month-,
 .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .-selected-.datepicker--cell-day.-other-month-,
 .-selected-.datepicker--cell-year.-other-decade- {
   color: #fff;
   background: #a2ddf6;
 }
 
 .-selected-.-focus-.datepicker--cell-day.-other-month-,
 .-selected-.-focus-.datepicker--cell-year.-other-decade- {
   background: #8ad5f4;
 }
 
 .-in-range-.datepicker--cell-day.-other-month-,
 .-in-range-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.1);
   color: #cccccc;
 }
 
 .-in-range-.-focus-.datepicker--cell-day.-other-month-,
 .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell-day.-other-month-:empty,
 .datepicker--cell-year.-other-decade-:empty {
   background: none;
   border: none;
 }
 
 /* -------------------------------------------------
     Datepicker
    ------------------------------------------------- */
 .datepickers-container {
   position: absolute;
   left: 0;
   top: 0;
 }
 
 @media print {
   .datepickers-container {
     display: none;
   }
 }
 .datepicker {
   background: #fff;
   border: 1px solid #dbdbdb;
   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   box-sizing: content-box;
   font-family: Tahoma, sans-serif;
   font-size: 14px;
   color: #4a4a4a;
   width: 250px;
   position: absolute;
   left: -100000px;
   opacity: 0;
   transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
   transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
   transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
   z-index: 100;
 }
 
 .datepicker.-from-top- {
   -webkit-transform: translateY(-8px);
   transform: translateY(-8px);
 }
 
 .datepicker.-from-right- {
   -webkit-transform: translateX(8px);
   transform: translateX(8px);
 }
 
 .datepicker.-from-bottom- {
   -webkit-transform: translateY(8px);
   transform: translateY(8px);
 }
 
 .datepicker.-from-left- {
   -webkit-transform: translateX(-8px);
   transform: translateX(-8px);
 }
 
 .datepicker.active {
   opacity: 1;
   -webkit-transform: translate(0);
   transform: translate(0);
   transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
   transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
   transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
 }
 
 .datepicker-inline .datepicker {
   border-color: #d7d7d7;
   box-shadow: none;
   position: static;
   left: auto;
   right: auto;
   opacity: 1;
   -webkit-transform: none;
   transform: none;
 }
 
 .datepicker-inline .datepicker--pointer {
   display: none;
 }
 
 .datepicker--content {
   box-sizing: content-box;
   padding: 4px;
 }
 
 .-only-timepicker- .datepicker--content {
   display: none;
 }
 
 .datepicker--pointer {
   position: absolute;
   background: #fff;
   border-top: 1px solid #dbdbdb;
   border-right: 1px solid #dbdbdb;
   width: 10px;
   height: 10px;
   z-index: -1;
 }
 
 .-top-left- .datepicker--pointer,
 .-top-center- .datepicker--pointer,
 .-top-right- .datepicker--pointer {
   top: calc(100% - 4px);
   -webkit-transform: rotate(135deg);
   transform: rotate(135deg);
 }
 
 .-right-top- .datepicker--pointer,
 .-right-center- .datepicker--pointer,
 .-right-bottom- .datepicker--pointer {
   right: calc(100% - 4px);
   -webkit-transform: rotate(225deg);
   transform: rotate(225deg);
 }
 
 .-bottom-left- .datepicker--pointer,
 .-bottom-center- .datepicker--pointer,
 .-bottom-right- .datepicker--pointer {
   bottom: calc(100% - 4px);
   -webkit-transform: rotate(315deg);
   transform: rotate(315deg);
 }
 
 .-left-top- .datepicker--pointer,
 .-left-center- .datepicker--pointer,
 .-left-bottom- .datepicker--pointer {
   left: calc(100% - 4px);
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
 }
 
 .-top-left- .datepicker--pointer,
 .-bottom-left- .datepicker--pointer {
   left: 10px;
 }
 
 .-top-right- .datepicker--pointer,
 .-bottom-right- .datepicker--pointer {
   right: 10px;
 }
 
 .-top-center- .datepicker--pointer,
 .-bottom-center- .datepicker--pointer {
   left: calc(50% - 5px);
 }
 
 .-left-top- .datepicker--pointer,
 .-right-top- .datepicker--pointer {
   top: 10px;
 }
 
 .-left-bottom- .datepicker--pointer,
 .-right-bottom- .datepicker--pointer {
   bottom: 10px;
 }
 
 .-left-center- .datepicker--pointer,
 .-right-center- .datepicker--pointer {
   top: calc(50% - 5px);
 }
 
 .datepicker--body {
   display: none;
 }
 
 .datepicker--body.active {
   display: block;
 }
 
 .datepicker--cell-day.-other-month-,
 .datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .datepicker--cell-day.-other-month-:hover,
 .datepicker--cell-year.-other-decade-:hover {
   color: #c5c5c5;
 }
 
 .-disabled-.-focus-.datepicker--cell-day.-other-month-,
 .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .-selected-.datepicker--cell-day.-other-month-,
 .-selected-.datepicker--cell-year.-other-decade- {
   color: #fff;
   background: #a2ddf6;
 }
 
 .-selected-.-focus-.datepicker--cell-day.-other-month-,
 .-selected-.-focus-.datepicker--cell-year.-other-decade- {
   background: #8ad5f4;
 }
 
 .-in-range-.datepicker--cell-day.-other-month-,
 .-in-range-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.1);
   color: #cccccc;
 }
 
 .-in-range-.-focus-.datepicker--cell-day.-other-month-,
 .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell-day.-other-month-:empty,
 .datepicker--cell-year.-other-decade-:empty {
   background: none;
   border: none;
 }
 
 /* -------------------------------------------------
     Navigation
    ------------------------------------------------- */
 .datepicker--nav {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #efefef;
   min-height: 32px;
   padding: 4px;
 }
 
 .-only-timepicker- .datepicker--nav {
   display: none;
 }
 
 .datepicker--nav-title,
 .datepicker--nav-action {
   display: flex;
   cursor: pointer;
   align-items: center;
   justify-content: center;
 }
 
 .datepicker--nav-action {
   width: 32px;
   border-radius: 4px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 .datepicker--nav-action:hover {
   background: #f0f0f0;
 }
 
 .datepicker--nav-action.-disabled- {
   visibility: hidden;
 }
 
 .datepicker--nav-action svg {
   width: 32px;
   height: 32px;
 }
 
 .datepicker--nav-action path {
   fill: none;
   stroke: #9c9c9c;
   stroke-width: 2px;
 }
 
 .datepicker--nav-title {
   border-radius: 4px;
   padding: 0 8px;
 }
 
 .datepicker--nav-title i {
   font-style: normal;
   color: #9c9c9c;
   margin-left: 5px;
 }
 
 .datepicker--nav-title:hover {
   background: #f0f0f0;
 }
 
 .datepicker--nav-title.-disabled- {
   cursor: default;
   background: none;
 }
 
 .datepicker--buttons {
   display: flex;
   padding: 4px;
   border-top: 1px solid #efefef;
 }
 
 .datepicker--button {
   color: #4EB5E6;
   cursor: pointer;
   border-radius: 4px;
   flex: 1;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   height: 32px;
 }
 
 .datepicker--button:hover {
   color: #4a4a4a;
   background: #f0f0f0;
 }
 
 .datepicker--cell-day.-other-month-,
 .datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .datepicker--cell-day.-other-month-:hover,
 .datepicker--cell-year.-other-decade-:hover {
   color: #c5c5c5;
 }
 
 .-disabled-.-focus-.datepicker--cell-day.-other-month-,
 .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .-selected-.datepicker--cell-day.-other-month-,
 .-selected-.datepicker--cell-year.-other-decade- {
   color: #fff;
   background: #a2ddf6;
 }
 
 .-selected-.-focus-.datepicker--cell-day.-other-month-,
 .-selected-.-focus-.datepicker--cell-year.-other-decade- {
   background: #8ad5f4;
 }
 
 .-in-range-.datepicker--cell-day.-other-month-,
 .-in-range-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.1);
   color: #cccccc;
 }
 
 .-in-range-.-focus-.datepicker--cell-day.-other-month-,
 .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell-day.-other-month-:empty,
 .datepicker--cell-year.-other-decade-:empty {
   background: none;
   border: none;
 }
 
 /* -------------------------------------------------
     Timepicker
    ------------------------------------------------- */
 .datepicker--time {
   border-top: 1px solid #efefef;
   display: flex;
   align-items: center;
   padding: 4px;
   position: relative;
 }
 
 .datepicker--time.-am-pm- .datepicker--time-sliders {
   flex: 0 1 138px;
   max-width: 138px;
 }
 
 .-only-timepicker- .datepicker--time {
   border-top: none;
 }
 
 .datepicker--time-sliders {
   flex: 0 1 153px;
   margin-right: 10px;
   max-width: 153px;
 }
 
 .datepicker--time-label {
   display: none;
   font-size: 12px;
 }
 
 .datepicker--time-current {
   display: flex;
   align-items: center;
   flex: 1;
   font-size: 14px;
   text-align: center;
   margin: 0 0 0 10px;
 }
 
 .datepicker--time-current-colon {
   margin: 0 2px 3px;
   line-height: 1;
 }
 
 .datepicker--time-current-hours,
 .datepicker--time-current-minutes {
   line-height: 1;
   font-size: 19px;
   font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
   position: relative;
   z-index: 1;
 }
 
 .datepicker--time-current-hours:after,
 .datepicker--time-current-minutes:after {
   content: "";
   background: #f0f0f0;
   border-radius: 4px;
   position: absolute;
   left: -2px;
   top: -3px;
   right: -2px;
   bottom: -2px;
   z-index: -1;
   opacity: 0;
 }
 
 .datepicker--time-current-hours.-focus-:after,
 .datepicker--time-current-minutes.-focus-:after {
   opacity: 1;
 }
 
 .datepicker--time-current-ampm {
   text-transform: uppercase;
   align-self: flex-end;
   color: #9c9c9c;
   margin-left: 6px;
   font-size: 11px;
   margin-bottom: 1px;
 }
 
 .datepicker--time-row {
   display: flex;
   align-items: center;
   font-size: 11px;
   height: 17px;
   background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
 }
 
 .datepicker--time-row:first-child {
   margin-bottom: 4px;
 }
 
 .datepicker--time-row input[type=range] {
   background: none;
   cursor: pointer;
   flex: 1;
   height: 100%;
   padding: 0;
   margin: 0;
   -webkit-appearance: none;
 }
 
 .datepicker--time-row input[type=range]::-webkit-slider-thumb {
   -webkit-appearance: none;
 }
 
 .datepicker--time-row input[type=range]::-ms-tooltip {
   display: none;
 }
 
 .datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
   border-color: #b8b8b8;
 }
 
 .datepicker--time-row input[type=range]:hover::-moz-range-thumb {
   border-color: #b8b8b8;
 }
 
 .datepicker--time-row input[type=range]:hover::-ms-thumb {
   border-color: #b8b8b8;
 }
 
 .datepicker--time-row input[type=range]:focus {
   outline: none;
 }
 
 .datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
   background: #5cc4ef;
   border-color: #5cc4ef;
 }
 
 .datepicker--time-row input[type=range]:focus::-moz-range-thumb {
   background: #5cc4ef;
   border-color: #5cc4ef;
 }
 
 .datepicker--time-row input[type=range]:focus::-ms-thumb {
   background: #5cc4ef;
   border-color: #5cc4ef;
 }
 
 .datepicker--time-row input[type=range]::-webkit-slider-thumb {
   box-sizing: border-box;
   height: 12px;
   width: 12px;
   border-radius: 3px;
   border: 1px solid #dedede;
   background: #fff;
   cursor: pointer;
   -webkit-transition: background 0.2s;
   transition: background 0.2s;
 }
 
 .datepicker--time-row input[type=range]::-moz-range-thumb {
   box-sizing: border-box;
   height: 12px;
   width: 12px;
   border-radius: 3px;
   border: 1px solid #dedede;
   background: #fff;
   cursor: pointer;
   -moz-transition: background 0.2s;
   transition: background 0.2s;
 }
 
 .datepicker--time-row input[type=range]::-ms-thumb {
   box-sizing: border-box;
   height: 12px;
   width: 12px;
   border-radius: 3px;
   border: 1px solid #dedede;
   background: #fff;
   cursor: pointer;
   -ms-transition: background 0.2s;
   transition: background 0.2s;
 }
 
 .datepicker--time-row input[type=range]::-webkit-slider-thumb {
   margin-top: -6px;
 }
 
 .datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
   border: none;
   height: 1px;
   cursor: pointer;
   color: transparent;
   background: transparent;
 }
 
 .datepicker--time-row input[type=range]::-moz-range-track {
   border: none;
   height: 1px;
   cursor: pointer;
   color: transparent;
   background: transparent;
 }
 
 .datepicker--time-row input[type=range]::-ms-track {
   border: none;
   height: 1px;
   cursor: pointer;
   color: transparent;
   background: transparent;
 }
 
 .datepicker--time-row input[type=range]::-ms-fill-lower {
   background: transparent;
 }
 
 .datepicker--time-row input[type=range]::-ms-fill-upper {
   background: transparent;
 }
 
 .datepicker--time-row span {
   padding: 0 12px;
 }
 
 .datepicker--time-icon {
   color: #9c9c9c;
   border: 1px solid;
   border-radius: 50%;
   font-size: 16px;
   position: relative;
   margin: 0 5px -1px 0;
   width: 1em;
   height: 1em;
 }
 
 .datepicker--time-icon:after,
 .datepicker--time-icon:before {
   content: "";
   background: currentColor;
   position: absolute;
 }
 
 .datepicker--time-icon:after {
   height: 0.4em;
   width: 1px;
   left: calc(50% - 1px);
   top: calc(50% + 1px);
   -webkit-transform: translateY(-100%);
   transform: translateY(-100%);
 }
 
 .datepicker--time-icon:before {
   width: 0.4em;
   height: 1px;
   top: calc(50% + 1px);
   left: calc(50% - 1px);
 }
 
 .datepicker--cell-day.-other-month-,
 .datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .datepicker--cell-day.-other-month-:hover,
 .datepicker--cell-year.-other-decade-:hover {
   color: #c5c5c5;
 }
 
 .-disabled-.-focus-.datepicker--cell-day.-other-month-,
 .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
   color: #dedede;
 }
 
 .-selected-.datepicker--cell-day.-other-month-,
 .-selected-.datepicker--cell-year.-other-decade- {
   color: #fff;
   background: #a2ddf6;
 }
 
 .-selected-.-focus-.datepicker--cell-day.-other-month-,
 .-selected-.-focus-.datepicker--cell-year.-other-decade- {
   background: #8ad5f4;
 }
 
 .-in-range-.datepicker--cell-day.-other-month-,
 .-in-range-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.1);
   color: #cccccc;
 }
 
 .-in-range-.-focus-.datepicker--cell-day.-other-month-,
 .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
   background-color: rgba(92, 196, 239, 0.2);
 }
 
 .datepicker--cell-day.-other-month-:empty,
 .datepicker--cell-year.-other-decade-:empty {
   background: none;
   border: none;
 }
 
 /*---------------------------------------*/
 /*--------         2. Fonts Icons      --------*/
 /*---------------------------------------*/
 @font-face {
   font-family: "club";
  src: url("../font-icons/fonts/club2f73.eot?9gw8hp");
   src: url("../font-icons/fonts/club2f73.eot?9gw8hp#iefix") format("embedded-opentype"), url("../font-icons/fonts/club2f73.ttf?9gw8hp") format("truetype"), url("../font-icons/fonts/club2f73.woff?9gw8hp") format("woff"), url("../font-icons/fonts/club2f73.svg?9gw8hp#club") format("svg");
   font-weight: normal;
   font-style: normal;
   font-display: block;
 }
 [class^=icon-], [class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: "club" !important;
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 .icon-user1:before {
   content: "\e919";
 }
 
 .icon-social-facebook:before {
   content: "\e900";
 }
 
 .icon-social-googleplus:before {
   content: "\e901";
 }
 
 .icon-social-instagram:before {
   content: "\e902";
 }
 
 .icon-social-linkedin:before {
   content: "\e903";
 }
 
 .icon-social-twitter:before {
   content: "\e904";
 }
 
 .icon-tables:before {
   content: "\e905";
 }
 
 .icon-wineglass:before {
   content: "\e906";
 }
 
 .icon-arrow_bottom:before {
   content: "\e907";
 }
 
 .icon-arrow_left:before {
   content: "\e908";
 }
 
 .icon-arrow_right:before {
   content: "\e909";
 }
 
 .icon-arrow_top:before {
   content: "\e90a";
 }
 
 .icon-calendar:before {
   content: "\e90b";
 }
 
 .icon-cart:before {
   content: "\e90c";
 }
 
 .icon-chat:before {
   content: "\e90d";
 }
 
 .icon-clock:before {
   content: "\e90e";
 }
 
 .icon-close:before {
   content: "\e90f";
 }
 
 .icon-cup:before {
   content: "\e910";
 }
 
 .icon-label:before {
   content: "\e911";
 }
 
 .icon-label_02:before {
   content: "\e912";
 }
 
 .icon-mail:before {
   content: "\e913";
 }
 
 .icon-menu-toggle:before {
   content: "\e914";
 }
 
 .icon-phone:before {
   content: "\e915";
 }
 
 .icon-place:before {
   content: "\e916";
 }
 
 .icon-private:before {
   content: "\e917";
 }
 
 .icon-search:before {
   content: "\e918";
 }
 
 /*---------------------------------------*/
 /*----       3. Variables              --*/
 /*---------------------------------------*/
 /*
     Theme variables
 */
 /* font */
 /* default */
 /*---------------------------------------*/
 /*--------   4. Typography       --------*/
 /*---------------------------------------*/
 /*
     Typography
 */
 body {
   font-family: "Roboto", sans-serif;
   font-size: 17px;
   line-height: 27px;
   color: #888888;
   background: #ffffff;
   margin: 0;
   overflow-x: hidden;
   overflow-y: scroll;
 }
 @media (max-width: 1024px) {
   body {
     font-size: 15px;
     line-height: 22px;
   }
 }
 
 a {
   text-decoration: none;
   outline: none;
   color: #FF1A43;
 }
 
 a:hover {
   outline: none;
   text-decoration: none;
   outline: none;
 }
 
 a:active,
 a:visited,
 a:focus {
   text-decoration: none;
   outline: none;
 }
 
 strong {
   font-weight: 800;
 }
 
 input[type=text] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
 }
 
 img {
   max-width: 100%;
   height: auto;
 }
 
 ul {
   list-style: none;
   margin: 0;
   padding: 0;
 }
 
 p {
   margin: 16px 0 0 0px;
 }
 @media (max-width: 575px) {
   p {
     margin: 10px 0 0 0px;
   }
 }
 
 address {
   margin: 0;
 }
 
 audio {
   width: 100%;
   display: block;
 }
 
 input::-ms-clear {
   display: none;
 }
 
 .divider {
   height: 46px;
   clear: both;
   overflow: hidden;
   width: 100%;
 }
 @media (max-width: 1024px) {
   .divider {
     height: 40px;
   }
 }
 @media (max-width: 575px) {
   .divider {
     height: 32px;
   }
 }
 
 .no-gutters.container-fluid {
   padding-left: 0;
   padding-right: 0;
 }
 
 /* button */
 .tt-btn {
   letter-spacing: -0.01em;
   font-weight: bold;
   font-size: 16px;
   line-height: 17px;
   padding: 19px 31px 20px;
   color: #ffffff;
   cursor: pointer;
   display: inline-block;
   border-left: 2px solid currentColor;
   border-right: 2px solid currentColor;
   position: relative;
   font-family: "Poppins", sans-serif;
   text-align: center;
   box-shadow: none;
   outline: none;
 }
 @media (min-width: 1025px) {
   .tt-btn.tt-btn__wide {
     padding-left: 31px;
     padding-right: 31px;
   }
 }
 .tt-btn::before, .tt-btn::after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 2px;
   background: currentColor;
   transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
   pointer-events: none;
 }
 .tt-btn::before {
   right: calc(66% + 5px);
 }
 .tt-btn::after {
   left: calc(39% + 5px);
 }
 .tt-btn span {
   display: block;
 }
 .tt-btn span::before, .tt-btn span::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   height: 2px;
   background: currentColor;
   transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
   pointer-events: none;
 }
 .tt-btn span::before {
   right: calc(86% + 5px);
 }
 .tt-btn span::after {
   left: calc(19% + 5px);
 }
 .tt-btn:hover {
   color: #ffffff;
 }
 .tt-btn:hover span {
   color: currentColor;
 }
 .tt-btn:hover::before {
   right: calc(59% + 5px);
 }
 .tt-btn:hover::after {
   left: calc(46% + 5px);
 }
 .tt-btn:hover span::before {
   right: calc(93% + 5px);
 }
 .tt-btn:hover span::after {
   left: calc(11% + 5px);
 }
 @media (max-width: 1024px) {
   .tt-btn {
     padding: 16px 29px 17px;
     font-size: 13px;
   }
 }
 @media (max-width: 767px) {
   .tt-btn {
     padding: 11px 24px 12px;
     font-size: 13px;
   }
 }
 
 .tt-btn:active,
 .tt-btn:visited,
 .tt-btn:focus,
 .tt-btn:focus {
   outline: none;
   box-shadow: none;
 }
 
 button:focus {
   outline: none;
 }
 
 .tt-btn-default {
   display: inline-block;
   position: relative;
   text-align: center;
   padding: 2px;
   background: linear-gradient(270deg, #FF1A43 -0.23%, #7B16D9 100%);
   box-shadow: none;
   border: none;
   outline: none;
 }
 .tt-btn-default span {
   display: inline-block;
   letter-spacing: -0.01em;
   font-weight: bold;
   font-size: 16px;
   line-height: 17px;
   padding: 17px 21px 20px;
   height: 52px;
   color: #ffffff;
   background: #252427;
   cursor: pointer;
   font-family: "Poppins", sans-serif;
 }
 @media (min-width: 1025px) {
   .tt-btn-default span {
     min-width: 152px;
   }
 }
 .tt-btn-default:before, .tt-btn-default:after {
   content: "";
   width: 18px;
   height: 2px;
   background-color: #ffffff;
   position: absolute;
   transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
 }
 .tt-btn-default:before {
   top: 0;
   left: 35.6%;
 }
 .tt-btn-default:after {
   bottom: 0;
   left: 19%;
 }
 .tt-btn-default:hover:before {
   left: 45.6%;
 }
 .tt-btn-default:hover:after {
   left: 10%;
 }
 @media (max-width: 1024px) {
   .tt-btn-default span {
     height: 46px;
     font-size: 13px;
     padding: 14px 28px 20px;
   }
 }
 @media (max-width: 767px) {
   .tt-btn-default span {
     height: 35px;
     font-size: 13px;
     padding: 9px 18px 12px;
   }
 }
 @media (min-width: 1025px) {
   .tt-btn-default.tt-btn__wide span {
     padding-left: 35px;
     padding-right: 35px;
   }
 }
 .tt-btn-default.tt-btn-default__small span {
   font-size: 16px;
   line-height: 17px;
   padding: 7px 15px 10px;
   height: 30px;
   letter-spacing: -0.01em;
   min-width: inherit;
 }
 
 .tt-btn-top {
   margin-top: 55px;
 }
 
 .tt-btn__wide-top {
   margin-top: 45px;
 }
 
 .tt-link {
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   line-height: 14px;
   letter-spacing: -0.01em;
   font-weight: bold;
   display: inline-block;
   transition: color 0.2s linear;
 }
 .tt-link:hover {
   color: #FF1A43;
 }
 
 .tt-link-top {
   margin-top: 27px;
 }
 
 .tt-width-01 {
   max-width: 520px;
 }
 
 .tt-link02 {
   color: #FF1A43;
   display: inline-block;
   position: relative;
 }
 .tt-link02:before {
   position: absolute;
   bottom: 4px;
   left: 0;
   right: 0;
   margin: auto;
   width: 0%;
   content: "";
   color: transparent;
   background: #FF1A43;
   height: 1px;
   transition: width 0.2s;
 }
 .tt-link02:hover {
   color: #FF1A43;
 }
 .tt-link02:hover:before {
   width: 100%;
 }
 
 .section-title {
   text-align: center;
   padding: 0px 25px;
   margin-bottom: 44px;
   position: relative;
 }
 .section-title.section-title_bottom-none {
   margin-bottom: 11px;
 }
 .section-title .section-title__label {
   color: #5C5C5C;
   letter-spacing: 0.2em;
   font-size: 15px;
   line-height: 20px;
   position: relative;
   z-index: 2;
   padding: 0 0 43px 0;
 }
 .section-title .section-title__label + .section-title__text {
   padding-top: 0;
   padding-bottom: 0px;
 }
 .section-title .section-title__text {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 48px;
   line-height: 50px;
   letter-spacing: -0.03em;
   position: relative;
   z-index: 2;
   padding-top: 88px;
   margin: 0;
   color: #ffffff;
 }
 .section-title .section-title__text a.tt-base-color {
   color: #FF1A43;
   text-decoration: none;
   display: inline-block;
   position: relative;
 }
 .section-title .section-title__text a.tt-base-color:before {
   content: "";
   display: block;
   position: absolute;
   bottom: -3px;
   width: 0%;
   height: 3px;
   margin: auto;
   left: 0;
   right: 0;
   background: linear-gradient(270deg, #FF1A43 -0.23%, #7B16D9 100%);
   transition: width 0.2s linear;
 }
 .section-title .section-title__text a.tt-base-color:hover:before {
   width: 100%;
 }
 .section-title .section-title__link {
   display: inline-block;
   z-index: 3;
   margin-top: 13px;
 }
 .section-title .section-title__text-under {
   position: absolute;
   text-align: center;
   color: #FFFFFF;
   font-weight: bold;
   opacity: 0.4;
   font-size: 200px;
   line-height: 18px;
   text-shadow: 0px 2px 7px rgba(52, 52, 52, 0.3);
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   top: 85px;
   left: 50%;
   z-index: 1;
   font-family: "Poppins", sans-serif;
 }
 .section-title .title__text-description {
   max-width: 598px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 16px;
   margin-bottom: -11px;
 }
 .section-title .title__text-description.tt-wide {
   max-width: 859px;
 }
 .section-title .link-01 {
   color: #ffffff;
   font-weight: bold;
   font-size: 18px;
   font-family: "Poppins", sans-serif;
   margin-top: 3px;
   display: inline-block;
   transition: color 0.2s;
 }
 .section-title .link-01:before {
   content: "";
   display: inline-block;
   border: 5px solid transparent;
   border-left: 9px solid #FF1A43;
   margin-right: 3px;
   position: relative;
   top: -1px;
 }
 .section-title .link-01:hover {
   color: #FF1A43;
 }
 .section-title.section-title_01 .section-title__text-under {
   top: 25px;
 }
 @media (max-width: 1024px) {
   .section-title.section-title_bottom-none {
     margin-bottom: 4px;
   }
   .section-title.section-title_bottom-none .section-title__text {
     padding-top: 64px;
   }
   .section-title .section-title__label {
     font-size: 13px;
   }
   .section-title .section-title__text {
     font-size: 35px;
     line-height: 37px;
     padding-top: 53px;
   }
   .section-title .section-title__text-under {
     font-size: 140px;
     line-height: 147px;
     top: 5px;
   }
   .section-title .title__text-description {
     margin-top: 13px;
   }
   .section-title .link-01 {
     font-size: 13px;
     line-height: 18px;
     margin-top: 1px;
   }
   .section-title .link-01:before {
     border: 4px solid transparent;
     border-left: 7px solid #FF1A43;
     top: 0px;
     margin-right: 0px;
   }
   .section-title.section-title_01 {
     margin-bottom: 24px;
   }
   .section-title.section-title_01 .section-title__label {
     padding-top: 18px;
     padding-bottom: 29px;
   }
   .section-title.section-title_01 .section-title__text-under {
     top: -37px;
   }
 }
 @media (min-width: 791px) {
   .section-title .section-title__link {
     position: absolute;
     right: 0;
     top: 50%;
     -webkit-transform: translateY(50%);
     transform: translateY(50%);
     z-index: 2;
   }
 }
 @media (max-width: 790px) {
   .section-title.section-title_bottom-none {
     padding-top: 25px;
   }
   .section-title.section-title_bottom-none .section-title__text-under {
     font-size: 100px;
     line-height: 94px;
     top: 59px;
   }
   .section-title.section-title_bottom-none .section-title__link {
     margin-top: 10px;
   }
   .section-title .section-title__text {
     font-size: 20px;
     line-height: 34px;
   }
   .section-title .section-title__text-under {
     font-size: 100px;
     line-height: 94px;
     top: 54px;
   }
   .section-title .section-title__link {
     margin-top: 2px;
     position: relative;
     z-index: 2;
   }
   .section-title.section-title_01 .section-title__text-under {
     top: 0;
     font-size: 140px;
   }
 }
 @media (max-width: 600px) {
   .section-title.section-title_01 .section-title__text-under {
     top: 0;
     font-size: 100px;
   }
 }
 @media (max-width: 576px) {
   .section-title {
     padding-left: 0;
     padding-right: 0;
   }
   .section-title.section-title_01 .section-title__text-under {
     top: 0;
     font-size: 90px;
   }
 }
 @media (max-width: 440px) {
   .section-title .section-title__text-under {
     font-size: 78px;
     line-height: 92px;
   }
 }
 
 .blocktitle {
   position: relative;
   padding-bottom: 15px;
 }
 .blocktitle .tt-caption {
   position: relative;
   z-index: 2;
   font-weight: normal;
   font-size: 15px;
   line-height: 24px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }
 .blocktitle .tt-title {
   font-size: 48px;
   line-height: 50px;
   letter-spacing: -0.03em;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
   position: relative;
   z-index: 2;
   margin: 37px 0 0 0;
 }
 .blocktitle .tt-title:after {
   content: "";
   display: inline-block;
   background: linear-gradient(270deg, #FF1A43 -0.23%, #7B16D9 100%);
   border-radius: 10px;
   width: 43px;
   height: 4px;
   margin-left: 15px;
   bottom: 9px;
   position: absolute;
 }
 .blocktitle .tt-title-under {
   font-size: 200px;
   line-height: 210px;
   position: absolute;
   top: -92px;
   left: -37px;
   z-index: 1;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
 }
 .blocktitle:not([class^=blocktitle_color-]) .tt-title {
   color: #ffffff;
 }
 .blocktitle:not([class^=blocktitle_color-]) .tt-title-under {
   text-shadow: 3px 4px 10px rgba(52, 52, 52, 0.1);
   color: #ffffff;
 }
 .blocktitle.blocktitle_color-01 .tt-title {
   color: #ffffff;
 }
 .blocktitle.blocktitle_color-01 .tt-title-under {
   text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.2);
   color: #3D3B42;
 }
 .blocktitle + p {
   margin-top: 0;
 }
 @media (max-width: 1500px) {
   .blocktitle .tt-caption {
     font-size: 14px;
     line-height: 22px;
   }
   .blocktitle .tt-title {
     font-size: 40px;
     line-height: 42px;
   }
   .blocktitle .tt-title-under {
     font-size: 160px;
     line-height: 170px;
   }
 }
 @media (max-width: 1024px) {
   .blocktitle {
     padding-bottom: 17px;
   }
   .blocktitle .tt-caption {
     font-size: 13px;
     line-height: 21px;
   }
   .blocktitle .tt-title {
     font-size: 35px;
     line-height: 37px;
     margin-top: 28px;
   }
   .blocktitle .tt-title:after {
     width: 34px;
     height: 3px;
   }
   .blocktitle .tt-title-under {
     font-size: 140px;
     line-height: 147px;
     top: -33px;
   }
 }
 @media (max-width: 767px) {
   .blocktitle {
     padding-bottom: 8px;
     padding-right: 21px;
   }
   .blocktitle .tt-caption {
     font-size: 13px;
     line-height: 21px;
   }
   .blocktitle .tt-title {
     font-size: 32px;
     line-height: 34px;
     margin-top: 25px;
   }
   .blocktitle .tt-title:after {
     width: 20px;
     height: 2px;
     margin-left: 8px;
     bottom: 6px;
   }
   .blocktitle .tt-title-under {
     font-size: 100px;
     line-height: 105px;
     top: -16px;
     left: 11px;
   }
 }
 
 .tt-base-color {
   color: #FF1A43;
 }
 
 .tt-subtitle {
   font-size: 36px;
   line-height: 38px;
   letter-spacing: -0.03em;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   color: #ffffff;
   margin: 0;
   padding: 0 0 19px 0;
 }
 @media (max-width: 1024px) {
   .tt-subtitle {
     font-size: 32px;
     line-height: 34px;
   }
 }
 @media (max-width: 790px) {
   .tt-subtitle {
     font-size: 28px;
     line-height: 30px;
   }
 }
 
 .tt-subtitle-top {
   margin-top: 37px;
 }
 
 /*
     List
 */
 .list-text-top {
   margin-top: 28px;
 }
 @media (max-width: 1024px) {
   .list-text-top {
     margin-top: 22px;
   }
 }
 
 .list-01 {
   margin-top: -8px;
   margin-bottom: -8px;
 }
 .list-01.list-text-top {
   margin-top: 28px;
 }
 @media (max-width: 575px) {
   .list-01.list-text-top {
     margin-top: 23px;
   }
 }
 .list-01 li {
   position: relative;
   color: #888888;
   font-size: 17px;
   line-height: 24px;
 }
 .list-01 li:before {
   content: "";
   width: 20px;
   height: 20px;
   background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTguODU2MTQgNC4xNTAzNEw1LjYwNTkyIDAuOTAwMDU0QzUuNTEzMTQgMC44MDcyNzQgNS4zODk0OSAwLjc1NjM0OCA1LjI1NzYzIDAuNzU2MzQ4QzUuMTI1NjMgMC43NTYzNDggNS4wMDIwNSAwLjgwNzM0NyA0LjkwOTI3IDAuOTAwMDU0TDQuNjE0MTggMS4xOTUyMkM0LjUyMTQ3IDEuMjg3ODYgNC40NzA0IDEuNDExNTkgNC40NzA0IDEuNTQzNTFDNC40NzA0IDEuNjc1MzYgNC41MjE0NyAxLjgwMzI3IDQuNjE0MTggMS44OTU5TDYuNTEwMyAzLjc5NjJIMC40ODYyMTVDMC4yMTQ2MDggMy43OTYyIDAgNC4wMDg4MyAwIDQuMjgwNTFWNC42OTc4QzAgNC45Njk0OCAwLjIxNDYwOCA1LjIwMzU2IDAuNDg2MjE1IDUuMjAzNTZINi41MzE4Mkw0LjYxNDI1IDcuMTE0NDdDNC41MjE1NCA3LjIwNzI1IDQuNDcwNDcgNy4zMjc2MSA0LjQ3MDQ3IDcuNDU5NTRDNC40NzA0NyA3LjU5MTMyIDQuNTIxNTQgNy43MTM0NCA0LjYxNDI1IDcuODA2MTRMNC45MDkzNCA4LjEwMDM2QzUuMDAyMTIgOC4xOTMxNCA1LjEyNTcxIDguMjQzNyA1LjI1NzcxIDguMjQzN0M1LjM4OTU2IDguMjQzNyA1LjUxMzIyIDguMTkyNDggNS42MDYgOC4wOTk3TDguODU2MjEgNC44NDk0OEM4Ljk0OTIxIDQuNzU2NDEgOS4wMDAzNSA0LjYzMjE3IDguOTk5OTkgNC41MDAxQzkuMDAwMjggNC4zNjc1OSA4Ljk0OTIxIDQuMjQzMjcgOC44NTYxNCA0LjE1MDM0WiIgZmlsbD0iI0ZGMUE0MyIvPg0KPC9zdmc+DQo=");
   background-repeat: no-repeat;
   position: absolute;
   top: 15px;
   left: 0;
 }
 .list-01 li a {
   display: inline-block;
   padding: 8px 0 8px 22px;
   color: #888888;
   transition: color 0.2s linear;
 }
 .list-01 li a:hover {
   color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .list-01 {
     padding-top: -5px;
     padding-bottom: -6px;
   }
   .list-01 li {
     font-size: 15px;
   }
   .list-01 li:before {
     top: 13px;
   }
   .list-01 li a {
     padding: 5px 0 6px 23px;
   }
 }
 
 .list-02 li {
   position: relative;
   padding: 0 0 0 20px;
 }
 .list-02 li:before {
   content: "";
   width: 5px;
   height: 5px;
   background-color: #FF1A43;
   border-radius: 50%;
   position: absolute;
   top: 11px;
   left: 0;
 }
 .list-02 li:not(:first-child) {
   margin-top: 26px;
 }
 
 .tt-list-box {
   margin-top: -10px;
   margin-left: -10px;
 }
 .tt-list-box li {
   margin: 10px 0 0 10px;
   display: inline-block;
 }
 .tt-list-box li a {
   font-size: 16px;
   line-height: 25px;
   color: #888888;
   border: 1px solid #D3D3D5;
   border-radius: 5px;
   padding: 8px 11px 8px;
   display: inline-block;
   transition: color 0.2s linear, border 0.2s linear;
 }
 .tt-list-box li a:hover {
   color: #FF1A43;
   border-color: #FF1A43;
 }
 @media (max-width: 1239px) {
   .tt-list-box li a {
     font-size: 15px;
     padding: 5px 9px;
   }
 }
 
 hr {
   border: none;
   box-shadow: none;
   height: 1px;
   width: 100%;
   background: #d8d8da;
   display: block;
   margin: 43px 0 49px 0;
   padding: 0;
   line-height: 0;
   font-size: 0;
 }
 
 #tt-pageContent {
   position: relative;
   background: #252427;
 }
 #tt-pageContent .tt-social-icon {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 #tt-pageContent .tt-social-icon li a {
   display: inline-block;
   padding: 5px 13px;
   color: #3D3B42;
   transition: color 0.2s linear;
   font-size: 15px;
   line-height: 15px;
   font-weight: bold;
 }
 #tt-pageContent .tt-social-icon li a:hover {
   color: #FF1A43;
 }
 #tt-pageContent .tt-social-icon li:first-child a {
   padding-left: 0;
 }
 #tt-pageContent .tt-social-icon li:last-child a {
   padding-right: 0;
 }
 @media (max-width: 1239px) {
   #tt-pageContent .tt-social-icon li a {
     padding: 5px 9px;
   }
 }
 #tt-pageContent:before {
   content: "";
   display: block;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   position: absolute;
   left: 50%;
   margin-top: 0px;
   border-radius: 3px;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   z-index: 12;
   width: 4px;
   height: 63px;
 }
 @media (min-width: 1025px) {
   #tt-pageContent:before {
     width: 4px;
     height: 63px;
     bottom: -32px;
   }
 }
 @media (max-width: 1024px) {
   #tt-pageContent:before {
     width: 3px;
     height: 46px;
     bottom: -23px;
   }
 }
 
 .tt-base-color {
   color: #FF1A43;
 }
 
 /* table responsive */
 @media (max-width: 789px) {
   .tt-table-responsive-md {
     overflow-y: hidden;
     overflow-x: scroll;
   }
   .tt-table-responsive-md > * {
     width: 789px;
   }
 }
 /*---------------------------------------*/
 /*--------     5. Header         --------*/
 /*---------------------------------------*/
 #tt-header {
   position: fixed;
   top: 0;
   background-color: #252427;
   width: 100%;
   height: 98px;
   z-index: 21;
   padding: 10px 0;
 }
 #tt-header .tt-holder {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: center;
 }
 @media (min-width: 1240px) {
   #tt-header .tt-holder {
     padding-left: 25px;
     padding-right: 25px;
   }
 }
 #tt-header .tt-wide {
   flex: 2 1 auto;
 }
 #tt-header.stick {
   background-color: #252427;
 }
 @media (max-width: 1239px) {
   #tt-header {
     background-color: #252427;
   }
 }
 #tt-header .tt-desctop-parent {
   margin-left: 6.5%;
 }
 #tt-header .tt-col-obj {
   margin-left: 5.5%;
   position: relative;
   top: 3px;
 }
 #tt-header #tt-nav {
   top: 4px;
   padding-right: 10px;
 }
 #tt-header .nav-btn {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   position: relative;
 }
 #tt-header .nav-btn li a {
   font-family: "Poppins", sans-serif;
   white-space: nowrap;
   font-weight: bold;
 }
 @media (min-width: 1240px) {
   #tt-header .nav-btn {
     top: 3px;
   }
   #tt-header .nav-btn li a {
     letter-spacing: -0.01em;
     font-size: 16px;
     line-height: 17px;
     padding: 19px 25px 20px;
     color: #ffffff;
     cursor: pointer;
     display: inline-block;
     border-left: 2px solid #ffffff;
     border-right: 2px solid #ffffff;
     position: relative;
   }
   #tt-header .nav-btn li a [class^=icon-] {
     display: none;
   }
   #tt-header .nav-btn li a::before, #tt-header .nav-btn li a::after {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     height: 2px;
     background: #ffffff;
     transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
   }
   #tt-header .nav-btn li a::before {
     right: calc(66% + 5px);
   }
   #tt-header .nav-btn li a::after {
     left: calc(39% + 5px);
   }
   #tt-header .nav-btn li a span {
     display: inline-block;
   }
   #tt-header .nav-btn li a span::before, #tt-header .nav-btn li a span::after {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     right: 0;
     height: 2px;
     background: #ffffff;
     transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
   }
   #tt-header .nav-btn li a span::before {
     right: calc(86% + 5px);
   }
   #tt-header .nav-btn li a span::after {
     left: calc(19% + 5px);
   }
   #tt-header .nav-btn li a:hover::before {
     right: calc(59% + 5px);
   }
   #tt-header .nav-btn li a:hover::after {
     left: calc(46% + 5px);
   }
   #tt-header .nav-btn li a:hover span::before {
     right: calc(93% + 5px);
   }
   #tt-header .nav-btn li a:hover span::after {
     left: calc(11% + 5px);
   }
   #tt-header .nav-btn li:not(:first-child) {
     margin-left: 30px;
   }
 }
 @media (max-width: 1239px) {
   #tt-header .nav-btn {
     top: 3px;
   }
   #tt-header .nav-btn li a {
     padding: 15px 17px;
   }
   #tt-header .nav-btn li a [class^=icon-] {
     font-size: 20px;
     margin-right: px;
     position: relative;
     top: 3px;
     margin-right: 8px;
     display: inline-block;
     color: #FF1A43;
   }
   #tt-header .nav-btn li a .tt-text {
     color: #ffffff;
     font-size: 16px;
     line-height: 14px;
     letter-spacing: -0.01em;
     transition: color 0.2s linear;
   }
   #tt-header .nav-btn li a .short-hide {
     display: none;
   }
   #tt-header .nav-btn li a:hover .tt-text {
     color: #FF1A43;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .nav-btn li a {
     padding: 15px 10px;
   }
   #tt-header .nav-btn li a [class^=icon-] {
     font-size: 16px;
     margin-right: 5px;
   }
   #tt-header .nav-btn li a [class^=icon-].icon-tables {
     font-size: 13px;
     top: 2px;
   }
   #tt-header .nav-btn li a [class^=icon-].icon-chat {
     font-size: 13px;
     top: 2px;
   }
   #tt-header .nav-btn li a .tt-text {
     font-size: 13px;
   }
 }
 #tt-header .tt-col-obj {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: center;
 }
 #tt-header .tt-obj {
   position: relative;
   /*search layout*/
 }
 #tt-header .tt-obj .tt-obj__btn {
   display: inline-block;
   padding: 15px 23px;
   color: #ffffff;
   transition: color 0.2s;
   line-height: 19px;
 }
 #tt-header .tt-obj .tt-obj__btn .tt-obj__badge {
   position: absolute;
   top: 7px;
   right: 15px;
   width: 17.27px;
   height: 17.27px;
   border-radius: 50%;
   font-size: 9px;
   line-height: 17px;
   text-align: center;
   background-color: #FF1A43;
   color: #ffffff;
 }
 #tt-header .tt-obj .tt-obj__btn [class^=icon-] {
   pointer-events: none;
 }
 #tt-header .tt-obj .tt-obj__btn:hover {
   color: #FF1A43;
 }
 #tt-header .tt-obj.tt-obj-chat .tt-obj__btn {
   font-size: 19px;
 }
 #tt-header .tt-obj.tt-obj-search:active .tt-dropdown-menu {
   z-index: 3;
 }
 @media (min-width: 1025px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu {
     position: fixed;
     z-index: 2;
     top: 0;
     left: 0;
     width: 100%;
     background: #ffffff;
     display: block;
     -webkit-transform: translate(0, -100%);
     transform: translate(0, -100%);
     -webkit-transform: translate3d(0, -100%, 0);
     transform: translate3d(0, -100%, 0);
     visibility: hidden;
     transition: all 0.2s linear;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu:before {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     display: block;
     width: 100%;
     height: 4px;
     background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .container {
     position: relative;
     padding-top: 30px;
     padding-bottom: 30px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form {
     position: relative;
     display: block;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-search-input {
     background: transparent;
     font-size: 24px;
     font-weight: 500;
     border: none;
     border-bottom: 1px solid rgba(0, 0, 0, 0.15);
     padding: 11px 12px 10px 0px;
     box-shadow: none;
     outline: none;
     width: 100%;
     color: #3D3B42;
     margin-top: 28px;
     border-right: 0;
     transition: all 0.2s linear;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-search-input:not(focus)::-webkit-input-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-search-input:not(focus)::-moz-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-search-input:not(focus):-moz-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-search-input:not(focus):-ms-input-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search {
     color: #3D3B42;
     font-size: 23px;
     font-weight: normal;
     line-height: 1;
     font-family: "club";
     position: absolute;
     cursor: pointer;
     top: 41px;
     padding: 4px 0 4px 4px;
     margin-top: -4px;
     right: 0px;
     z-index: 3;
     display: inline-block;
     background: none;
     border: none;
     transition: color 0.2s linear;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search:before {
     content: "\e918";
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search:hover {
     color: #FF1A43;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-info-text {
     position: absolute;
     top: 0;
     left: 0;
     color: #888888;
     font-size: 16px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close {
     display: inline-flex;
     position: absolute;
     top: 0;
     right: 0;
     margin-top: -10px;
     color: #3D3B42;
     font-size: 21px;
     line-height: 1;
     font-family: "club";
     padding: 5px 0px 5px 5px;
     background: none;
     border: none;
     cursor: pointer;
     outline: none;
     transition: color 0.2s linear;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close:before {
     font-family: "club";
     content: "\e90f";
     line-height: 1;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close:hover {
     color: #FF1A43;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-description {
     text-align: center;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-price {
     justify-content: center;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu > .container {
     display: none;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: flex-start;
     align-items: center;
     background: #ffffff;
     position: fixed;
     z-index: -2;
     opacity: 0;
     width: 100%;
     top: 0;
     left: 0;
     height: 64px;
   }
 }
 @media (max-width: 1024px) and (max-width: 767px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu {
     top: 34px;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu:before {
     content: "";
     display: block;
     width: calc(100% + 40px);
     z-index: 0;
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     background: #ffffff;
     margin-left: -20px;
     margin-right: -20px;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .container {
     max-width: 100%;
     padding-left: 0px;
     padding-right: 0px;
     position: relative;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .container [class^=tt-col]:first-child:first-child {
     padding-left: 0;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-info-text {
     display: none;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: flex-end;
     align-items: center;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-col:first-child {
     flex: 2 1 auto;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-col:last-child {
     margin-left: 20px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-search-input {
     background: transparent;
     font-size: 16px;
     border: 1px solid transparent;
     padding: 18px 12px 18px 15px;
     font-family: "Roboto", sans-serif;
     box-shadow: none;
     outline: none;
     width: calc(100% - 40px);
     color: #3D3B42;
     transition: all 0.2s linear;
     position: relative;
     top: 1px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-search-input:not(focus)::-webkit-input-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-search-input:not(focus)::-moz-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-search-input:not(focus):-moz-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu form .tt-search-input:not(focus):-ms-input-placeholder {
     color: #3D3B42;
     font-weight: 700;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close {
     display: inline-flex;
     color: #3D3B42;
     font-size: 20px;
     line-height: 1;
     font-family: "club";
     padding: 5px 15px 5px 5px;
     background: none;
     border: none;
     cursor: pointer;
     outline: none;
     position: relative;
     top: 0px;
     right: 0px;
     transition: color 0.2s linear;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close:before {
     font-family: "club";
     content: "\e90f";
     font-size: 20px;
     line-height: 1;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-close:hover {
     color: #FF1A43;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search {
     color: #3D3B42;
     font-size: 20px;
     line-height: 1;
     font-family: "club";
     position: absolute;
     cursor: pointer;
     top: 50%;
     margin-top: -11px;
     right: 50px;
     z-index: 3;
     display: inline-block;
     background: none;
     border: none;
     transition: color 0.2s linear;
   }
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search:hover {
     color: #FF1A43;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .tt-btn-search:before {
     content: "\e918";
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-dropdown-menu .search-results {
     position: absolute;
     top: 36px;
     left: 0;
     padding: 11px 11px 19px;
   }
 }
 #tt-header .tt-obj.tt-obj-search .search-results {
   position: relative;
   padding: 0;
   width: 100%;
   display: none;
   margin-top: 27px;
   background: #ffffff;
 }
 #tt-header .tt-obj.tt-obj-search .search-results ul {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: flex-start;
   list-style: none;
   padding: 0;
   overflow: hidden;
   margin-right: -10px;
   margin-left: -10px;
 }
 #tt-header .tt-obj.tt-obj-search .search-results ul > li {
   display: block;
   width: 16.666%;
   margin: 0;
   padding-left: 10px;
   padding-right: 10px;
   overflow: hidden;
 }
 #tt-header .tt-obj.tt-obj-search .search-results ul > li a {
   display: block;
 }
 #tt-header .tt-obj.tt-obj-search .search-results ul > li a .thumbnail img {
   width: 100%;
   height: auto;
 }
 @media (min-width: 791px) {
   #tt-header .tt-obj.tt-obj-search .search-results ul > li {
     width: 16.666%;
   }
 }
 @media (max-width: 790px) {
   #tt-header .tt-obj.tt-obj-search .search-results ul > li {
     width: 33.333%;
   }
 }
 @media (max-width: 575px) and (min-width: 381px) {
   #tt-header .tt-obj.tt-obj-search .search-results ul > li {
     width: 25%;
   }
   #tt-header .tt-obj.tt-obj-search .search-results ul > li:nth-child(n+5) {
     display: none;
   }
 }
 @media (max-width: 380px) {
   #tt-header .tt-obj.tt-obj-search .search-results ul > li {
     width: 33.333%;
   }
   #tt-header .tt-obj.tt-obj-search .search-results ul > li:nth-child(n+4) {
     display: none;
   }
 }
 #tt-header .tt-obj.tt-obj-search .tt-view-all {
   background: none;
   border: none;
   padding: 0;
   margin-top: 15px;
   font-size: 18px;
   font-weight: 700;
   color: #3D3B42;
   font-family: "Roboto", sans-serif;
   text-decoration: none;
   cursor: pointer;
   transition: color 0.2s linear;
   display: inline-block;
 }
 #tt-header .tt-obj.tt-obj-search .tt-view-all:before {
   content: "";
   display: inline-block;
   border: 5px solid transparent;
   border-left: 9px solid #ff1a43;
   margin-right: 3px;
   position: relative;
   top: -1px;
 }
 #tt-header .tt-obj.tt-obj-search .tt-view-all:hover {
   text-decoration: none;
   color: #FF1A43;
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search .tt-view-all {
     font-size: 16px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-view-all:before {
     border-width: 4px;
     border-left-width: 6px;
     top: -1px;
   }
 }
 @media (max-width: 790px) {
   #tt-header .tt-obj.tt-obj-search .tt-view-all {
     font-size: 15px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-view-all:before {
     top: -2px;
   }
 }
 @media (min-width: 1025px) {
   #tt-header .tt-obj.tt-obj-search.active .tt-dropdown-menu {
     visibility: visible;
     -webkit-transform: translate(0, 0);
     transform: translate(0, 0);
     -webkit-transform: translate3d(0, 0, 0);
     transform: translate3d(0, 0, 0);
   }
   #tt-header .tt-obj.tt-obj-search.active .tt-dropdown-menu > .container {
     display: block;
   }
 }
 @media (max-width: 1024px) {
   #tt-header .tt-obj.tt-obj-search.active .tt-dropdown-menu {
     z-index: 6;
     opacity: 1;
   }
 }
 #tt-header .tt-obj.tt-obj-cart .tt-obj__btn {
   font-size: 21px;
 }
 #tt-header .tt-obj.tt-obj-languages {
   padding: 0px 23px;
 }
 #tt-header .tt-menu-toggle {
   padding: 5px 15px;
   color: #ffffff;
   transition: color 0.2s linear;
   display: none;
   position: relative;
   right: 5px;
   font-size: 16px;
 }
 #tt-header .tt-menu-toggle:hover {
   color: #FF1A43;
 }
 #tt-header .tt-logo {
   position: relative;
   left: 15px;
   display: inline-block;
 }
 @media (max-width: 1770px) {
   #tt-header #tt-nav > ul > li > a {
     padding-left: 13px;
     padding-right: 13px;
   }
   #tt-header .tt-col-obj,
 #tt-header .tt-desctop-parent {
     margin-left: 3%;
   }
   #tt-header .tt-obj .tt-obj__btn {
     padding-left: 17px;
     padding-right: 17px;
   }
   #tt-header .tt-obj.tt-obj-languages {
     padding-left: 17px;
     padding-right: 20px;
   }
 }
 @media (min-width: 1240px) {
   #tt-header .tablet-visible {
     display: none;
   }
 }
 @media (max-width: 1239px) {
   #tt-header {
     height: 85px;
   }
   #tt-header .tt-logo {
     top: 1px;
     left: 13px;
   }
   #tt-header .tt-logo > * {
     width: 200px;
     height: auto;
   }
   #tt-header #tt-nav {
     display: none;
   }
   #tt-header .tt-obj.tt-obj-languages {
     padding-right: 15px;
   }
   #tt-header .tablet-hidden {
     display: none;
   }
   #tt-header .tablet-visible {
     display: block;
   }
   #tt-header .tt-menu-toggle {
     display: block !important;
   }
 }
 @media (max-width: 1024px) {
   #tt-header {
     height: auto;
     padding: 0;
   }
   #tt-header .tt-holder {
     padding-top: 10px;
     padding-bottom: 10px;
     height: 88px;
   }
   #tt-header .tt-logo {
     top: 1px;
     left: 13px;
     display: inline-block;
   }
   #tt-header .tt-logo > * {
     width: 200px;
     height: auto;
   }
   #tt-header .tt-skinSelect-02 .SumoSelect {
     font-size: 13px;
   }
   #tt-header .tt-obj.tt-obj-search .tt-obj__btn {
     font-size: 16px;
   }
   #tt-header .tt-obj.tt-obj-cart .tt-obj__btn {
     font-size: 16px;
   }
   #tt-header .tt-obj.tt-obj-cart .tt-obj__badge {
     right: 3px;
     width: 16px;
     height: 16px;
     top: 3px;
   }
   #tt-header .tt-obj.tt-obj-languages {
     padding-right: 30px;
   }
   #tt-header .tt-menu-toggle {
     top: -3px;
   }
   #tt-header .nav-btn {
     top: 0px;
   }
   #tt-header .nav-btn li a [class^=icon-] {
     top: 4px;
   }
   #tt-header .tt-obj .tt-obj__btn {
     padding: 10px 14px;
     top: -2px;
     position: relative;
     z-index: 2;
   }
   #tt-header .tt-obj .tt-obj__select {
     top: -2px;
     position: relative;
   }
   #tt-header .tt-obj:not(.active) .tt-obj__btn {
     z-index: 4;
   }
 }
 @media (max-width: 767px) {
   #tt-header {
     top: 35px;
   }
   #tt-header .nav-btn {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     background-color: #2b2a30;
     overflow: hidden;
   }
   #tt-header .nav-btn li {
     width: 33.333%;
     position: relative;
   }
   #tt-header .nav-btn li a {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-content: center;
     align-items: center;
     padding: 5px 10px;
     text-align: center;
     height: 34px;
   }
   #tt-header .nav-btn li a [class^=icon-],
 #tt-header .nav-btn li a [class^=icon-].icon-chat,
 #tt-header .nav-btn li a [class^=icon-].icon-tables {
     top: 0;
   }
   #tt-header .nav-btn li:not(:first-child):before {
     content: "";
     display: inline-block;
     width: 1px;
     height: 100%;
     background: #1c1b20;
     position: absolute;
     top: 0;
     left: 0;
   }
   #tt-header .tt-obj.tt-obj-languages {
     padding-right: 20px;
   }
   #tt-header .tt-obj .tt-obj__btn {
     padding: 10px 10px;
   }
   #tt-header .tt-logo {
     top: 3px;
     left: 8px;
   }
   #tt-header .tt-logo > * {
     width: 200px;
   }
   #tt-header .tt-obj .tt-obj__select,
 #tt-header .tt-obj .tt-obj__btn {
     top: 2px;
   }
   #tt-header .tt-menu-toggle {
     top: 0;
   }
 }
 
 /* @media (min-width: 1240px) {
   .load-mainslider #tt-header:not(.stuck) {
     background-color: transparent;
   }
 } */
 
 /*
     Desktop Nav
 */
 #tt-nav {
   position: relative;
 }
 #tt-nav > ul {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: center;
 }
 #tt-nav > ul > li {
   display: inline-block;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
 }
 #tt-nav > ul > li > a {
   display: inline-block;
   font-size: 14px;
   line-height: 21px;
   color: #ffffff;
   padding: 8px 22px 6px;
   text-decoration: none;
 }
 #tt-nav > ul > li > a span {
   display: inline-block;
 }
 #tt-nav > ul > li ul {
   position: absolute;
   z-index: 5;
   top: 100%;
   left: 0;
   width: 185px;
   margin-top: 4px;
   padding-top: 14px;
   padding-bottom: 15px;
   background: #fff;
   transition: all 0.3s;
   opacity: 0;
   visibility: hidden;
   -webkit-transform: translateY(20px);
   transform: translateY(20px);
   transition-property: all;
   transition-duration: 0.2s;
   transition-delay: 0s;
 }
 #tt-nav > ul > li ul:before {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 3px;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
 }
 #tt-nav > ul > li ul li {
   margin: 0;
   padding: 0;
 }
 #tt-nav > ul > li ul li a {
   font-size: 14px;
   line-height: 25px;
   display: block;
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
   padding: 0px 23px;
   transition: color 0.2s;
 }
 #tt-nav > ul > li ul li a:hover {
   color: #FF1A43;
 }
 #tt-nav > ul > li:hover ul {
   opacity: 1;
   visibility: visible;
   -webkit-transform: translateY(0);
   transform: translateY(0);
   transition-delay: 0.15s;
 }
 
 #tt-nav__line {
   display: block;
   position: absolute;
   height: 4px;
   width: 50px;
   top: 29px;
   left: 22px;
   width: 41px;
   background: linear-gradient(270deg, #FF1A43 -0.23%, #7B16D9 100%);
   border-radius: 10px;
   opacity: 1;
   transition: width 0.3s linear, left 0.3s linear;
 }
 @media (min-width: 1534px) {
   #tt-nav__line {
     margin-top: 9px;
   }
 }
 @media (max-width: 1533px) {
   #tt-nav__line {
     margin-top: 4px;
   }
 }
 
 /*
     Logo
 */
 #tt-header .logo__img {
   fill: rgb(255, 26, 17);
   -webkit-animation-name: glow-blue;
   -webkit-animation-duration: 1s;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-timing-function: ease-in-out;
   -webkit-animation-direction: alternate;
   animation-name: glow-blue;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-timing-function: ease-in-out;
   animation-direction: alternate;
   -moz-animation: none;
 }
 
 @-webkit-keyframes glow-blue {
   0% {
     fill: rgb(255, 26, 17);
   }
   100% {
     fill: rgb(206, 24, 123);
   }
 }
 @keyframes glow-blue {
   0% {
     fill: rgb(255, 26, 17);
   }
   100% {
     fill: rgb(206, 24, 123);
   }
 }
 @-webkit-keyframes flicker {
   from {
     opacity: 1;
   }
   4% {
     opacity: 0.9;
   }
   6% {
     opacity: 0.85;
   }
   8% {
     opacity: 0.95;
   }
   10% {
     opacity: 0.9;
   }
   11% {
     opacity: 0.922;
   }
   12% {
     opacity: 0.9;
   }
   14% {
     opacity: 0.95;
   }
   16% {
     opacity: 0.98;
   }
   17% {
     opacity: 0.9;
   }
   19% {
     opacity: 0.93;
   }
   20% {
     opacity: 0.99;
   }
   24% {
     opacity: 1;
   }
   26% {
     opacity: 0.94;
   }
   28% {
     opacity: 0.98;
   }
   37% {
     opacity: 0.93;
   }
   38% {
     opacity: 0.5;
   }
   39% {
     opacity: 0.96;
   }
   42% {
     opacity: 1;
   }
   44% {
     opacity: 0.97;
   }
   46% {
     opacity: 0.94;
   }
   56% {
     opacity: 0.9;
   }
   58% {
     opacity: 0.9;
   }
   60% {
     opacity: 0.99;
   }
   68% {
     opacity: 1;
   }
   70% {
     opacity: 0.9;
   }
   72% {
     opacity: 0.95;
   }
   93% {
     opacity: 0.93;
   }
   95% {
     opacity: 0.95;
   }
   97% {
     opacity: 0.93;
   }
   to {
     opacity: 1;
   }
 }
 @keyframes flicker {
   from {
     opacity: 1;
   }
   4% {
     opacity: 0.9;
   }
   6% {
     opacity: 0.85;
   }
   8% {
     opacity: 0.95;
   }
   10% {
     opacity: 0.9;
   }
   11% {
     opacity: 0.922;
   }
   12% {
     opacity: 0.9;
   }
   14% {
     opacity: 0.95;
   }
   16% {
     opacity: 0.98;
   }
   17% {
     opacity: 0.9;
   }
   19% {
     opacity: 0.93;
   }
   20% {
     opacity: 0.99;
   }
   24% {
     opacity: 1;
   }
   26% {
     opacity: 0.94;
   }
   28% {
     opacity: 0.98;
   }
   37% {
     opacity: 0.93;
   }
   38% {
     opacity: 0.5;
   }
   39% {
     opacity: 0.96;
   }
   42% {
     opacity: 1;
   }
   44% {
     opacity: 0.97;
   }
   46% {
     opacity: 0.94;
   }
   56% {
     opacity: 0.9;
   }
   58% {
     opacity: 0.9;
   }
   60% {
     opacity: 0.99;
   }
   68% {
     opacity: 1;
   }
   70% {
     opacity: 0.9;
   }
   72% {
     opacity: 0.95;
   }
   93% {
     opacity: 0.93;
   }
   95% {
     opacity: 0.95;
   }
   97% {
     opacity: 0.93;
   }
   to {
     opacity: 1;
   }
 }
 #tt-header .logo__text {
   -webkit-animation: flicker 6s infinite step-end;
   animation: flicker 6s infinite step-end;
 }
 
 @keyframes flicker {
   0% {
     opacity: 1;
   }
   3% {
     opacity: 0.4;
   }
   6% {
     opacity: 1;
   }
   7% {
     opacity: 0.4;
   }
   8% {
     opacity: 1;
   }
   9% {
     opacity: 0.4;
   }
   10% {
     opacity: 1;
   }
   89% {
     opacity: 1;
   }
   90% {
     opacity: 0.4;
   }
   100% {
     opacity: 0.4;
   }
 }
 /*
     Mobile menu
 */
 .panel-menu,
 .mmpanels,
 .mmpanels > .mmpanel {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: inherit;
 }
 
 .panel-menu {
   width: 320px;
   background: #3D3B42;
   position: fixed;
   z-index: 50;
 }
 .panel-menu ul {
   list-style-type: none;
   margin: 0;
   padding: 0;
 }
 .panel-menu ul li {
   margin: 0;
   width: 100%;
 }
 .panel-menu ul li a {
   display: flex;
   padding: 9px 27px 9px 30px;
   font-size: 15px;
   line-height: 22px;
   text-decoration: none;
   color: #ffffff;
   background: transparent;
   position: relative;
   transition: all 0.2s linear;
 }
 .panel-menu ul li a:hover {
   color: #FF1A43;
   background: transparent;
 }
 .panel-menu #mm0.mmpanel a:not(.mm-close) {
   font-family: "Poppins", sans-serif;
   font-weight: 700;
   color: #ffffff;
   background-color: transparent;
 }
 .panel-menu #mm0.mmpanel a:not(.mm-close):after {
   color: #ffffff;
 }
 .panel-menu #mm0.mmpanel a:not(.mm-close):hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu #mm0.mmpanel a:not(.mm-close):hover:after {
   color: #FF1A43;
 }
 .panel-menu #mm0.mmpanel .mm-next-level:after {
   top: 13px;
 }
 .panel-menu .mmpanel:not(#mm0) a:not(.mm-original-link) {
   font-family: "Poppins", sans-serif;
   font-weight: 400;
 }
 .panel-menu .mmpanel .mm-original-link .tt-badge {
   top: -1px;
 }
 .panel-menu .mm-close,
 .panel-menu .mm-prev-level,
 .panel-menu .mm-original-link {
   display: block;
   color: #ffffff;
   background-color: transparent;
   text-transform: uppercase;
 }
 .panel-menu .mm-close:before,
 .panel-menu .mm-prev-level:before,
 .panel-menu .mm-next-level:after {
   font-family: "club";
   font-size: 14px;
   line-height: 14px;
   font-weight: bold;
   transition: all 0.2s linear;
   color: #ffffff;
 }
 .panel-menu .mm-close:hover,
 .panel-menu .mm-prev-level:hover,
 .panel-menu .mm-next-level:hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu .mm-close:hover:before .mm-prev-level:hover:before,
 .panel-menu .mm-next-level:hover:after {
   color: #FF1A43;
 }
 .panel-menu li.mm-close-parent {
   margin-bottom: 32px;
 }
 .panel-menu li.mm-close-parent .mm-close {
   padding: 14px 16px 15px 29px;
   color: #ffffff;
   border-bottom: 1px solid #45444a;
   background-color: transparent;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
 }
 .panel-menu li.mm-close-parent .mm-close:before {
   content: "\e90f";
   padding-right: 10px;
   position: relative;
   top: 1px;
   color: #ffffff;
 }
 .panel-menu li.mm-close-parent .mm-close:hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu li.mm-close-parent .mm-close:hover:before {
   color: #FF1A43;
 }
 .panel-menu .mm-prev-level {
   padding: 14px 16px 15px 29px;
   color: #ffffff;
   background-color: transparent;
   border-bottom: 1px solid #45444a;
   margin-bottom: 15px;
 }
 .panel-menu .mm-prev-level:before {
   content: "\e908";
   padding-right: 10px;
   position: relative;
   top: 1px;
   color: #ffffff;
   display: inline-block;
 }
 .panel-menu .mm-prev-level:hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu .mm-prev-level:hover:before {
   color: #FF1A43;
 }
 .panel-menu .mm-next-level {
   color: #3D3B42;
   background-color: transparent;
 }
 .panel-menu .mm-next-level:after {
   content: "\e909";
   padding-left: 10px;
   top: 12px;
   right: 30px;
   position: absolute;
   color: #ffffff;
 }
 .panel-menu .mm-next-level:hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu .mm-next-level:hover:after {
   color: #FF1A43;
 }
 .panel-menu .mm-original-link {
   font-family: "Poppins", sans-serif;
   font-weight: 700;
   color: #ffffff;
   background-color: transparent;
 }
 .panel-menu .mm-original-link:before {
   padding-right: 10px;
   color: #ffffff;
 }
 .panel-menu .mm-original-link:hover {
   color: #FF1A43;
   background-color: transparent;
 }
 .panel-menu .mm-original-link:hover:before {
   color: #FF1A43;
 }
 
 .mm-open {
   overflow: hidden;
 }
 .mm-open .mm-fullscreen-bg {
   cursor: pointer;
   background-color: rgba(0, 0, 0, 0.55);
   position: fixed;
   top: 0;
   left: 0;
   overflow: hidden;
   width: 100%;
   height: 100%;
   z-index: 21;
   display: none;
 }
 
 .mmitemopen.panel-menu,
 .mmitemopen.panel-menu.mm-right {
   transition: -webkit-transform 0.3s ease;
   transition: transform 0.3s ease;
   transition: transform 0.3s ease, -webkit-transform 0.3s ease;
   -webkit-transform: translate(0, 0);
   transform: translate(0, 0);
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
 }
 
 .panel-menu {
   -webkit-transform: translate(-100%, 0);
   transform: translate(-100%, 0);
   -webkit-transform: translate3d(-100%, 0, 0);
   transform: translate3d(-100%, 0, 0);
 }
 
 .panel-menu.mm-right,
 .mmitemopen.panel-menu.mm-right.mmhide {
   left: auto;
   right: 0;
   -webkit-transform: translate(100%, 0);
   transform: translate(100%, 0);
   -webkit-transform: translate3d(100%, 0, 0);
   transform: translate3d(100%, 0, 0);
 }
 
 .mmitemopen.panel-menu.mmhide {
   -webkit-transform: translate(-100%, 0);
   transform: translate(-100%, 0);
   -webkit-transform: translate3d(-100%, 0, 0);
   transform: translate3d(-100%, 0, 0);
 }
 
 .mmpanel {
   transition: -webkit-transform 0.3s ease;
   transition: transform 0.3s ease;
   transition: transform 0.3s ease, -webkit-transform 0.3s ease;
   -webkit-transform: translate(100%, 0);
   transform: translate(100%, 0);
   -webkit-transform: translate3d(100%, 0, 0);
   transform: translate3d(100%, 0, 0);
 }
 
 .mmpanels {
   overflow: hidden;
 }
 
 .mmpanel.mmopened {
   -webkit-transform: translate(0, 0);
   transform: translate(0, 0);
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
 }
 
 .mmpanel.mmsubopened {
   -webkit-transform: translate(-80%, 0);
   transform: translate(-80%, 0);
   -webkit-transform: translate3d(-80%, 0, 0);
   transform: translate3d(-80%, 0, 0);
 }
 
 .mmpanels > .mmpanel {
   overflow: scroll;
   overflow-x: hidden;
   overflow-y: auto;
 }
 
 .mmpanels,
 .mmpanels > .mmpanel {
   background: inherit;
   border-color: inherit;
 }
 
 .mmpanels > .mmpanel:not(.mmhidden) {
   display: block;
 }
 
 .mmpanels > .mmpanel:after {
   content: "";
   display: block;
   height: 20px;
 }
 
 .mmhidden,
 .mm-nav-btn {
   display: none;
 }
 
 .mm-fullscreen-bg,
 .mm-fullscreen-bg:focus,
 .mm-fullscreen-bg:active,
 .mm-fullscreen-bg:hover {
   outline: none;
 }
 
 .tt-menu-slider {
   max-height: 389px;
 }
 
 #subtitle-wrapper {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   width: 100%;
   height: 225px;
   position: fixed;
   top: 98px;
   z-index: 1;
 }
 #subtitle-wrapper + #tt-pageContent {
   background-color: #ffffff;
   padding-top: 0px;
   margin-top: 292px;
   position: relative;
   z-index: 2;
 }
 #subtitle-wrapper .subtitle__img {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url("../images/subtitle-wrapper01.jpg");
   background-repeat: no-repeat;
   background-position: center bottom;
   background-color: #5f5e63;
   -webkit-transform: scale(1);
   transform: scale(1);
   transition: -webkit-transform 15s ease;
   transition: transform 15s ease;
   transition: transform 15s ease, -webkit-transform 15s ease;
 }
 #subtitle-wrapper .subtitle__title {
   font-size: 54px;
   line-height: 57px;
   color: #ffffff;
   font-family: "Poppins", sans-serif;
   margin: 0;
   padding: 0;
   letter-spacing: -0.03em;
   position: relative;
 }
 #subtitle-wrapper .subtitle__label {
   font-size: 17px;
   line-height: 27px;
   color: #D3D3D5;
   margin-top: 11px;
   position: relative;
 }
 @media (max-width: 1239px) {
   #subtitle-wrapper {
     top: 85px;
   }
   #subtitle-wrapper + #tt-pageContent {
     margin-top: 279px;
   }
 }
 @media (max-width: 1024px) {
   #subtitle-wrapper {
     top: 64px;
   }
   #subtitle-wrapper .subtitle__title {
     font-size: 44px;
     line-height: 47px;
   }
   #subtitle-wrapper .subtitle__label {
     font-size: 15px;
     line-height: 25px;
     margin-top: 7px;
   }
   #subtitle-wrapper + #tt-pageContent {
     margin-top: 258px;
   }
 }
 @media (max-width: 767px) {
   #subtitle-wrapper {
     top: 97px;
     height: 200px;
   }
   #subtitle-wrapper .subtitle__title {
     font-size: 34px;
     line-height: 37px;
   }
   #subtitle-wrapper .subtitle__label {
     font-size: 15px;
     line-height: 25px;
     margin-top: 2px;
   }
   #subtitle-wrapper + #tt-pageContent {
     margin-top: 250px;
   }
 }
 
 .loaded #subtitle-wrapper .subtitle__img {
   -webkit-transform: scale(1.2);
   transform: scale(1.2);
 }
 .section-indent01{
   background: #252427;
   color: #ffffff;
 }
 
 /*---------------------------------------*/
 /*--------    6. Content layout  ------*/
 /*--------------------------------------*/
 @media (min-width: 1025px) {
   .section-indent01:not(.no-margin) {
     /* margin-top: 36px; */
   }
 }
 @media (max-width: 1024px) {
   .section-indent01:not(.no-margin) {
     margin-top: -5px;
   }
 }
 @media (max-width: 790px) {
   .section-indent01:not(.no-margin) {
     margin-top: -43px;
   }
 }
 
 .section-indent02:not(.no-margin) {
   margin-top: 131px;
 }
 @media (max-width: 1024px) {
   .section-indent02:not(.no-margin) {
     margin-top: 40px;
   }
   .section-indent02:not(.no-margin) .section-marker:before {
     margin-top: -4px;
   }
 }
 @media (max-width: 937px) {
   .section-indent02:not(.no-margin) .section-marker:before {
     margin-top: -14px;
   }
 }
 @media (max-width: 887px) {
   .section-indent02:not(.no-margin) .section-marker:before {
     margin-top: -3px;
   }
 }
 @media (max-width: 826px) {
   .section-indent02:not(.no-margin) {
     margin-top: 58px;
   }
   .section-indent02:not(.no-margin) .section-marker:before {
     margin-top: -23px;
   }
 }
 @media (max-width: 790px) {
   .section-indent02:not(.no-margin) {
     margin-top: 60px;
   }
 }
 @media (max-width: 767px) {
   .section-indent02:not(.no-margin) {
     margin-top: 0px;
   }
   .section-indent02:not(.no-margin) .section-marker:before {
     margin-top: 27px;
   }
 }
 .section-indent03{
   background: #252427;
   color: #ffffff;
 }
 .section-indent05{
   background: #252427;
   color: #ffffff;
 }
 .section-indent06{
   background: #252427;
   color: #ffffff;
 }
 
 .section-indent03:not(.no-margin) {
   margin-top: 17px;
 }
 @media (max-width: 790px) {
   .section-indent03:not(.no-margin) {
     margin-top: 49px;
   }
 }
 @media (max-width: 1024px) {
   .section-indent03 .section-title .section-title__text {
     padding-top: 38px;
   }
   .section-indent03 .section-title .section-title__text-under {
     top: -10px;
   }
 }
 
 .section-indent04:not(.no-margin) {
   margin-top: 98px;
 }
 @media (max-width: 1024px) {
   .section-indent04:not(.no-margin) {
     margin-top: 72px;
   }
 }
 @media (max-width: 790px) {
   .section-indent04:not(.no-margin) {
     margin-top: 49px;
   }
 }
 
 .section-indent05:not(.no-margin) {
   padding-top: 98px;
   padding-bottom: 98px;
 }
 @media (max-width: 1024px) {
   .section-indent05:not(.no-margin) {
     padding-top: 70px;
     padding-bottom: 70px;
   }
 }
 @media (max-width: 790px) {
   .section-indent05:not(.no-margin) {
     padding-top: 29px;
     padding-bottom: 49px;
   }
 }
 
 .section-indent06:not(.no-margin) {
   margin-top: 136px;
 }
 @media (max-width: 1024px) {
   .section-indent06:not(.no-margin) {
     margin-top: 40px;
   }
   .section-indent06:not(.no-margin) .section-marker:before {
     margin-top: -4px;
   }
 }
 @media (max-width: 937px) {
   .section-indent06:not(.no-margin) .section-marker:before {
     margin-top: -14px;
   }
 }
 @media (max-width: 887px) {
   .section-indent06:not(.no-margin) .section-marker:before {
     margin-top: -3px;
   }
 }
 @media (max-width: 826px) {
   .section-indent06:not(.no-margin) .section-marker:before {
     margin-top: -23px;
   }
 }
 @media (max-width: 790px) {
   .section-indent06:not(.no-margin) {
     margin-top: 40px;
   }
 }
 @media (max-width: 767px) {
   .section-indent06:not(.no-margin) .section-marker:before {
     margin-top: 40px;
   }
 }
 
 .section-inner {
   padding-top: 36px;
   padding-bottom: 122px;
   position: relative;
   z-index: 2;
 }
 @media (max-width: 1024px) {
   .section-inner {
     padding-top: 0px;
     padding-bottom: 60px;
   }
 }
 @media (max-width: 790px) {
   .section-inner {
     padding-bottom: 49px;
   }
 }
 
 .section-wrapper02 {
   background: #252427;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
 }
 @media (min-width: 1700px) {
   .section-wrapper02 {
     width: calc(100% - 163px);
   }
 }
 @media (max-width: 1699px) {
   .section-wrapper02 {
     width: calc(100% - 100px);
   }
 }
 @media (max-width: 1600px) {
   .section-wrapper02 {
     width: calc(100% - 70px);
   }
 }
 @media (max-width: 1500px) {
   .section-wrapper02 {
     width: calc(100% - 50px);
   }
 }
 @media (max-width: 1450px) {
   .section-wrapper02 {
     width: 100%;
   }
 }
 
 .section-indent-minus01 {
   margin-top: -85px;
   position: relative;
   z-index: 1;
 }
 
 @media (max-width: 1024px) {
   .container-fluid-tablet {
     max-width: inherit;
     margin-left: 0;
     margin-right: 0;
     padding-left: 30px;
     padding-right: 30px;
   }
 }
 @media (max-width: 575px) {
   .container-fluid-tablet {
     padding-left: 15px;
     padding-right: 15px;
   }
 }
 
 @media (max-width: 1239px) {
   .container-fluid-lg {
     max-width: inherit;
     margin-left: 0;
     margin-right: 0;
     padding-left: 30px;
     padding-right: 30px;
   }
 }
 @media (max-width: 575px) {
   .container-fluid-lg {
     padding-left: 15px;
     padding-right: 15px;
   }
 }
 
 @media (min-width: 1240px) {
   .container-fluid-subpages {
     padding-left: 70px;
     padding-right: 70px;
   }
 }
 @media (max-width: 1239px) {
   .container-fluid-subpages {
     padding-left: 50px;
     padding-right: 50px;
   }
 }
 @media (max-width: 1024px) {
   .container-fluid-subpages {
     padding-left: 20px;
     padding-right: 20px;
   }
 }
 
 /* news item */
 .subpages-indent {
   padding-top: 120px;
 }
 
 .subpages-inner {
   padding-top: 120px;
   padding-bottom: 120px;
   background: #252427;
 }
 @media (max-width: 1239px) {
   .subpages-inner {
     padding-top: 100px;
     padding-bottom: 100px;
   }
 }
 @media (max-width: 1024px) {
   .subpages-inner {
     padding-top: 80px;
     padding-bottom: 80px;
   }
 }
 @media (max-width: 575px) {
   .subpages-inner {
     padding-top: 50px;
     padding-bottom: 50px;
   }
 }
 
 .section-wrapper01 {
   background: #3c3b41 url("../images/promo02.jpg") 0 0 no-repeat;
   overflow: hidden;
 }
 @media (min-width: 1025px) {
   .section-wrapper01 {
     margin-top: -50px;
   }
 }
 @media (max-width: 1024px) {
   .section-wrapper01 {
     margin-top: -30px;
   }
 }
 
 .section-wrapper {
   background-color: #3D3B42;
 }
 
 .section-marker {
   position: relative;
 }
 .section-marker:before {
   content: "";
   display: block;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   position: absolute;
   top: 0;
   left: 50%;
   margin-top: -30px;
   border-radius: 3px;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   z-index: 2;
 }
 .section-marker.section-marker__indent01:before {
   margin-top: -49px;
 }
 .section-marker.section-marker__indent02:before {
   margin-top: 54px;
 }
 .section-marker.section-marker__indent03:before {
   margin-top: -162px;
 }
 .section-marker.section-marker__indent04:before {
   margin-top: -168px;
 }
 @media (min-width: 1025px) {
   .section-marker:before {
     width: 4px;
     height: 63px;
     margin-left: -2px;
   }
 }
 @media (max-width: 1024px) {
   .section-marker:before {
     width: 3px;
     height: 46px;
     margin-top: -23px;
   }
   .section-marker.section-marker__indent01:before {
     margin-top: -40px;
   }
   .section-marker.section-marker__indent02:before {
     margin-top: 62px;
   }
   .section-marker.section-marker__indent03:before {
     margin-top: -63px;
   }
   .section-marker.section-marker__indent04:before {
     margin-top: -63px;
   }
 }
 @media (max-width: 790px) {
   .section-marker.section-marker__indent01:before {
     margin-top: -72px;
   }
   .section-marker.section-marker__indent03:before {
     margin-top: -72px;
   }
   .section-marker.section-marker__indent04:before {
     margin-top: -61px;
   }
 }
 
 /*--------
     Main Slider
 --------*/
 .mainSlider-layout {
   position: relative;
   background: #252427;
 }
 
 .mainSlider-wrapper {
   position: relative;
 }
 
 .mainSlider {
   height: 900px;
 }
 @media (max-width: 1239px) {
   .mainSlider {
     height: 700px;
   }
 }
 @media (max-width: 1024px) and (min-width: 551px) {
   .mainSlider {
     height: 354px;
   }
 }
 @media (max-width: 790px) {
   .mainSlider {
     height: 250px;
   }
 }
 .mainSlider .container {
   position: relative;
 }
 .mainSlider .slide .img--holder {
   background-size: cover;
   background-position: center top;
   background-repeat: no-repeat;
   height: 900px;
   transition: -webkit-transform 3s ease, -webkit-filter 3s ease;
   transition: transform 3s ease, filter 3s ease;
   transition: transform 3s ease, filter 3s ease, -webkit-transform 3s ease, -webkit-filter 3s ease;
 }
 @media (max-width: 1239px) {
   .mainSlider .slide .img--holder {
     height: 700px;
   }
 }
 @media (max-width: 1024px) and (min-width: 551px) {
   .mainSlider .slide .img--holder {
     height: 354px;
   }
 }
 @media (max-width: 790px) {
   .mainSlider .slide .img--holder {
     height: 250px;
   }
 }
 .mainSlider .slide .img--holder:before {
   content: "";
   background-color: #38226f;
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.8;
   z-index: 1;
 }
 .mainSlider .slide .mainSlider-textmask {
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
   background-clip: text;
   position: absolute;
   text-align: center;
   white-space: nowrap;
   background-position: 50% 47%;
   background-repeat: no-repeat;
   text-align: center;
   font-size: 905px;
   line-height: 0.86;
   font-weight: bold;
   padding-top: 3.3%;
   font-family: "Poppins", sans-serif;
 }
 .mainSlider .slide .container {
   position: relative;
   z-index: 2;
 }
 .mainSlider .slide .slide-content {
   height: 100%;
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   align-content: stretch;
   align-items: flex-start;
   padding-bottom: 9px;
 }
 .mainSlider .slide .slide-content .container:first-child {
   margin-top: -5px;
 }
 .mainSlider .slide .slide-content .container .tt-title-01 {
   color: #ffffff;
   font-weight: 700;
   font-size: 30px;
 }
 .mainSlider .slide .slide-content .container .tt-title-02 {
   color: #ffffff;
   letter-spacing: -0.03em;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
 }
 .mainSlider .slide .slide-content .container .tt-row-btn {
   margin-top: 40px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
 }
 .mainSlider .slide .slide-content .container .tt-row-btn .tt-btn {
   margin-left: 15px;
   margin-right: 15px;
   min-width: 177px;
 }
 .mainSlider .slide .slide-content .container:not(.text-center):not(.text-right) .tt-row-btn {
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .mainSlider .slide .slide-content .container.text-center .tt-row-btn {
   justify-content: center;
 }
 .mainSlider .slide .slide-content .container.text-right .tt-row-btn {
   justify-content: flex-end;
 }
 @media (max-width: 1600px) {
   .mainSlider .slide .mainSlider-textmask {
     font-size: 805px;
     padding-top: 8%;
   }
 }
 @media (max-width: 1350px) {
   .mainSlider .slide .mainSlider-textmask {
     font-size: 605px;
     padding-top: 14%;
   }
 }
 @media (max-width: 1239px) {
   .mainSlider .slide .mainSlider-textmask {
     padding-top: 7.5%;
   }
 }
 @media (min-width: 1025px) {
   .mainSlider .slide .slide-content .tt-title-01 {
     font-size: 20px;
     line-height: 16px;
   }
   .mainSlider .slide .slide-content .tt-title-02 {
     font-size: 64px;
     line-height: 67px;
     margin-top: 38px;
   }
 }
 @media (max-width: 1024px) {
   .mainSlider .slide .mainSlider-textmask {
     font-size: 380px;
     top: 0;
     padding-top: 1.6%;
   }
   .mainSlider .slide .slide-content .tt-title-01 {
     font-size: 16px;
     line-height: 16px;
   }
   .mainSlider .slide .slide-content .tt-title-02 {
     font-size: 51px;
     line-height: 54px;
     margin-top: 28px;
   }
   .mainSlider .slide .slide-content .container .tt-row-btn {
     margin-top: 30px;
   }
   .mainSlider .slide .slide-content .container .tt-row-btn .tt-btn {
     min-width: 155px;
   }
 }
 @media (max-width: 790px) {
   .mainSlider .slide .mainSlider-textmask {
     font-size: 260px;
     padding-top: 1.6%;
   }
   .mainSlider .slide .slide-content .tt-title-01 {
     font-size: 12px;
     line-height: 40px;
   }
   .mainSlider .slide .slide-content .tt-title-02 {
     font-size: 25px;
     line-height: 37px;
     margin-top: 13px;
   }
   .mainSlider .slide .slide-content .container .tt-row-btn {
     margin-top: 25px;
   }
   .mainSlider .slide .slide-content .container .tt-row-btn .tt-btn {
     min-width: 124px;
     margin-left: 10px;
     margin-right: 10px;
   }
 }
 @media (max-width: 450px) {
   .mainSlider .slide .mainSlider-textmask {
     font-size: 180px;
     padding-top: 11%;
   }
   .mainSlider .slide .slide-content .tt-title-02 {
     font-size: 20px;
     line-height: 30px;
     margin-top: 13px;
   }
   .mainSlider .slide .slide-content .container .tt-row-btn {
     margin-top: 15px;
   }
 }
 .mainSlider .slick-arrow {
   position: absolute;
   top: 50%;
   margin-top: -18px;
   z-index: 7;
   font-size: 0;
   line-height: 0;
   background: none;
   border: none;
   color: #fff;
 }
 .mainSlider .slick-arrow:hover {
   color: #FF1A43;
 }
 .mainSlider .slick-arrow:before {
   font-family: "club";
   display: block;
   font-size: 40px;
   line-height: 1em;
 }
 .mainSlider .slick-prev {
   left: 20px;
 }
 .mainSlider .slick-prev:before {
   content: "\e916";
 }
 .mainSlider .slick-next {
   right: 20px;
 }
 .mainSlider .slick-next:before {
   content: "\e917";
 }
 @media (max-width: 1239px) {
   .mainSlider {
     margin-top: 85px;
   }
 }
 @media (max-width: 1239px) {
   .mainSlider {
     margin-top: 64px;
   }
 }
 @media (max-width: 767px) {
   .mainSlider {
     margin-top: 98px;
   }
 }
 
 .fadeInLeftSm {
   -webkit-animation-name: fadeInLeftSm;
   animation-name: fadeInLeftSm;
   -webkit-animation-timing-function: ease-out;
   animation-timing-function: ease-out;
 }
 
 .fadeInRightSm {
   -webkit-animation-name: fadeInRightSm;
   animation-name: fadeInRightSm;
   -webkit-animation-timing-function: ease-out;
   animation-timing-function: ease-out;
 }
 
 .fadeInUpSm {
   -webkit-animation-name: fadeInUpSm;
   animation-name: fadeInUpSm;
   -webkit-animation-timing-function: ease-out;
   animation-timing-function: ease-out;
 }
 
 .animated.fadeInLeftSm,
 .animated.fadeInRightSm,
 .animated.fadeInUpSm {
   -webkit-animation-duration: 0.75s;
   animation-duration: 0.75s;
 }
 
 @-webkit-keyframes fadeInUpSm {
   0% {
     opacity: 0;
     -webkit-transform: translate3d(0, 50%, 0);
     transform: translate3d(0, 50%, 0);
   }
   100% {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 @keyframes fadeInUpSm {
   0% {
     opacity: 0;
     -webkit-transform: translate3d(0, 50%, 0);
     transform: translate3d(0, 50%, 0);
   }
   100% {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 @-webkit-keyframes fadeInLeftSm {
   0% {
     opacity: 0;
     -webkit-transform: translate3d(-20%, 0, 0);
     transform: translate3d(-20%, 0, 0);
   }
   100% {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 @keyframes fadeInLeftSm {
   0% {
     opacity: 0;
     -webkit-transform: translate3d(-20%, 0, 0);
     transform: translate3d(-20%, 0, 0);
   }
   100% {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 @-webkit-keyframes fadeInRightSm {
   from {
     opacity: 0;
     -webkit-transform: translate3d(20%, 0, 0);
     transform: translate3d(20%, 0, 0);
   }
   to {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 @keyframes fadeInRightSm {
   from {
     opacity: 0;
     -webkit-transform: translate3d(20%, 0, 0);
     transform: translate3d(20%, 0, 0);
   }
   to {
     opacity: 1;
     -webkit-transform: none;
     transform: none;
   }
 }
 body.is-loaded .loading-content {
   opacity: 0;
   visibility: hidden;
 }
 
 .loading-content {
   height: 100%;
   width: 100%;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   margin: auto;
   z-index: 3;
   background-color: #252427;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 1;
   pointer-events: none;
   visibility: visible;
   transition: all 0.5s;
 }
 .loading-content.disable {
   opacity: 0;
 }
 .loading-content svg {
   width: 200px;
   height: auto;
 }
 @media (min-width: 1025px) {
   .loading-content svg {
     width: 200px;
   }
 }
 @media (max-width: 1024px) {
   .loading-content svg {
     width: 145px;
   }
 }
 @media (max-width: 790px) {
   .loading-content svg {
     width: 120px;
   }
 }
 
 .mainSlider-textmask {
   opacity: 0;
   transition: opacity 2s linear 1s;
   overflow: hidden;
   width: calc(100% - 4px);
   height: calc(100% - 4px);
 }
 
 .load-mainslider .mainSlider .slide.slick-current .img--holder {
   -webkit-filter: grayscale(100%);
   -moz-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
   -o-filter: grayscale(100%);
   filter: grayscale(100%);
   /* -webkit-filter: url(grayscale.html#greyscale); */
   /* filter: url(grayscale.html#greyscale); */
   filter: gray;
   -webkit-filter: grayscale(1);
   -webkit-transform: scale(1.2);
   transform: scale(1.2);
 }
 .load-mainslider .mainSlider .slide.slick-current .mainSlider-textmask {
   opacity: 1;
 }
 
 @media (min-width: 1025px) {
   .slick-indent {
     padding-left: 30px;
     padding-right: 30px;
   }
 }
 @media (max-width: 1024px) {
   .slick-indent {
     margin-left: -5px;
     margin-right: -5px;
   }
 }
 
 .event-item {
   position: relative;
   display: block;
   margin: 30px 15px;
   transition: box-shadow 0.3s linear;
 }
 .event-item .event-item__label {
   background: url("../images/label-01.png") 0 0 no-repeat;
   display: block;
   width: 54px;
   height: 63px;
   color: #ffffff;
   position: absolute;
   z-index: 3;
   right: 20px;
   top: -6px;
   text-align: center;
   font-size: 26px;
   line-height: 16px;
   font-weight: bold;
   padding: 12px 0 0 0;
   background-size: cover;
 }
 .event-item .event-item__label span {
   display: block;
   text-align: center;
   font-weight: normal;
   font-size: 15px;
   line-height: 16px;
   padding-top: 5px;
 }
 .event-item .event-item__img {
   position: relative;
   z-index: 1;
 }
 .event-item .event-item__img img {
   width: 100%;
   height: auto;
 }
 .event-item .event-item__layout {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: stretch;
   align-items: center;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(61, 59, 66, 0.98);
   z-index: 2;
   opacity: 0;
   transition: opacity 0.25s linear;
 }
 .event-item .event-item__layout .tt-btn {
   margin: 10px 0;
   min-width: 157px;
 }
 .event-item:hover {
   box-shadow: 0px 7px 16px rgba(61, 59, 66, 0.7);
 }
 .event-item:hover .event-item__layout {
   opacity: 1;
 }
 @media (max-width: 1024px) and (min-width: 791px) {
   .event-item {
     margin: 30px 15px 22px;
   }
   .event-item .event-item__layout .tt-btn {
     min-width: 133px;
     margin: 5px 0;
   }
   .event-item .event-item__label {
     width: 40px;
     height: 46px;
     right: 10px;
     top: -3px;
     font-size: 20px;
     line-height: 16px;
     padding: 7px 0 0 0;
   }
   .event-item .event-item__label span {
     font-size: 13px;
     line-height: 16px;
     padding-top: 2px;
   }
 }
 @media (max-width: 790px) {
   .event-item .event-item__layout .tt-btn {
     min-width: 123px;
   }
 }
 @media (max-width: 575px) {
   .event-item {
     max-width: 248px;
     margin-left: auto;
     margin-right: auto;
   }
 }
 
 .events-img-list {
   margin-top: 58px;
 }
 .events-img-list .tt-col {
   padding: 15px;
 }
 @media (max-width: 1024px) {
   .events-img-list {
     margin-top: 42px;
   }
 }
 
 .event-item02 {
   overflow: hidden;
   position: relative;
 }
 .event-item02 .event-item02__img {
   position: relative;
   height: 493px;
   background-size: cover;
   background-position: no-repeat;
   transition: -webkit-transform 0.7s;
   transition: transform 0.7s;
   transition: transform 0.7s, -webkit-transform 0.7s;
 }
 .event-item02 .event-item02__img:before {
   content: "";
   background: #2C2837;
   opacity: 0.7;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 .event-item02 .event-item02__content {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   padding: 54px 21px 82px;
   color: #D3D3D5;
   height: 100%;
   text-align: center;
   z-index: 0;
 }
 .event-item02 .event-item02__content .event-item02__border {
   border: 1px solid #ffffff;
   border-bottom: none;
   height: 100%;
   position: relative;
   padding: 0 20px 15px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   z-index: 0;
 }
 .event-item02 .event-item02__content .event-item02__border:before, .event-item02 .event-item02__content .event-item02__border:after {
   content: "";
   position: absolute;
   bottom: 0;
   width: 43px;
   height: 1px;
   background-color: #ffffff;
 }
 .event-item02 .event-item02__content .event-item02__border:before {
   left: 0;
 }
 .event-item02 .event-item02__content .event-item02__border:after {
   right: 0;
 }
 .event-item02 .event-item02__content .tt-title {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 24px;
   line-height: 32px;
   color: #ffffff;
   padding: 0 0 17px 0;
   margin: 0;
   width: 100%;
 }
 .event-item02 .event-item02__content .tt-title + p {
   margin-top: 0;
 }
 .event-item02 .event-item02__content p {
   padding-left: 10px;
   padding-right: 10px;
   max-width: 270px;
   margin-left: auto;
   margin-right: auto;
 }
 .event-item02 .event-item02__content .tt-btn {
   position: absolute;
   bottom: -27px;
   left: 50%;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   white-space: nowrap;
 }
 @media (max-width: 1250px) and (min-width: 1025px) {
   .event-item02 .event-item02__content .event-item02__border .tt-btn {
     padding-left: 18px;
     padding-right: 18px;
   }
 }
 @media (max-width: 1239px) {
   .event-item02 .event-item02__img {
     height: 420px;
   }
   .event-item02 .event-item02__content {
     padding: 33px 20px 61px;
   }
 }
 @media (max-width: 1024px) {
   .event-item02 .event-item02__img {
     height: 375px;
   }
   .event-item02 .event-item02__content {
     padding: 41px 20px 62px;
   }
   .event-item02 .event-item02__content .tt-title {
     font-size: 18px;
     line-height: 24px;
   }
   .event-item02 .event-item02__content .event-item02__border {
     padding-left: 15px;
     padding-right: 15px;
   }
   .event-item02 .event-item02__content .event-item02__border:before, .event-item02 .event-item02__content .event-item02__border:after {
     width: 33px;
   }
   .event-item02 .event-item02__content p {
     padding-left: 0px;
     padding-right: 0px;
   }
   .event-item02 .event-item02__content .tt-btn {
     bottom: -19px;
   }
 }
 .event-item02:hover .event-item02__img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 
 @media (max-width: 576px) {
   .event-item02-list .event-item02 {
     margin-left: auto;
     margin-right: auto;
     max-width: 286px;
   }
   .event-item02-list .event-item02__img {
     height: 375px;
   }
 }
 
 .events-wide {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .events-wide .events-wide__img {
   flex: 0 0 46%;
   max-width: 46%;
   overflow: hidden;
 }
 .events-wide .events-wide__img a {
   display: block;
   margin-top: 50px;
   height: 531px;
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
 }
 .events-wide .events-wide__img .tt-text {
   font-family: "Poppins", sans-serif;
   letter-spacing: -0.03em;
   font-weight: bold;
   font-size: 96px;
   line-height: 105%;
   color: #ffffff;
   text-transform: uppercase;
   -webkit-writing-mode: vertical-lr;
   -ms-writing-mode: tb-lr;
   writing-mode: vertical-lr;
   display: block;
   position: absolute;
   white-space: nowrap;
 }
 .events-wide .events-wide__img .tt-text.tt-text__right {
   right: -17px;
   top: 0px;
   -webkit-transform: scale(-1, -1);
   transform: scale(-1, -1);
   position: absolute;
 }
 .events-wide .events-wide__img .tt-text.tt-text__left {
   top: -6px;
   left: -17px;
 }
 .events-wide .events-wide__img .tt-icon {
   width: 93px;
   height: 93px;
   background-color: #FF1A43;
   border-radius: 50%;
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
   transition: all 0.3s;
 }
 .events-wide .events-wide__img .tt-icon:before {
   content: "";
   display: inline-block;
   position: absolute;
   border: 11px solid transparent;
   border-left: 16px solid #ffffff;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-5px, -11px);
   transform: translate(-5px, -11px);
 }
 .events-wide .events-wide__img .tt-icon::after {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background: #FF1A43;
   opacity: 0.5;
   -webkit-animation: pulse 1.3s infinite linear;
   animation: pulse 1.3s infinite linear;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   transition: all 0.3s;
   pointer-events: none;
 }
 .events-wide .events-wide__img .tt-icon:hover {
   background: linear-gradient(270deg, #FF1A43 -3.76%, #7B16D9 100%);
 }
 .events-wide .events-wide__content {
   flex: 0 0 54%;
   max-width: 54%;
   min-height: 582px;
   overflow: hidden;
   position: relative;
 }
 .events-wide .events-wide__content.tt-color02 {
   background-color: #252427;
   color: #D3D3D5;
 }
 .events-wide .events-wide__content.tt-color01 {
   background-color: #252427;
   color: #D3D3D5;
 }
 .events-wide .events-wide__content .events-wide_wrapper {
   max-width: 571px;
 }
 .events-wide .events-wide__content [class^=tt-btn] {
   margin-top: 41px;
 }
 .events-wide:not(.tt-item__revers) {
   align-content: stretch;
   align-items: flex-end;
 }
 .events-wide:not(.tt-item__revers) .events-wide__content {
   padding: 111px 70px 114px 100px;
 }
 .events-wide.tt-item__revers .events-wide__content {
   padding: 111px 80px 114px 40px;
   box-shadow: 0px 4px 124px rgba(0, 0, 0, 0.25);
 }
 .events-wide.tt-item__revers .events-wide_wrapper {
   float: right;
 }
 @media (max-width: 1600px) {
   .events-wide .events-wide__img .tt-text {
     font-size: 90px;
   }
 }
 @media (max-width: 1500px) {
   .events-wide:not(.tt-item__revers) .events-wide__content {
     padding: 111px 65px 90px 80px;
   }
   .events-wide.tt-item__revers .events-wide__content {
     padding: 111px 50px 90px 40px;
   }
 }
 @media (max-width: 1024px) {
   .events-wide .events-wide__img a {
     height: 436px;
   }
   .events-wide .events-wide__img .tt-icon {
     width: 79px;
     height: 79px;
   }
   .events-wide .events-wide__img .tt-text {
     font-size: 73px;
   }
   .events-wide .events-wide__img .tt-text.tt-text__right {
     right: -12px;
     top: -1px;
   }
   .events-wide .events-wide__img .tt-text.tt-text__left {
     left: -14px;
   }
   .events-wide:not(.tt-item__revers) .events-wide__content {
     padding: 54px 65px 57px 50px;
   }
   .events-wide.tt-item__revers .events-wide__content {
     padding: 73px 50px 77px 40px;
   }
   .events-wide .events-wide__content {
     min-height: 467px;
   }
   .events-wide .events-wide__content [class^=tt-btn] {
     margin-top: 33px;
   }
 }
 @media (max-width: 887px) {
   .events-wide .events-wide__img a {
     height: 472px;
   }
 }
 @media (max-width: 767px) {
   .events-wide {
     flex-wrap: wrap;
   }
   .events-wide .events-wide__img,
 .events-wide .events-wide__content {
     flex: 0 0 100%;
     max-width: 100%;
     height: auto;
     overflow: hidden;
   }
   .events-wide .events-wide__content {
     min-height: inherit;
   }
   .events-wide:not(.tt-item__revers) .events-wide__content {
     padding: 36px 13px 90px 13px;
   }
   .events-wide.tt-item__revers .events-wide__content {
     padding: 36px 13px 90px 13px;
   }
   .events-wide .events-wide__img a {
     height: 400px;
   }
   .events-wide .events-wide__img .tt-icon {
     width: 53px;
     height: 53px;
     box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
   }
   .events-wide .events-wide__img .tt-icon:before {
     content: "";
     display: inline-block;
     position: absolute;
     border: 6px solid transparent;
     border-left: 11px solid #ffffff;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-5px, -6px);
     transform: translate(-5px, -6px);
   }
   .events-wide .events-wide__img a {
     height: 400px;
   }
   .events-wide .events-wide__img .tt-text {
     font-size: 67px;
     top: -4px;
   }
   .events-wide .blocktitle .tt-title-under {
     left: -11px;
   }
 }
 @media (max-width: 575px) {
   .events-wide .events-wide__img a {
     height: 235px;
   }
   .events-wide .events-wide__img .tt-text {
     font-size: 40px;
     top: -3px;
   }
   .events-wide .events-wide__img .tt-text.tt-text__right {
     right: -7px;
   }
   .events-wide .events-wide__img .tt-text.tt-text__left {
     left: -7px;
     top: -4px;
   }
   .events-wide .events-wide__content [class^=tt-btn] {
     margin-top: 28px;
   }
   .events-wide:not(.tt-item__revers) .events-wide__content {
     padding: 36px 13px 39px 13px;
   }
   .events-wide.tt-item__revers .events-wide__content {
     padding: 36px 13px 39px 13px;
   }
 }
 
 .events-wide-list .events-wide:nth-child(even) {
   flex-direction: row-reverse;
 }
 .events-wide-list .events-wide:nth-child(even) .events-wide__img a {
   margin-top: 0px;
   height: 531px;
 }
 @media (max-width: 1024px) {
   .events-wide-list .events-wide:nth-child(even) .events-wide__img a {
     height: 449px;
   }
 }
 @media (max-width: 575px) {
   .events-wide-list .events-wide:nth-child(even) .events-wide__img a {
     height: 235px;
   }
 }
 
 @-webkit-keyframes pulse {
   from {
     width: 100%;
     height: 100%;
     opacity: 0.8;
   }
   50% {
     width: 140%;
     height: 140%;
     opacity: 0;
   }
   to {
     width: 100%;
     height: 100%;
     opacity: 0;
   }
 }
 
 @keyframes pulse {
   from {
     width: 100%;
     height: 100%;
     opacity: 0.8;
   }
   50% {
     width: 140%;
     height: 140%;
     opacity: 0;
   }
   to {
     width: 100%;
     height: 100%;
     opacity: 0;
   }
 }
 html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__right {
   right: -19px;
 }
 html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__left {
   left: -19px;
 }
 @media (max-width: 1024px) {
   html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__right {
     right: -14px;
   }
   html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__left {
     left: -16px;
   }
 }
 @media (max-width: 575px) {
   html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__right {
     right: -8px;
   }
   html:not(.win).safari .events-wide .events-wide__img .tt-text.tt-text__left {
     left: -8px;
   }
 }
 
 .slick-dots01 .slick-dots {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   margin: 9px 0 -8px 0;
 }
 .slick-dots01 .slick-dots li {
   padding: 8px;
 }
 .slick-dots01 .slick-dots li > * {
   width: 8px;
   max-width: 8px;
   height: 8px;
   display: block;
   background-color: #C4C4C4;
   border-radius: 50%;
   font-size: 0;
   line-height: 0;
   box-shadow: none;
   outline: none;
   border: none;
   padding: 0;
   transition: background-color 0.2s;
 }
 .slick-dots01 .slick-dots li:not(.slick-active) {
   cursor: pointer;
 }
 .slick-dots01 .slick-dots li:not(.slick-active) > * {
   cursor: pointer;
 }
 .slick-dots01 .slick-dots li.slick-active > *, .slick-dots01 .slick-dots li:hover > * {
   background-color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .slick-dots01 .slick-dots li {
     padding: 8px 9px;
   }
   .slick-dots01 .slick-dots li > * {
     width: 6px;
     max-width: 6px;
     height: 6px;
   }
 }
 @media (max-width: 575px) {
   .slick-dots01 .slick-dots {
     margin-top: 0;
   }
 }
 
 .slick-slide {
   height: auto;
 }
 
 .slick-slider .slick-slide {
   outline: none;
 }
 
 .js-slick01,
 .js-slick02,
 .js-slick03,
 .js-slick04 {
   opacity: 0;
 }
 
 .loaded .js-slick01,
 .loaded .js-slick02,
 .loaded .js-slick03,
 .loaded .js-slick04 {
   opacity: 1;
 }
 
 .slick-arrows01 .slick-arrow {
   position: absolute;
   top: 50%;
 }
 .slick-arrows01 .slick-prev {
   left: 0;
 }
 .slick-arrows01 .slick-next {
   right: 0;
 }
 
 .slick-dots02 .slick-dots {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   margin: 28px 0 -8px 0;
 }
 .slick-dots02 .slick-dots li {
   padding: 8px;
 }
 .slick-dots02 .slick-dots li > * {
   width: 8px;
   max-width: 8px;
   height: 8px;
   display: block;
   background-color: #C4C4C4;
   border-radius: 50%;
   font-size: 0;
   line-height: 0;
   box-shadow: none;
   outline: none;
   border: none;
   padding: 0;
   transition: background-color 0.2s;
 }
 .slick-dots02 .slick-dots li:not(.slick-active) {
   cursor: pointer;
 }
 .slick-dots02 .slick-dots li:not(.slick-active) > * {
   cursor: pointer;
 }
 .slick-dots02 .slick-dots li.slick-active > *, .slick-dots02 .slick-dots li:hover > * {
   background-color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .slick-dots02 .slick-dots li {
     padding: 8px 9px;
   }
   .slick-dots02 .slick-dots li > * {
     width: 6px;
     max-width: 6px;
     height: 6px;
   }
 }
 @media (max-width: 575px) {
   .slick-dots02 .slick-dots {
     margin-top: 28px;
   }
 }
 
 .tt-promo01 {
   padding: 122px 0 130px 0;
   position: relative;
   overflow: hidden;
   background: #252427;
   color: #ffffff;
 }
 .tt-promo01:before, .tt-promo01:after {
   content: "";
   position: absolute;
   top: 50%;
   left: 0;
   width: 100%;
   height: 100%;
   pointer-events: none;
   z-index: 1;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
 }
 .tt-promo01 .tt-promo01__layout {
   max-width: 755px;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
   position: relative;
   background: #252427;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   z-index: 2;
 }
 .tt-promo01 .tt-promo01__layout .tt-title {
   font-family: Poppins;
   color: #3D3B42;
   font-weight: bold;
   font-size: 48px;
   line-height: 51px;
   text-align: center;
   letter-spacing: -0.03em;
   padding: 0 0 29px 0;
   margin: 0;
 }
 .tt-promo01 .tt-promo01__layout .tt-title + p {
   margin-top: 0;
 }
 .tt-promo01 .tt-promo01__layout [class^=tt-btn] {
   margin-top: 33px;
 }
 .tt-promo01 .tt-promo01__layout:before, .tt-promo01 .tt-promo01__layout:after {
   content: "";
   position: absolute;
   top: 0;
   width: 80px;
   height: 100%;
 }
 /* .tt-promo01 .tt-promo01__layout:before {
   left: -20px;
   background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 60%);
 }
 .tt-promo01 .tt-promo01__layout:after {
   right: -20px;
   background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 60%);
 } */
 .tt-promo01 .tt-promo01__layout > * {
   position: relative;
   z-index: 2;
 }
 @media (max-width: 1024px) {
   .tt-promo01 {
     padding: 54px 0 59px 0;
   }
   .tt-promo01 .tt-promo01__layout {
     max-width: 583px;
   }
   .tt-promo01 .tt-promo01__layout .tt-title {
     font-size: 35px;
     line-height: 37px;
     padding: 0 0 18px 0;
   }
   .tt-promo01 .tt-promo01__layout [class^=tt-btn] {
     margin-top: 23px;
   }
 }
 @media (min-width: 791px) {
   .tt-promo01:before {
     background: url("../images/promo-left.png") left center no-repeat;
   }
   .tt-promo01:after {
     background: url("../images/promo-right.png") right center no-repeat;
   }
 }
 @media (max-width: 790px) {
   .tt-promo01 {
     padding: 45px 0 52px 0;
   }
   .tt-promo01 .tt-promo01__layout .tt-title {
     font-size: 32px;
     line-height: 34px;
     padding-bottom: 10px;
   }
   .tt-promo01 .tt-promo01__layout .tt-title br {
     display: none;
   }
   .tt-promo01 .tt-promo01__layout [class^=tt-btn] {
     margin-top: 12px;
   }
 }
 
 .instafeed-masonry .instagram_gallery {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: center;
 }
 .instafeed-masonry .instagram_gallery > * {
   width: 25%;
   position: relative;
   display: block;
   overflow: hidden;
 }
 .instafeed-masonry .instagram_gallery > *:before {
   content: "";
   display: block;
   width: 50px;
   height: 50px;
   background: linear-gradient(270deg, #FF1A43 -5.89%, #7B16D9 100.01%);
   position: absolute;
   bottom: 0px;
   right: 0px;
   z-index: 2;
 }
 .instafeed-masonry .instagram_gallery > *:after {
   content: "\e902";
   font-family: "club";
   display: block;
   position: absolute;
   bottom: 12px;
   right: 12px;
   font-size: 24px;
   line-height: 25px;
   z-index: 3;
   color: #ffffff;
 }
 .instafeed-masonry .instagram_gallery > * img {
   position: relative;
   z-index: 1;
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
   width: 100%;
   height: 300px;
   -o-object-fit: cover;
   object-fit: cover;
 }
 .instafeed-masonry .instagram_gallery > *:hover img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 @media (min-width: 576px) {
   .instafeed-masonry .instagram_gallery > * {
     width: 25%;
   }
 }
 @media (max-width: 1200px) {
   .instafeed-masonry .instagram_gallery > * img {
     height: 269px;
   }
 }
 @media (max-width: 1024px) {
   .instafeed-masonry .instagram_gallery > *:before {
     width: 38px;
     height: 38px;
   }
   .instafeed-masonry .instagram_gallery > *:after {
     bottom: 9px;
     right: 9px;
     font-size: 19px;
     line-height: 19px;
   }
   .instafeed-masonry .instagram_gallery > * img {
     height: 200px;
   }
 }
 @media (max-width: 575px) {
   .instafeed-masonry .instagram_gallery > * {
     width: 50%;
     display: none;
   }
   .instafeed-masonry .instagram_gallery > *:nth-child(1), .instafeed-masonry .instagram_gallery > *:nth-child(2), .instafeed-masonry .instagram_gallery > *:nth-child(3), .instafeed-masonry .instagram_gallery > *:nth-child(4) {
     display: block;
   }
   .instafeed-masonry .instagram_gallery > *:before {
     width: 31px;
     height: 31px;
   }
   .instafeed-masonry .instagram_gallery > *:after {
     bottom: 8px;
     right: 7px;
     font-size: 16px;
     line-height: 16px;
   }
   .instafeed-masonry .instagram_gallery > * img {
     height: 170px;
   }
 }
 
 .instafeed-col .instagram_gallery {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   margin-left: -20px;
 }
 .instafeed-col .instagram_gallery > * {
   width: calc(25% - 20px);
   position: relative;
   display: block;
   overflow: hidden;
   margin-left: 20px;
   margin-top: 20px;
 }
 .instafeed-col .instagram_gallery > *:before {
   content: "";
   display: block;
   width: 50px;
   height: 50px;
   background: linear-gradient(270deg, #FF1A43 -5.89%, #7B16D9 100.01%);
   position: absolute;
   bottom: 0px;
   right: 0px;
   z-index: 2;
 }
 .instafeed-col .instagram_gallery > *:after {
   content: "\e902";
   font-family: "club";
   display: block;
   position: absolute;
   bottom: 12px;
   right: 12px;
   font-size: 24px;
   line-height: 25px;
   z-index: 3;
   color: #ffffff;
 }
 .instafeed-col .instagram_gallery > * img {
   position: relative;
   z-index: 1;
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
   width: 100%;
   height: 300px;
   -o-object-fit: cover;
   object-fit: cover;
 }
 .instafeed-col .instagram_gallery > *:hover img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 @media (max-width: 1200px) {
   .instafeed-col .instagram_gallery > * img {
     height: 240px;
   }
 }
 @media (max-width: 790px) {
   .instafeed-col .instagram_gallery > * {
     width: calc(33.333% - 20px);
   }
   .instafeed-col .instagram_gallery > *:before {
     width: 40px;
     height: 40px;
   }
   .instafeed-col .instagram_gallery > *:after {
     bottom: 10px;
     right: 10px;
     font-size: 20px;
     line-height: 21px;
   }
   .instafeed-col .instagram_gallery > * img {
     height: 200px;
   }
 }
 @media (max-width: 575px) {
   .instafeed-col .instagram_gallery > * {
     width: calc(50% - 20px);
   }
   .instafeed-col .instagram_gallery > * img {
     height: 170px;
   }
 }
 
 html:not(.mac) .section-parallax {
   background-attachment: fixed;
 }
 
 .section-parallax {
   background-size: cover;
   background-repeat: no-repeat;
 }
 .section-parallax .slick-dots {
   position: absolute;
   bottom: -39px;
   text-align: center;
   width: 100%;
 }
 
 .section-parallax-inner {
   padding: 159px 0 101px;
 }
 @media (max-width: 1024px) {
   .section-parallax-inner {
     padding: 135px 0 76px;
   }
 }
 @media (max-width: 1024px) {
   .section-parallax-inner {
     padding: 135px 0 76px;
   }
 }
 @media (max-width: 790px) {
   .section-parallax-inner {
     padding: 126px 0 76px;
   }
 }
 
 .section-parallax-border01 {
   position: relative;
   border-left: 5px solid #ffffff;
   border-right: 5px solid #ffffff;
 }
 .section-parallax-border01::before, .section-parallax-border01::after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 5px;
   background: #ffffff;
   transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
 }
 .section-parallax-border01::before {
   right: calc(76% + 5px);
 }
 .section-parallax-border01::after {
   left: calc(37% + 5px);
 }
 .section-parallax-border01 .section-parallax-border02 {
   display: block;
   text-align: center;
   padding-left: 35px;
   padding-right: 35px;
 }
 .section-parallax-border01 .section-parallax-border02::before, .section-parallax-border01 .section-parallax-border02::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   height: 5px;
   background: #ffffff;
   transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
 }
 .section-parallax-border01 .section-parallax-border02::before {
   right: calc(92% + 5px);
 }
 .section-parallax-border01 .section-parallax-border02::after {
   left: calc(21% + 5px);
 }
 @media (max-width: 1024px) {
   .section-parallax-border01 {
     border-left-width: 4px;
     border-right-width: 4px;
   }
   .section-parallax-border01::before, .section-parallax-border01::after {
     height: 4px;
   }
   .section-parallax-border01::before {
     right: calc(69% + 5px);
   }
   .section-parallax-border01::after {
     left: calc(47% + 5px);
   }
   .section-parallax-border01 .section-parallax-border02::before, .section-parallax-border01 .section-parallax-border02::after {
     height: 4px;
   }
   .section-parallax-border01 .section-parallax-border02::before {
     right: calc(90% + 5px);
   }
   .section-parallax-border01 .section-parallax-border02::after {
     left: calc(25% + 5px);
   }
 }
 @media (max-width: 790px) {
   .section-parallax-border01 {
     border-left-width: 3px;
     border-right-width: 3px;
   }
   .section-parallax-border01::before, .section-parallax-border01::after {
     height: 3px;
   }
   .section-parallax-border01 .section-parallax-border02 {
     padding-left: 20px;
     padding-right: 20px;
   }
   .section-parallax-border01 .section-parallax-border02::before, .section-parallax-border01 .section-parallax-border02::after {
     height: 3px;
   }
 }
 
 .tt-parallax01 {
   color: #ffffff;
   padding: 67px 0 59px;
 }
 .tt-parallax01 .tt-parallax01__wrapper {
   max-width: 859px;
   margin-left: auto;
   margin-right: auto;
 }
 .tt-parallax01 .tt-parallax01__data {
   font-size: 15px;
   line-height: 25px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }
 .tt-parallax01 .tt-parallax01__title {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 48px;
   line-height: 50px;
   letter-spacing: -0.03em;
   color: #ffffff;
   margin: 39px 0 0 0;
   padding: 0 0 15px 0;
 }
 .tt-parallax01 .tt-parallax01__title + p {
   margin-top: 0;
 }
 .tt-parallax01 [class^=tt-btn] {
   margin-top: 43px;
 }
 @media (max-width: 1024px) {
   .tt-parallax01 {
     padding: 38px 0 38px;
   }
   .tt-parallax01 .tt-parallax01__data {
     font-size: 13px;
     letter-spacing: 0.2em;
     line-height: 30px;
   }
   .tt-parallax01 .tt-parallax01__title {
     font-size: 35px;
     line-height: 37px;
     letter-spacing: -0.03em;
     margin-top: 24px;
     padding-bottom: 20px;
   }
   .tt-parallax01 [class^=tt-btn] {
     margin-top: 23px;
   }
 }
 @media (max-width: 790px) {
   .tt-parallax01 {
     padding: 33px 0 37px;
   }
   .tt-parallax01 .tt-parallax01__data {
     font-size: 13px;
     letter-spacing: 0.2em;
     line-height: 30px;
   }
   .tt-parallax01 .tt-parallax01__title {
     font-size: 35px;
     line-height: 37px;
     letter-spacing: -0.03em;
     margin-top: 20px;
     padding-bottom: 7px;
   }
   .tt-parallax01 [class^=tt-btn] {
     margin-top: 27px;
   }
 }
 
 .parallax__navleft,
 .parallax__navright {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   position: absolute;
   width: 55px;
   height: 55px;
   top: 50%;
   color: #ffffff;
   font-size: 16px;
   transition: color 0.2s;
   font-size: 31px;
 }
 .parallax__navleft:hover,
 .parallax__navright:hover {
   color: #FF1A43;
 }
 @media (max-width: 1220px) {
   .parallax__navleft,
 .parallax__navright {
     display: none !important;
   }
 }
 
 .parallax__navleft {
   left: 35px;
 }
 
 .parallax__navright {
   right: 35px;
 }
 
 .tt-box01-wrapper {
   margin-left: -15px;
   margin-right: -15px;
 }
 .tt-box01-wrapper .item {
   padding-left: 15px;
   padding-right: 15px;
 }
 @media (min-width: 1024px) {
   .tt-box01-wrapper {
     padding-top: 10px;
   }
 }
 
 .tt-box01 {
   margin-bottom: -6px;
 }
 .tt-box01 .tt-box01__img {
   display: block;
   overflow: hidden;
 }
 .tt-box01 .tt-box01__img img {
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
   width: 100%;
   height: auto;
 }
 .tt-box01 .tt-box01__img:hover img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 .tt-box01 .tt-box01_layout {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: center;
   position: relative;
   top: -39px;
 }
 .tt-box01 .tt-box01_layout:not(.tt-box01_layout02) {
   margin-left: 30px;
   background-color: #ffffff;
 }
 .tt-box01 .tt-box01_layout.tt-box01_layout02 {
   flex-direction: row-reverse;
   background-color: #3D3B42;
   color: #ffffff;
   margin-left: 30px;
   margin-right: 0;
 }
 .tt-box01 .tt-box01_layout.tt-box01_layout02 .tt-box01__title .tt-title01 {
   color: #ffffff;
 }
 .tt-box01 .tt-box01_layout.tt-box01_layout02 .tt-box01__title .tt-title01 a:not(:hover) {
   color: #ffffff;
 }
 .tt-box01 .tt-box01_layout.tt-box01_layout02 .tt-box01__title .tt-title02 {
   color: #D3D3D5;
 }
 .tt-box01 .tt-box01__social {
   background: linear-gradient(270deg, #FF1A43 -3.76%, #7B16D9 100%);
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   padding: 0 12px;
 }
 .tt-box01 .tt-box01__social li a {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   color: #ffffff;
   padding: 25px 10px;
   font-size: 20px;
 }
 .tt-box01 .tt-box01__social li a > * {
   transition: -webkit-transform 0.2s;
   transition: transform 0.2s;
   transition: transform 0.2s, -webkit-transform 0.2s;
 }
 .tt-box01 .tt-box01__social li a:hover > * {
   -webkit-transform: scale(1.15);
   transform: scale(1.15);
 }
 .tt-box01 .tt-box01__title {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   padding: 0 26px;
   background: #252427;
 }
 .tt-box01 .tt-box01__title .tt-title01 {
   font-size: 22px;
   line-height: 23px;
   letter-spacing: -0.03em;
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   width: 100%;
 }
 .tt-box01 .tt-box01__title .tt-title01 a {
   color: #ffffff;
   transition: color 0.2s;
 }
 .tt-box01 .tt-box01__title .tt-title01 a:hover {
   color: #FF1A43;
 }
 .tt-box01 .tt-box01__title .tt-title02 {
   font-size: 15px;
   line-height: 18px;
   color: #ffffff;
   width: 100%;
   margin-top: 3px;
 }
 @media (max-width: 1239px) {
   .tt-box01 .tt-box01__title {
     padding: 0 20px;
     margin-top: 2px;
   }
   .tt-box01 .tt-box01__title .tt-title01 {
     font-size: 19px;
     line-height: 21px;
   }
   .tt-box01 .tt-box01__title .tt-title02 {
     font-size: 14px;
     line-height: 17px;
     margin-top: 3px;
   }
   .tt-box01 .tt-box01_layout:not(.tt-box01_layout02) {
     margin-left: 17px;
   }
   .tt-box01 .tt-box01__social {
     padding: 0 12px;
   }
   .tt-box01 .tt-box01__social li a {
     padding: 20px 8px;
     font-size: 18px;
   }
 }
 @media (max-width: 1024px) and (min-width: 576px) {
   .tt-box01 .tt-box01__title {
     padding: 0 5px 0 15px;
   }
   .tt-box01 .tt-box01__title .tt-title01 {
     font-size: 18px;
     line-height: 19px;
   }
   .tt-box01 .tt-box01__title .tt-title02 {
     font-size: 13px;
     line-height: 15px;
     margin-top: 1px;
   }
   .tt-box01 .tt-box01_layout {
     top: -29px;
   }
   .tt-box01 .tt-box01__social {
     padding: 0 7px;
   }
   .tt-box01 .tt-box01__social li a {
     padding: 17px 7px 18px;
     font-size: 13px;
   }
 }
 @media (max-width: 575px) {
   .tt-box01 {
     max-width: 287px;
     margin-left: auto;
     margin-right: auto;
   }
   .tt-box01 .tt-box01__title {
     padding: 0 5px 0 20px;
     margin-top: 0;
   }
   .tt-box01 .tt-box01__title .tt-title01 {
     font-size: 18px;
     line-height: 19px;
   }
   .tt-box01 .tt-box01__title .tt-title02 {
     font-size: 13px;
     line-height: 15px;
     margin-top: 5px;
   }
   .tt-box01 .tt-box01_layout {
     top: -35px;
   }
   .tt-box01 .tt-box01__social {
     padding: 0 7px;
   }
   .tt-box01 .tt-box01__social li a {
     padding: 22px 9px;
     font-size: 15px;
   }
 }
 
 .promo02 {
   width: 100%;
   background-size: cover;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   padding: 67px 0 20px;
   min-height: 400px;
   position: relative;
   overflow: hidden;
 }
 .promo02 .promo02__img svg {
   -webkit-transform-style: preserve-3d;
   transform-style: preserve-3d;
   -webkit-animation: torsion 7s infinite alternate ease-in-out -7.5s;
   animation: torsion 7s infinite alternate ease-in-out -7.5s;
   -webkit-animation-fill-mode: forwards;
   animation-fill-mode: forwards;
 }
 @-webkit-keyframes torsion {
   from {
     opacity: 1;
   }
   4% {
     opacity: 0.9;
   }
   6% {
     opacity: 0.85;
   }
   8% {
     opacity: 0.95;
   }
   10% {
     opacity: 0.9;
   }
   11% {
     opacity: 0.922;
   }
   12% {
     opacity: 0.9;
   }
   14% {
     opacity: 0.95;
   }
   16% {
     opacity: 0.98;
   }
   17% {
     opacity: 0.9;
   }
   19% {
     opacity: 0.93;
   }
   20% {
     opacity: 0.99;
   }
   24% {
     opacity: 1;
   }
   26% {
     opacity: 0.94;
   }
   28% {
     opacity: 0.98;
   }
   37% {
     opacity: 0.93;
   }
   39% {
     opacity: 0.96;
   }
   42% {
     opacity: 1;
   }
   44% {
     opacity: 0.97;
   }
   46% {
     opacity: 0.94;
   }
   56% {
     opacity: 0.9;
   }
   58% {
     opacity: 0.9;
   }
   60% {
     opacity: 0.99;
   }
   68% {
     opacity: 1;
   }
   70% {
     opacity: 0.9;
   }
   72% {
     opacity: 0.95;
   }
   93% {
     opacity: 0.93;
   }
   95% {
     opacity: 0.95;
   }
   97% {
     opacity: 0.93;
   }
   to {
     opacity: 1;
   }
 }
 @keyframes torsion {
   from {
     opacity: 1;
   }
   4% {
     opacity: 0.9;
   }
   6% {
     opacity: 0.85;
   }
   8% {
     opacity: 0.95;
   }
   10% {
     opacity: 0.9;
   }
   11% {
     opacity: 0.922;
   }
   12% {
     opacity: 0.9;
   }
   14% {
     opacity: 0.95;
   }
   16% {
     opacity: 0.98;
   }
   17% {
     opacity: 0.9;
   }
   19% {
     opacity: 0.93;
   }
   20% {
     opacity: 0.99;
   }
   24% {
     opacity: 1;
   }
   26% {
     opacity: 0.94;
   }
   28% {
     opacity: 0.98;
   }
   37% {
     opacity: 0.93;
   }
   39% {
     opacity: 0.96;
   }
   42% {
     opacity: 1;
   }
   44% {
     opacity: 0.97;
   }
   46% {
     opacity: 0.94;
   }
   56% {
     opacity: 0.9;
   }
   58% {
     opacity: 0.9;
   }
   60% {
     opacity: 0.99;
   }
   68% {
     opacity: 1;
   }
   70% {
     opacity: 0.9;
   }
   72% {
     opacity: 0.95;
   }
   93% {
     opacity: 0.93;
   }
   95% {
     opacity: 0.95;
   }
   97% {
     opacity: 0.93;
   }
   to {
     opacity: 1;
   }
 }
 .promo02 .promo02__layout {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   padding-top: 57px;
 }
 .promo02 .tt-col:nth-child(1) {
   flex: 0 0 30%;
   max-width: 30%;
   padding-bottom: 0;
 }
 .promo02 .tt-col:nth-child(2) {
   margin-top: 0px;
   flex: 2 1 auto;
   padding: 0px 70px 0 50px;
 }
 .promo02 .tt-col:nth-child(3) {
   padding: 0px 0 0 0;
 }
 .promo02 .promo02__title {
   font-size: 48px;
   line-height: 51px;
   color: #ffffff;
   letter-spacing: -0.03em;
   font-family: "Poppins", sans-serif;
   padding: 0;
   margin: 0;
 }
 .promo02 .promo02__content {
   color: #D3D3D5;
 }
 .promo02 .promo02_rowbtn > * {
   min-width: 197px;
 }
 .promo02 .promo02_rowbtn > *:not(:first-child) {
   margin-top: 13px;
 }
 @media (min-width: 1891px) {
   .promo02 .promo02__img {
     position: absolute;
     left: 6.4%;
   }
 }
 @media (max-width: 1890px) {
   .promo02 {
     padding-left: 25px;
     padding-right: 25px;
   }
   .promo02 .promo02__title {
     font-size: 43px;
     line-height: 45px;
   }
   .promo02 .promo02__img {
     left: 0px;
     margin-right: 30px;
   }
   .promo02 .promo02__img > svg {
     max-width: 150px;
   }
   .promo02 .tt-col:nth-child(1) {
     max-width: 24%;
     flex: 0 0 24%;
     min-width: 332px;
   }
   .promo02 .container {
     max-width: inherit;
     padding: 0;
     margin: 0;
   }
 }
 @media (max-width: 1400px) {
   .promo02 .promo02__title {
     font-size: 40px;
     line-height: 42px;
   }
   .promo02 .promo02__img {
     left: 0px;
     margin-right: 25px;
   }
   .promo02 .promo02__img > svg {
     max-width: 110px;
   }
   .promo02 .tt-col:nth-child(1) {
     max-width: 24%;
     flex: 0 0 24%;
     min-width: 288px;
   }
   .promo02 .tt-col:nth-child(2) {
     padding: 0px 50px 0px 50px;
   }
 }
 @media (max-width: 1200px) {
   .promo02 {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: stretch;
     align-items: flex-start;
     padding: 105px 0px 23px;
   }
   .promo02 .promo02__img {
     flex: 0 0 inherit;
     max-width: inherit;
     margin-right: 0;
     padding-right: 30px;
     position: relative;
     top: -25px;
   }
   .promo02 .promo02__img svg {
     max-width: 152px;
     float: right;
   }
   .promo02 .container {
     flex: 0 0 inherit;
     max-width: inherit;
   }
   .promo02 .promo02__layout {
     flex-wrap: wrap;
     justify-content: flex-start;
     align-content: stretch;
     align-items: flex-start;
     padding-top: 0px;
     padding-right: 40px;
     padding-bottom: 20px;
   }
   .promo02 .promo02__layout .t-col {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .promo02 .promo02__layout .tt-col:nth-child(1) {
     flex: 0 0 100%;
     max-width: 100%;
     min-width: inherit;
   }
   .promo02 .promo02__layout .tt-col:nth-child(2) {
     padding: 0px;
     margin-top: 12px;
   }
   .promo02 .promo02__layout .tt-col:nth-child(3) {
     padding: 0px;
     margin-top: 11px;
   }
   .promo02 .promo02__layout .promo02_rowbtn {
     margin-left: -26px;
     margin-top: -6px;
   }
   .promo02 .promo02__layout .promo02_rowbtn > * {
     min-width: 152px;
     margin-top: 20px;
     margin-left: 26px;
   }
   .promo02 .promo02__title {
     font-size: 35px;
     line-height: 37px;
   }
   .promo02 .promo02__title br {
     display: none;
   }
 }
 @media (max-width: 1024px) {
   .promo02 {
     padding: 84px 0px 25px;
   }
 }
 @media (max-width: 766px) {
   .promo02 {
     padding: 55px 20px 29px;
   }
   .promo02 .promo02__title {
     font-size: 33px;
     line-height: 34px;
   }
   .promo02 .promo02__title br {
     display: block;
   }
   .promo02 .promo02__img {
     top: -7px;
     padding-right: 10px;
   }
   .promo02 .promo02__img svg {
     max-width: 88px;
     height: auto;
   }
   .promo02 .promo02__layout {
     padding-right: 20px;
   }
   .promo02 .promo02__layout .promo02_rowbtn > * {
     min-width: inherit;
   }
   .promo02 .promo02__layout .tt-col:nth-child(2) {
     margin-top: 9px;
   }
 }
 
 .promo-box-wide {
   overflow: hidden;
 }
 .promo-box-wide .tt-item {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-end;
   align-content: stretch;
   align-items: stretch;
   padding: 50px 0 50px;
   position: relative;
 }
 .promo-box-wide .tt-item .tt-item__img {
   width: 59.9%;
   background-size: cover;
   position: absolute;
   z-index: 1;
   left: 0;
   top: 0;
   height: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 0 0;
 }
 @media (min-width: 1025px) {
   .promo-box-wide .tt-item .tt-item__img {
     background-image: url("../images/promo-box-wide01.jpg");
   }
 }
 @media (max-width: 1024px) and (min-width: 767px) {
   .promo-box-wide .tt-item .tt-item__img {
     background-image: url("../images/promo-box-wide02.jpg");
   }
 }
 @media (max-width: 766px) {
   .promo-box-wide .tt-item .tt-item__img {
     background-image: url("../images/promo-box-wide03.jpg");
     height: 400px;
     width: 100%;
     background-position: center 0;
   }
 }
 @media (max-width: 575px) {
   .promo-box-wide .tt-item .tt-item__img {
     height: 244px;
   }
 }
 .promo-box-wide .tt-item .tt-item__content {
   background: #252427;
   box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25);
   width: 53.7%;
   position: relative;
   z-index: 2;
   padding: 74px 95px 110px;
   color: #ffffff;
 }
 .promo-box-wide .tt-item .tt-content-text {
   max-width: 571px;
   margin-top: -16px;
   position: relative;
   z-index: 3;
 }
 .promo-box-wide .tt-item .tt-item__label {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   width: 149px;
   height: 149px;
   padding: 10px 5px;
   background-color: #FF1A43;
   position: absolute;
   left: -91px;
   top: 50px;
   color: #ffffff;
   text-align: center;
   font-size: 12px;
   line-height: 14px;
   letter-spacing: -0.03em;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
 }
 .promo-box-wide .tt-item .tt-item__label [class^=icon-] {
   font-size: 40px;
   line-height: 40px;
   position: relative;
   top: 10px;
 }
 .promo-box-wide .tt-item .tt-item__label-text-01 {
   margin: 5px 0;
   font-size: 20px;
   line-height: 37px;
 }
 @media (min-width: 1025px) {
   .promo-box-wide .tt-item .list-01 {
     -webkit-column-count: 2;
     -moz-column-count: 2;
     column-count: 2;
     -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
     column-gap: 20px;
     list-style-type: none;
   }
 }
 @media (max-width: 1380px) {
   .promo-box-wide .tt-item .tt-item__content {
     padding: 74px 55px 110px 95px;
     box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
   }
 }
 @media (max-width: 1024px) {
   .promo-box-wide .tt-item {
     padding: 30px 0;
   }
   .promo-box-wide .tt-item .tt-item__content {
     padding: 24px 40px 56px 77px;
   }
   .promo-box-wide .tt-item .tt-item__label-icon > svg {
     max-width: 27px;
     height: auto;
   }
   .promo-box-wide .tt-item .tt-item__label-icon [class^=icon-] {
     font-size: 32px;
     line-height: 32px;
     position: relative;
     top: 1px;
   }
   .promo-box-wide .tt-item .tt-item__label-text-01 {
     margin-top: 5px;
   }
   .promo-box-wide .tt-item .tt-item__label {
     width: 118px;
     height: 118px;
     font-size: 10px;
     left: -80px;
     top: 30px;
   }
   .promo-box-wide .tt-item .tt-item__label-text-02 {
     font-size: 30px;
     line-height: 29px;
     margin-top: 1px;
   }
   .promo-box-wide .tt-item .tt-item__label-text-03 {
     margin-top: 0px;
   }
   .promo-box-wide .tt-item .tt-content-text {
     margin-top: -20px;
   }
   .promo-box-wide .tt-item .list-01.list-text-top {
     margin-top: 23px;
   }
   .promo-box-wide .tt-item .tt-link-top {
     margin-top: 34px;
   }
 }
 @media (max-width: 767px) {
   .promo-box-wide .tt-item {
     flex-wrap: wrap;
     padding: 0;
   }
   .promo-box-wide .tt-item .tt-item__label {
     top: 0;
     left: auto;
     right: 20px;
     -webkit-transform: translate(0, -100%);
     transform: translate(0, -100%);
     margin-top: 10px;
   }
   .promo-box-wide .tt-item .tt-item__img {
     width: 100%;
     position: relative;
   }
   .promo-box-wide .tt-item .tt-item__content {
     position: relative;
     width: 100%;
     padding: 7px 20px 46px;
   }
   .promo-box-wide .tt-item .tt-item__content .title__text-description {
     margin-top: 21px;
   }
   .promo-box-wide .tt-item .list-01.list-text-top {
     margin-top: 17px;
   }
   .promo-box-wide .tt-item .blocktitle .tt-title-under {
     left: -11px;
   }
 }
 
 @media (max-width: 1024px) {
   .tt-table-hide {
     display: none;
   }
 }
 
 .contact-map {
   position: relative;
   z-index: 0;
 }
 .contact-map #map {
   height: 450px;
   width: 100%;
 }
 
 .contact-info-wrapper {
   background-image: url("../images/contact-info-wrapper.jpg");
   background-size: cover;
   background-position: center center;
   padding: 124px 0;
 }
 
 .contact-info__title {
   font-size: 48px;
   line-height: 50px;
   letter-spacing: -0.03em;
   color: #ffffff;
   font-family: "Poppins", sans-serif;
   margin: 0;
   padding: 0 0 40px 0;
 }
 @media (max-width: 1024px) {
   .contact-info__title {
     font-size: 35px;
     line-height: 37px;
   }
 }
 
 .contact-info {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   text-align: left;
   max-width: 300px;
   margin-left: auto;
   margin-right: auto;
 }
 .contact-info .contact-info__icon {
   color: #FF1A43;
   width: 34px;
   min-width: 34px;
 }
 .contact-info .contact-info__icon > * {
   font-size: 22px;
   position: relative;
   top: 3px;
 }
 .contact-info .contact-info__content .tt-title {
   font-size: 18px;
   line-height: 27px;
   font-weight: bold;
   color: #ffffff;
   font-family: "Poppins", sans-serif;
 }
 .contact-info .contact-info__content address {
   margin: 0;
   padding: 0;
   color: #D3D3D5;
   font-size: 17px;
   line-height: 27px;
   margin-top: 13px;
 }
 @media (max-width: 1024px) {
   .contact-info .contact-info__icon {
     width: 24px;
     min-width: 24px;
   }
   .contact-info .contact-info__icon > * {
     font-size: 17px;
     top: 2px;
   }
   .contact-info .contact-info__content .tt-title {
     font-size: 16px;
     line-height: 24px;
     padding-bottom: 5px;
   }
   .contact-info .contact-info__content address {
     font-size: 17px;
     line-height: 27px;
     margin-top: 3px;
   }
 }
 
 .contact-info-list {
   margin-top: -35px;
 }
 .contact-info-list .contact-info {
   margin-top: 35px;
 }
 
 .newsletterform-01 {
   max-width: 370px;
 }
 .newsletterform-01 .form-group {
   position: relative;
   margin: 0;
   padding: 0;
 }
 .newsletterform-01 .form-group .tt-input {
   border-radius: 5px;
   border: 1px solid rgba(0, 0, 0, 0.15);
   transition: 0.2s;
   font-size: 16px;
   line-height: 19px;
   padding: 11px 128px 11px 19px;
   font-family: "Roboto", sans-serif;
   width: 100%;
   display: block;
   height: 48px;
 }
 .newsletterform-01 .form-group .tt-input:focus {
   outline: 0;
   border-color: #FF1A43;
 }
 .newsletterform-01 .form-group .tt-btn-icon {
   position: absolute;
   right: 0;
   top: 0;
   border: none;
   background: 0 0;
   outline: 0;
   cursor: pointer;
   width: 50px;
   height: 100%;
   color: #FF1A43;
   transition: color 0.2s linear;
 }
 .newsletterform-01 label.error {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: center;
   position: absolute;
   top: 56px;
   left: 0;
   padding: 8px 12px 6px;
   background: red;
   color: #fff;
   font-size: 14px;
   line-height: 22px;
   z-index: 1;
 }
 .newsletterform-01 label.error:after {
   content: "";
   position: absolute;
   top: -12px;
   left: 50%;
   border: 6px solid transparent;
   border-bottom: 6px solid #f8353e;
 }
 .newsletterform-01 .form-group {
   margin-top: 21px;
 }
 .newsletterform-01 > :nth-child(1) {
   margin-top: 0;
 }
 
 #contactform textarea {
   height: 192px;
 }
 
 .events-parties .tt-item {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-content: stretch;
   align-items: stretch;
   padding: 50px 0;
   position: relative;
   background-color: #252427;
 }
 .events-parties .tt-item:not(:first-child) {
   margin-top: 50px;
 }
 .events-parties .tt-item .tt-item__img {
   width: 61%;
   background-size: cover;
   position: absolute;
   z-index: 1;
   top: 0;
   height: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 0 0;
 }
 @media (max-width: 766px) {
   .events-parties .tt-item .tt-item__img {
     height: 400px;
     width: 100%;
     background-position: center 0;
   }
 }
 @media (max-width: 575px) {
   .events-parties .tt-item .tt-item__img {
     height: 244px;
   }
 }
 .events-parties .tt-item .tt-item__content {
   background: #252427;
   box-shadow: 0px 4px 124px rgba(0, 0, 0, 0.25);
   width: 53.9%;
   position: relative;
   z-index: 2;
   padding: 85px 95px 89px 90px;
   color: #ffffff;
 }
 .events-parties .tt-item .tt-content-text {
   max-width: 571px;
   position: relative;
   z-index: 3;
 }
 .events-parties .tt-item .tt-btn-row {
   margin-top: 42px;
 }
 @media (min-width: 1025px) {
   .events-parties .tt-item .tt-btn-row [class^=tt-btn] span {
     padding-left: 42px;
     padding-right: 42px;
   }
 }
 .events-parties .tt-item:nth-child(odd) {
   justify-content: flex-end;
 }
 .events-parties .tt-item:nth-child(odd) .tt-item__img {
   left: 0;
 }
 .events-parties .tt-item:nth-child(even) {
   justify-content: flex-start;
 }
 .events-parties .tt-item:nth-child(even) .tt-item__img {
   right: 0;
 }
 .events-parties .tt-item:nth-child(even) .tt-item__content {
   background: #252427;
   color: #ffffff;
 }
 @media (min-width: 768px) {
   .events-parties .tt-item:nth-child(even) .tt-item__content .tt-item__content-wrapper {
     float: right;
   }
 }
 .events-parties .tt-item:nth-child(even) .blocktitle .tt-title {
   color: #ffffff;
 }
 @media (max-width: 1239px) {
   .events-parties .tt-item .tt-item__content {
     padding: 65px 60px 69px 50px;
   }
 }
 @media (max-width: 767px) {
   .events-parties .tt-item {
     flex-wrap: wrap;
     padding: 0;
   }
   .events-parties .tt-item:not(:first-child) {
     margin-top: 50px;
   }
   .events-parties .tt-item .tt-item__img {
     width: 100%;
     position: relative;
   }
   .events-parties .tt-item .tt-item__content {
     position: relative;
     width: 100%;
     padding: 34px 30px 56px;
   }
   .events-parties .tt-item .tt-btn-row {
     margin-top: 32px;
   }
 }
 @media (max-width: 575px) {
   .events-parties .tt-item:not(:first-child) {
     margin-top: 30px;
   }
   .events-parties .tt-item .tt-item__content {
     padding: 34px 20px 46px;
   }
 }
 
 .ttcalendar__nav {
   position: relative;
 }
 .ttcalendar__nav .ttcalendar__navleft,
 .ttcalendar__nav .ttcalendar__navright {
   position: absolute;
   color: #9e9ea0;
   font-size: 24px;
   z-index: 3;
   top: -2px;
   transition: color 0.2s linear;
 }
 .ttcalendar__nav .ttcalendar__navleft:hover,
 .ttcalendar__nav .ttcalendar__navright:hover {
   color: #FF1A43;
 }
 .ttcalendar__nav .ttcalendar__navleft {
   right: calc(50% + 105px);
 }
 .ttcalendar__nav .ttcalendar__navright {
   left: calc(50% + 105px);
 }
 .ttcalendar__nav .ttcalendar__navlink {
   z-index: 3;
   font-size: 18px;
   line-height: 15px;
   color: #3D3B42;
   font-weight: 700;
   transition: color 0.2s ease-in-out;
 }
 .ttcalendar__nav .ttcalendar__navlink .tt-icon {
   margin-right: 6px;
   color: #FF1A43;
   position: relative;
   top: 2px;
 }
 .ttcalendar__nav .ttcalendar__navlink:hover {
   color: #FF1A43;
 }
 .ttcalendar__nav:before, .ttcalendar__nav:after {
   content: "";
   position: absolute;
   top: 0;
   width: 40%;
   height: 30px;
   z-index: 2;
 }
 .ttcalendar__nav:before {
   left: 0px;
   background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff 180px);
 }
 .ttcalendar__nav:after {
   right: 0px;
   background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 180px);
 }
 @media (min-width: 701px) {
   .ttcalendar__nav .ttcalendar__navlink {
     position: absolute;
     right: 0;
     top: 0;
   }
 }
 @media (max-width: 700px) {
   .ttcalendar__nav .ttcalendar__navlink {
     position: absolute;
     right: 50%;
     top: 40px;
     -webkit-transform: translate(50%, 0%);
     transform: translate(50%, 0%);
     font-size: 16px;
   }
   .ttcalendar__nav .ttcalendar__navlink .tt-icon {
     margin-right: 4px;
   }
   .ttcalendar__nav .ttcalendar__navlink .tt-icon svg {
     width: 14px;
     height: 14px;
   }
 }
 
 .section-wrapper .ttcalendar__nav .ttcalendar__navlink {
   color: #ffffff;
 }
 .section-wrapper .ttcalendar__nav:before {
   background: linear-gradient(to left, rgba(60, 59, 65, 0), #3c3b41 180px);
 }
 .section-wrapper .ttcalendar__nav:after {
   background: linear-gradient(to right, rgba(60, 59, 65, 0), #3c3b41 180px);
 }
 .section-wrapper .ttcalendar-layout .ttcalendar__month-title {
   color: #ffffff;
 }
 
 .ttcalendar-layout {
   position: relative;
   background-color: #252427;
 }
 .ttcalendar-layout .ttcalendar__month-title {
   text-align: center;
   color: #ffffff;
   font-size: 24px;
   line-height: 20px;
   font-weight: bold;
   padding-bottom: 41px;
 }
 @media (max-width: 700px) {
   .ttcalendar-layout.ttcalendar-layout01 .ttcalendar__month-title, .ttcalendar-layout.ttcalendar-layout02 .ttcalendar__month-title {
     padding-bottom: 61px;
   }
 }
 
 /*
     events.html
 */
 .ttcalendar-layout01 .tt-day-header {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .ttcalendar-layout01 .tt-day-header > * {
   font-size: 17px;
   line-height: 27px;
   color: #D3D3D5;
   width: 14.285%;
   height: 49px;
   text-align: center;
 }
 .ttcalendar-layout01 .tt-day-grid {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   margin-top: -10px;
   margin-left: -10px;
 }
 .ttcalendar-layout01 .tt-day-grid > * {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   width: calc(14.285% - 10px);
   height: 262px;
   background-color: #ffffff;
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
   position: relative;
   margin-top: 10px;
   margin-left: 10px;
   font-size: 36px;
   line-height: 38px;
 }
 .ttcalendar-layout01 .tt-day-grid > *.tt-day-empty {
   opacity: 0.2;
 }
 .ttcalendar-layout01 .tt-day-grid > *:not(.tt-day-empty):hover {
   box-shadow: 0 7px 16px rgba(61, 59, 66, 0.7);
 }
 .ttcalendar-layout01 .tt-day-grid > *:not(.tt-day-empty):hover .tt-day-event__bg:before {
   opacity: 0.98;
 }
 .ttcalendar-layout01 .tt-day-grid > *:not(.tt-day-empty):hover .tt-day-event__bg > * {
   opacity: 1;
 }
 .ttcalendar-layout01 .tt-day-grid > *:not(.tt-day-empty):hover .tt-day-event__time,
 .ttcalendar-layout01 .tt-day-grid > *:not(.tt-day-empty):hover .tt-day-event__label {
   opacity: 0;
   z-index: 0;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg {
   position: absolute;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   z-index: 1;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg > * {
   min-width: 155px;
   margin: 10px 0;
   position: relative;
   z-index: 2;
   opacity: 0;
   transition: opacity 0.25s linear;
   cursor: pointer;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg:before {
   content: "";
   position: absolute;
   z-index: 1;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: #3D3B42;
   opacity: 0;
   transition: opacity 0.25s linear;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
   background: url("../images/label-01.png") 0 0 no-repeat;
   background-size: cover;
   width: 54px;
   height: 62px;
   top: 0;
   right: 20px;
   position: absolute;
   z-index: 2;
   color: #ffffff;
   text-align: center;
   font-family: "Roboto", sans-serif;
   font-size: 15px;
   line-height: 16px;
   padding: 8px 0 0 0;
   transition: opacity 0.25s linear;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
   display: block;
   font-size: 25px;
   line-height: 25px;
   font-weight: bold;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__label {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   position: absolute;
   z-index: 1;
   margin-top: 10px;
   transition: opacity 0.25s linear;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
   max-width: 97%;
 }
 .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
   color: #ffffff;
   font-size: 15px;
   line-height: 15px;
   text-transform: uppercase;
   margin-top: -5px;
   margin-left: 40px;
   letter-spacing: -0.02em;
 }
 @media (max-width: 1650px) {
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 220px;
     font-size: 32px;
     line-height: 34px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 92%;
   }
 }
 @media (max-width: 1400px) {
   .ttcalendar-layout01 .tt-day-header > * {
     height: 43px;
   }
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 190px;
     font-size: 28px;
     line-height: 32px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
     font-size: 14px;
     line-height: 14px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 87%;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
     width: 44px;
     height: 52px;
     font-size: 13px;
     line-height: 14px;
     padding: 6px 0 0 0;
     right: 15px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
     font-size: 22px;
     line-height: 22px;
   }
 }
 @media (max-width: 1239px) {
   .ttcalendar-layout01 .tt-day-header > * {
     font-size: 16px;
     line-height: 26px;
     height: 40px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg > * {
     padding: 10px 10px 11px;
     min-width: 100px;
     margin: 5px 0;
   }
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 160px;
     font-size: 24px;
     line-height: 28px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
     font-size: 12px;
     line-height: 12px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 85%;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
     width: 38px;
     height: 46px;
     font-size: 12px;
     line-height: 13px;
     padding: 7px 0 0 0;
     right: 10px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
     font-size: 18px;
     line-height: 18px;
   }
 }
 @media (max-width: 1024px) {
   .ttcalendar-layout01 .tt-day-header > * {
     font-size: 16px;
     line-height: 26px;
     height: 40px;
   }
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 135px;
     font-size: 22px;
     line-height: 26px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
     font-size: 11px;
     line-height: 11px;
     margin-left: 25px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 82%;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
     width: 34px;
     height: 42px;
     font-size: 12px;
     line-height: 13px;
     padding: 5px 0 0 0;
     right: 10px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
     font-size: 16px;
     line-height: 16px;
   }
 }
 @media (max-width: 870px) {
   .ttcalendar-layout01 .tt-day-header > * {
     font-size: 15px;
     line-height: 25px;
     height: 38px;
   }
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 105px;
     font-size: 22px;
     line-height: 26px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
     font-size: 11px;
     line-height: 11px;
     margin-left: 25px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 82%;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
     width: 30px;
     height: 38px;
     font-size: 11px;
     line-height: 12px;
     padding: 5px 0 0 0;
     right: 5px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
     font-size: 14px;
     line-height: 14px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg > * {
     padding: 6px 4px 7px;
     min-width: 79px;
     font-size: 12px;
   }
 }
 @media (max-width: 670px) {
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 190px;
     font-size: 18px;
     line-height: 22px;
     width: calc(33.333% - 10px);
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label .tt-day-event__label__text {
     font-size: 9px;
     line-height: 9px;
     margin-left: 25px;
     margin-top: -3px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__label img {
     max-width: 80%;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time {
     width: 28px;
     height: 36px;
     font-size: 11px;
     line-height: 12px;
     padding: 3px 0 0 0;
     right: 5px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__time strong {
     font-size: 13px;
     line-height: 13px;
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg > * {
     padding: 8px 7px 9px;
     min-width: 95px;
     font-size: 13px;
     margin: 10px 0;
   }
   .ttcalendar-layout01 .tt-day-header {
     display: none;
   }
 }
 @media (max-width: 575px) {
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 160px;
     font-size: 18px;
     line-height: 22px;
     width: calc(33.333% - 10px);
   }
 }
 @media (max-width: 450px) {
   .ttcalendar-layout01 .tt-day-grid > * {
     height: 120px;
     font-size: 18px;
     line-height: 22px;
     width: calc(33.333% - 10px);
   }
   .ttcalendar-layout01 .tt-day-grid .tt-day-event__bg > * {
     padding: 6px 4px 7px;
     min-width: 79px;
     font-size: 12px;
     margin: 7px 0;
   }
 }
 
 @media (max-width: 1024px) {
   html.touch-device .ttcalendar-layout01 .tt-day-grid > *:not(.active) .tt-day-event__bg > * {
     pointer-events: none;
   }
 }
 /*
     tickets.html
 */
 .tickets-wide__item {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: center;
   align-items: center;
   background-color: #ffffff;
 }
 .tickets-wide__item .tickets-wide__description {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   flex: 2 1 auto;
   padding: 20px 50px;
 }
 .tickets-wide__item .tickets-wide__description .tt-col:not(:first-child) {
   margin-left: 25px;
 }
 .tickets-wide__item .tickets-wide__description .tickets-wide__label {
   display: block;
   background: url("../images/label-01.png") 0 0 no-repeat;
   width: 63px;
   height: 73px;
   padding-top: 7px;
   color: #ffffff;
   font-size: 15px;
   line-height: 16px;
   text-align: center;
   background-size: cover;
   font-family: "Poppins", sans-serif;
 }
 .tickets-wide__item .tickets-wide__description .tickets-wide__label span {
   display: block;
   font-size: 26px;
   line-height: 30px;
   font-weight: bold;
 }
 .tickets-wide__item .tickets-wide__description .tickets-wide__data {
   font-size: 15px;
   line-height: 25px;
   letter-spacing: 0.2em;
   color: #5C5C5C;
   text-transform: uppercase;
 }
 .tickets-wide__item .tickets-wide__description .tickets-wide__title {
   color: #3D3B42;
   font-size: 36px;
   line-height: 47px;
   letter-spacing: -0.03em;
   font-weight: bold;
   margin: 4px 0 0 0;
   padding: 0;
 }
 .tickets-wide__item .tickets-wide__btn > * {
   margin-right: 50px;
 }
 .tickets-wide__item .tickets-wide__btn > *:not(:first-child) {
   margin-top: 20px;
 }
 .tickets-wide__item .tickets-wide__btn > * span {
   min-width: 157px;
 }
 @media (max-width: 1239px) {
   .tickets-wide__item .tickets-wide__description .tickets-wide__title {
     font-size: 30px;
     line-height: 40px;
   }
 }
 @media (max-width: 1024px) {
   .tickets-wide__item .tickets-wide__description {
     padding: 15px 30px;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__label {
     width: 50px;
     height: 60px;
     padding-top: 7px;
     font-size: 14px;
     line-height: 15px;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__label span {
     font-size: 22px;
     line-height: 26px;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__data {
     font-size: 14px;
     line-height: 24px;
     letter-spacing: 0.1em;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__title {
     font-size: 28px;
     line-height: 38px;
   }
   .tickets-wide__item .tickets-wide__btn > * {
     margin-right: 30px;
   }
   .tickets-wide__item .tickets-wide__btn > * span {
     min-width: 140px;
   }
 }
 @media (max-width: 767px) {
   .tickets-wide__item .tickets-wide__description .tt-col:first-child {
     display: none;
   }
   .tickets-wide__item .tickets-wide__description .tt-col:not(:first-child) {
     margin-left: 0;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__data {
     font-size: 12px;
     line-height: 22px;
     letter-spacing: 0.1em;
   }
   .tickets-wide__item .tickets-wide__description .tickets-wide__title {
     font-size: 22px;
     line-height: 28px;
   }
 }
 @media (max-width: 576px) {
   .tickets-wide__item {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
     align-content: flex-start;
     align-items: flex-start;
     padding: 30px 20px;
   }
   .tickets-wide__item .tickets-wide__description {
     display: block;
     text-align: center;
   }
   .tickets-wide__item .tickets-wide__btn {
     margin-left: -15px;
     margin-top: -10px;
   }
   .tickets-wide__item .tickets-wide__btn > * {
     margin: 10px 0 0 15px;
   }
 }
 
 /*
     Tickets Col Wrapper (tickets-02.html)
 */
 .tickets-wide:not(:first-child) {
   margin-top: 20px;
 }
 
 .js-tickets-col {
   position: relative;
   z-index: 1;
 }
 .js-tickets-col .tickets-wide__season {
   text-align: center;
   color: #3D3B42;
   font-size: 24px;
   line-height: 20px;
   font-weight: bold;
   padding-bottom: 29px;
 }
 
 @media (min-width: 1025px) {
   .tickets-col-wrapper {
     margin-top: -36px;
   }
   .tickets-col-wrapper .tickets-col {
     margin-top: 36px;
   }
 }
 @media (max-width: 1024px) {
   .tickets-col-wrapper {
     margin-top: -27px;
   }
   .tickets-col-wrapper .tickets-col {
     margin-top: 27px;
   }
 }
 @media (max-width: 575px) {
   .tickets-col-wrapper {
     margin-top: -23px;
   }
   .tickets-col-wrapper .tickets-col {
     margin-top: 23px;
   }
 }
 
 .tickets-col .tickets-col__img {
   position: relative;
   overflow: hidden;
 }
 .tickets-col .tickets-col__img img {
   width: 100%;
   height: auto;
 }
 .tickets-col .tickets-col__img .tickets-col__label {
   display: block;
   background: url("../images/label-01.png") 0 0 no-repeat;
   position: absolute;
   background-position: center bottom;
   right: 23px;
   top: 0px;
   width: 61px;
   height: 71px;
   color: #ffffff;
   text-align: center;
   font-size: 15px;
   line-height: 20px;
   padding: 6px 0 0 0;
   background-size: cover;
 }
 .tickets-col .tickets-col__img .tickets-col__label strong {
   display: block;
   font-size: 26px;
   line-height: 26px;
 }
 .tickets-col .tickets-col__img .tickets-col__btn {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: flex-start;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   background: rgba(61, 59, 66, 0.9);
   font-family: "Poppins", sans-serif;
   padding: 13px 13px 20px;
   opacity: 0;
   transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1) 50ms;
 }
 .tickets-col .tickets-col__img .tickets-col__btn > * {
   display: inline-block;
   margin-top: 7px;
   margin-left: 7px;
   margin-right: 7px;
 }
 .tickets-col .tickets-col__description {
   margin-top: 20px;
   color: #ffffff;
 }
 .tickets-col .tickets-col__data {
   font-size: 15px;
   line-height: 35px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
   color: #ffffff;
 }
 .tickets-col .tickets-col__title {
   font-size: 36px;
   font-weight: 500;
   color: #ffffff;
   letter-spacing: -0.03em;
   font-family: "Poppins", sans-serif;
   margin: 6px 0 0 0;
   padding: 0 0 17px 0;
 }
 @media (max-width: 1239px) {
   .tickets-col .tickets-col__img .tickets-col__btn > * {
     padding-left: 15px;
     padding-right: 15px;
   }
 }
 @media (max-width: 1024px) {
   .tickets-col .tickets-col__img .tickets-col__label {
     width: 51px;
     height: 63px;
     font-size: 13px;
     right: 15px;
   }
   .tickets-col .tickets-col__img .tickets-col__label strong {
     font-size: 21px;
     line-height: 21px;
   }
   .tickets-col .tickets-col__data {
     font-size: 14px;
     line-height: 24px;
   }
   .tickets-col .tickets-col__title {
     font-size: 28px;
     padding-bottom: 6px;
   }
 }
 @media (max-width: 575px) {
   .tickets-col {
     max-width: 265px;
     margin-left: auto;
     margin-right: auto;
   }
   .tickets-col .tickets-col__description {
     margin-top: 10px;
   }
   .tickets-col .tickets-col__data {
     font-size: 13px;
     line-height: 23px;
     letter-spacing: 0.1em;
   }
   .tickets-col .tickets-col__title {
     font-size: 24px;
     padding-bottom: 3px;
     margin-top: 2px;
   }
 }
 
 html:not(.touch-device) .tickets-col:hover .tickets-col__img .tickets-col__btn {
   opacity: 1;
 }
 html.touch-device .tickets-col.active .tickets-col__img .tickets-col__btn {
   opacity: 1;
 }
 
 /*---------------------------------------*/
 /*--------       7. Gallery       ------*/
 /*--------------------------------------*/
 /*
     Gallery page - Filter Tabs
 */
 #filter-nav {
   position: relative;
 }
 #filter-nav ul {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   margin-top: -10px;
   padding: 0 0 63px 0;
 }
 #filter-nav ul li > a {
   color: #ffffff;
   font-size: 19px;
   line-height: 16px;
   font-weight: bold;
   display: inline-block;
   padding: 10px 18px;
 }
 #filter-nav ul li > a:hover {
   color: #3D3B42;
 }
 @media (min-width: 791px) {
   #filter-nav ul li:before, #filter-nav ul li:after {
     content: "";
     width: 30px;
     height: 1px;
     background-color: #d8d8da;
     display: inline-block;
     position: relative;
     top: -6px;
   }
   #filter-nav ul li:first-child:before {
     display: none;
   }
   #filter-nav ul li:last-child:after {
     display: none;
   }
 }
 @media (max-width: 790px) {
   #filter-nav ul li > a {
     font-size: 18px;
     padding-left: 13px;
     padding-right: 13px;
   }
   #filter-nav ul li:before, #filter-nav ul li:after {
     width: 20px;
   }
 }
 @media (max-width: 575px) {
   #filter-nav ul {
     padding-bottom: 25px;
   }
   #filter-nav ul li > a {
     font-size: 16px;
     padding-left: 13px;
     padding-right: 13px;
   }
   #filter-nav ul li:before, #filter-nav ul li:after {
     width: 20px;
   }
 }
 
 #tt-filternav__line {
   display: block;
   position: absolute;
   height: 4px;
   width: 50px;
   top: 29px;
   left: 22px;
   width: 41px;
   background: linear-gradient(270deg, #FF1A43 -0.23%, #7B16D9 100%);
   border-radius: 10px;
   opacity: 1;
   transition: width 0.3s linear, left 0.3s linear, opacity 0.7s ease-in-out;
   margin-top: 15px;
   opacity: 0;
 }
 @media (max-width: 790px) {
   #tt-filternav__line {
     height: 3px;
   }
 }
 
 #filter-layout > *:not(.show) {
   display: none;
 }
 
 #js-more-include {
   padding: 0;
   display: block !important;
 }
 
 /*
     Gallery Inner layout
 */
 .gallery-innerlayout-wrapper {
   margin-left: -10px;
   margin-right: -10px;
 }
 .gallery-innerlayout-wrapper [class^=col-] {
   padding-left: 10px;
   padding-right: 10px;
   padding-top: 20px;
 }
 @media (min-width: 1025px) {
   .gallery-innerlayout-wrapper {
     padding-left: 55px;
     padding-right: 55px;
   }
 }
 @media (max-width: 1024px) {
   .gallery-innerlayout-wrapper {
     padding-left: 5px;
     padding-right: 5px;
   }
 }
 
 .gallery-innerlayout {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   position: relative;
   overflow: hidden;
 }
 .gallery-innerlayout .gallery__img {
   position: absolute;
   z-index: 1;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-size: cover;
   background-position: center center;
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
   background-color: #252427;
 }
 .gallery-innerlayout > *:not(.gallery__img) {
   position: relative;
   z-index: 2;
 }
 .gallery-innerlayout .gallery__time {
   font-size: 15px;
   line-height: 25px;
   color: #ffffff;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }
 .gallery-innerlayout .gallery__title {
   color: #ffffff;
   font-weight: 500;
   font-size: 36px;
   line-height: 38px;
   font-family: "Poppins", sans-serif;
   margin-top: 10px;
 }
 .gallery-innerlayout .gallery__video {
   width: 93px;
   height: 93px;
   background-color: #FF1A43;
   border-radius: 50%;
   position: relative;
   pointer-events: none;
 }
 .gallery-innerlayout .gallery__video:before {
   content: "";
   display: inline-block;
   border: 11px solid transparent;
   border-left: 17px solid #ffffff;
   margin-right: 3px;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-5px, -10px);
   transform: translate(-5px, -10px);
 }
 .gallery-innerlayout:hover .gallery__img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 @media (min-width: 1660px) {
   .gallery-innerlayout {
     height: 505px;
   }
 }
 @media (max-width: 1659px) {
   .gallery-innerlayout {
     height: 400px;
   }
 }
 @media (max-width: 1400px) {
   .gallery-innerlayout {
     height: 290px;
   }
   .gallery-innerlayout .gallery__time {
     font-size: 14px;
     line-height: 24px;
     letter-spacing: 0.13em;
   }
   .gallery-innerlayout .gallery__title {
     font-size: 30px;
     line-height: 32px;
   }
 }
 @media (max-width: 1024px) {
   .gallery-innerlayout {
     height: 270px;
   }
   .gallery-innerlayout .gallery__time {
     font-size: 13px;
     line-height: 23px;
     letter-spacing: 0.13em;
   }
   .gallery-innerlayout .gallery__title {
     font-size: 26px;
     line-height: 28px;
     margin-top: 7px;
   }
   .gallery-innerlayout .gallery__video {
     width: 79px;
     height: 79px;
   }
   .gallery-innerlayout .gallery__video:before {
     border-width: 7px;
     border-left-width: 13px;
     -webkit-transform: translate(-5px, -6px);
     transform: translate(-5px, -6px);
   }
 }
 @media (max-width: 790px) {
   .gallery-innerlayout {
     height: 210px;
   }
   .gallery-innerlayout .gallery__time {
     font-size: 12px;
     line-height: 20px;
     letter-spacing: 0.1em;
   }
   .gallery-innerlayout .gallery__title {
     font-size: 22px;
     line-height: 24px;
     margin-top: 4px;
   }
   .gallery-innerlayout .gallery__video {
     width: 53px;
     height: 53px;
   }
   .gallery-innerlayout .gallery__video:before {
     border-width: 6px;
     border-left-width: 10px;
     -webkit-transform: translate(-3px, -6px);
     transform: translate(-3px, -6px);
   }
 }
 @media (max-width: 575px) {
   .gallery-innerlayout {
     height: 200px;
   }
 }
 
 /*
     Gallery Inner layout
 */
 .gallery-externallayout-wrapper {
   margin-left: -10px;
   margin-right: -10px;
 }
 .gallery-externallayout-wrapper [class^=col-] {
   padding-left: 10px;
   padding-right: 10px;
 }
 @media (min-width: 1025px) {
   .gallery-externallayout-wrapper {
     padding-left: 55px;
     padding-right: 55px;
     margin-top: -43px;
   }
   .gallery-externallayout-wrapper [class^=col-] {
     padding-top: 43px;
   }
 }
 @media (max-width: 1024px) {
   .gallery-externallayout-wrapper {
     padding-left: 5px;
     padding-right: 5px;
     margin-top: -33px;
   }
   .gallery-externallayout-wrapper [class^=col-] {
     padding-top: 33px;
   }
 }
 
 .gallery-externallayout {
   text-align: center;
   display: block;
 }
 .gallery-externallayout .gallery__img {
   overflow: hidden;
   position: relative;
 }
 .gallery-externallayout .gallery__img img {
   width: 100%;
   height: auto;
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
 }
 .gallery-externallayout .gallery__time {
   font-size: 15px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
   color: #5C5C5C;
   margin-top: 23px;
 }
 .gallery-externallayout .gallery__title {
   font-size: 36px;
   line-height: 38px;
   font-weight: 500;
   color: #3D3B42;
   margin-top: 10px;
   font-family: "Poppins", sans-serif;
 }
 .gallery-externallayout .gallery__video {
   width: 93px;
   height: 93px;
   background-color: #FF1A43;
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   pointer-events: none;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
 }
 .gallery-externallayout .gallery__video:before {
   content: "";
   display: inline-block;
   border: 11px solid transparent;
   border-left: 17px solid #ffffff;
   margin-right: 3px;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-5px, -10px);
   transform: translate(-5px, -10px);
 }
 .gallery-externallayout:hover .gallery__img img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 @media (max-width: 1024px) {
   .gallery-externallayout .gallery__time {
     font-size: 14px;
   }
   .gallery-externallayout .gallery__title {
     font-size: 30px;
     line-height: 32px;
     margin-top: 5px;
   }
   .gallery-externallayout .gallery__video {
     width: 79px;
     height: 79px;
   }
   .gallery-externallayout .gallery__video:before {
     border-width: 7px;
     border-left-width: 13px;
     -webkit-transform: translate(-5px, -6px);
     transform: translate(-5px, -6px);
   }
 }
 @media (max-width: 790px) {
   .gallery-externallayout .gallery__time {
     font-size: 14px;
     letter-spacing: 0.2em;
   }
   .gallery-externallayout .gallery__title {
     font-size: 24px;
     line-height: 26px;
     margin-top: 5px;
   }
   .gallery-externallayout .gallery__video {
     width: 53px;
     height: 53px;
   }
   .gallery-externallayout .gallery__video:before {
     border-width: 6px;
     border-left-width: 10px;
     -webkit-transform: translate(-3px, -6px);
     transform: translate(-3px, -6px);
   }
 }
 
 /*---------------------------------------*/
 /*--------       8. News       ------*/
 /*--------------------------------------*/
 /*
     News List
 */
 @media (min-width: 1025px) {
   .tt-news-list {
     margin-top: -43px;
   }
   .tt-news-list [class^=col-] {
     margin-top: 43px;
   }
 }
 @media (max-width: 1024px) {
   .tt-news-list {
     margin-top: -33px;
   }
   .tt-news-list [class^=col-] {
     margin-top: 33px;
   }
 }
 @media (max-width: 575px) {
   .tt-news-list {
     margin-top: -23px;
   }
   .tt-news-list [class^=col-] {
     margin-top: 23px;
   }
 }
 
 .tt-news {
   text-align: center;
   text-decoration: none;
   display: block;
 }
 .tt-news .tt-news__img {
   position: relative;
   overflow: hidden;
 }
 .tt-news .tt-news__img img {
   width: 100%;
   height: auto;
   transition: -webkit-transform 0.5s;
   transition: transform 0.5s;
   transition: transform 0.5s, -webkit-transform 0.5s;
 }
 .tt-news .tt-news__img .tt-btn {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
 }
 .tt-news .tt-news__time {
   font-size: 15px;
   line-height: 35px;
   letter-spacing: 0.2em;
   color: #5C5C5C;
   text-transform: uppercase;
   margin-top: 19px;
 }
 .tt-news .tt-news__title {
   font-family: "Poppins", sans-serif;
   font-weight: 500;
   color: #3D3B42;
   font-size: 36px;
   line-height: 38px;
   margin: 12px 0 0 0;
   padding: 0;
   letter-spacing: 0;
 }
 .tt-news:hover .tt-news__img img {
   -webkit-transform: scale3d(1.1, 1.1, 1);
   transform: scale3d(1.1, 1.1, 1);
 }
 @media (max-width: 1024px) {
   .tt-news .tt-news__title {
     font-size: 28px;
     line-height: 32px;
     margin: 8px 0 0 0;
   }
 }
 @media (max-width: 790px) {
   .tt-news .tt-news__time {
     font-size: 14px;
     line-height: 34px;
     letter-spacing: 0.1em;
   }
   .tt-news .tt-news__title {
     font-size: 24px;
     line-height: 28px;
     margin: 4px 0 0 0;
   }
 }
 @media (max-width: 575px) {
   .tt-news .tt-news__time {
     font-size: 13px;
     line-height: 34px;
     letter-spacing: 0.1em;
   }
   .tt-news .tt-news__title {
     font-size: 22px;
     line-height: 26px;
     margin: 0px 0 0 0;
   }
 }
 
 /*
     Block Aside
 */
 .tt-block-aside .tt-aside-title {
   font-size: 24px;
   line-height: 31px;
   letter-spacing: -0.03em;
   color: #3D3B42;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
   margin: 0;
   padding: 0 0 19px 0;
 }
 .tt-block-aside + .tt-block-aside {
   margin-top: 44px;
 }
 
 .form-default {
   margin: 0;
 }
 .form-default .tt-form-search {
   position: relative;
 }
 .form-default .tt-form-search button[type=submit].tt-btn-icon {
   position: absolute;
   right: 0;
   top: 0;
   margin: 0;
   padding: 0;
   height: 51px;
   width: 50px;
   background: none;
   box-shadow: none;
   border: none;
   color: #FF1A43;
   cursor: pointer;
 }
 .form-default .tt-form-search .form-control:not(textarea) {
   height: 51px;
 }
 
 .tt-aside-post .tt-item {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .tt-aside-post .tt-item .tt-item__img {
   width: 80px;
   min-width: 80px;
 }
 .tt-aside-post .tt-item .tt-item__img img {
   width: 100%;
   height: auto;
 }
 .tt-aside-post .tt-item .tt-item__description {
   padding: 0 0 0 20px;
 }
 .tt-aside-post .tt-item .tt-item__data {
   font-size: 13px;
   line-height: 20px;
   color: #5C5C5C;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }
 .tt-aside-post .tt-item .tt-item__title {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 18px;
   line-height: 24px;
   letter-spacing: -0.03em;
   color: #3B4757;
   margin: 7px 0 0 0;
 }
 .tt-aside-post .tt-item .tt-item__title a {
   color: #3B4757;
   text-decoration: none;
   transition: color 0.2s ease-in-out;
 }
 .tt-aside-post .tt-item .tt-item__title a:hover {
   color: #FF1A43;
 }
 .tt-aside-post .tt-item:not(:last-child) {
   border-bottom: 1px solid #ececee;
   padding-bottom: 15px;
   margin-bottom: 15px;
 }
 @media (max-width: 1239px) {
   .tt-aside-post .tt-item .tt-item__img {
     width: 75px;
     min-width: 75px;
   }
   .tt-aside-post .tt-item .tt-item__img img {
     width: 100%;
     height: auto;
   }
   .tt-aside-post .tt-item .tt-item__description {
     padding-left: 15px;
   }
   .tt-aside-post .tt-item .tt-item__data {
     font-size: 12px;
     line-height: 18px;
     letter-spacing: 0.01em;
   }
   .tt-aside-post .tt-item .tt-item__title {
     font-size: 16px;
     line-height: 22px;
   }
 }
 
 .tt-block-aside .datepicker-here {
   margin-top: 5px;
 }
 
 .datepicker-here .datepicker-inline .datepicker {
   border: none;
   margin-left: 0;
   margin-right: 0;
   width: 100%;
 }
 .datepicker-here .datepicker--content {
   padding: 0;
 }
 .datepicker-here .datepicker--nav-action path {
   stroke: #888888;
 }
 .datepicker-here .datepicker--nav-action:hover path {
   stroke: #FF1A43;
 }
 .datepicker-here .datepicker--nav {
   border: none;
   padding: 0 0 8px 0;
 }
 .datepicker-here .datepicker--nav .datepicker--nav-action {
   position: absolute;
   background: transparent;
 }
 .datepicker-here .datepicker--nav .datepicker--nav-action:first-child {
   right: 32px;
 }
 .datepicker-here .datepicker--nav .datepicker--nav-action:last-child {
   right: 0;
 }
 .datepicker-here .datepicker--nav .datepicker--nav-title {
   font-size: 17px;
   line-height: 27px;
   color: #888888;
   padding-left: 0;
 }
 .datepicker-here .datepicker--days-names {
   background: #343337;
   color: #ffffff;
   margin: 0;
 }
 .datepicker-here .datepicker--day-name {
   font-size: 16px;
   color: #ffffff;
   padding: 8px 0;
 }
 .datepicker-here .datepicker--cells .datepicker--cell {
   background: #f3f3f3;
   border-radius: 0;
   border: 1px solid #ffffff;
   font-size: 15px;
   height: 40px;
 }
 .datepicker-here .datepicker--cell-day.-other-month-,
 .datepicker-here .datepicker--cell-year.-other-decade- {
   background: #fbfbfb;
   color: transparent;
 }
 .datepicker-here .datepicker--cell.-current- {
   color: #ffffff;
   background: #FF1A43;
 }
 @media (max-width: 1239px) {
   .datepicker-here .datepicker--day-name {
     font-size: 14px;
   }
   .datepicker-here .datepicker--cells .datepicker--cell {
     font-size: 13px;
     height: 37px;
   }
 }
 
 .tt-comments-layout {
   margin-top: 27px;
 }
 @media (min-width: 1240px) {
   .tt-comments-layout {
     margin-right: 22px;
   }
 }
 .tt-comments-layout .tt-comments-layout__title {
   font-size: 24px;
   line-height: 31px;
   letter-spacing: -0.03em;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
   color: #3D3B42;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: flex-start;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-avatar {
   width: 80px;
   min-width: 80px;
   height: 80px;
   position: relative;
   margin-right: 26px;
   margin-top: 4px;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-avatar img {
   position: relative;
   z-index: 2;
   max-width: 100%;
   height: auto;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-avatar:empty:before {
   font-family: "club";
   content: "\e919";
   font-size: 24px;
   line-height: 73px;
   display: block;
   text-align: center;
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 0;
   color: #ffffff;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-avatar:empty:after {
   content: "";
   background-color: #F3F3F3;
   width: 100%;
   height: 100%;
   min-width: 59px;
   display: block;
   top: 0;
   left: 0;
   z-index: 0;
   border-radius: 50%;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content {
   position: relative;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-btn-default {
   position: absolute;
   right: 0;
   top: 2px;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-comments-title {
   padding-bottom: 13px;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-comments-title .username {
   display: block;
   font-weight: bold;
   font-size: 18px;
   line-height: 36px;
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-comments-title .username span {
   color: #3D3B42;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-comments-title .time {
   font-size: 15px;
   line-height: 18px;
   color: #5C5C5C;
   margin-top: 3px;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content .tt-comments-title .time .tt-icon {
   display: inline-block;
   margin-right: 7px;
   color: #FF1A43;
   font-size: 14px;
   font-weight: bold;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content p {
   margin-top: 7px;
 }
 .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content > *:nth-child(1) {
   margin-top: 0;
 }
 .tt-comments-layout .tt-item:not(:last-child) {
   padding-bottom: 21px;
   border-bottom: 1px solid #d8d8da;
 }
 .tt-comments-layout .tt-item {
   margin-top: 24px;
 }
 .tt-comments-layout .tt-comments-level-2 {
   margin-left: 35px;
   margin-top: 33px;
   padding: 33px 0 40px 0;
   border-top: 1px solid #d8d8da;
 }
 @media (max-width: 790px) {
   .tt-comments-layout .tt-comments-layout__title {
     font-size: 20px;
     line-height: 27px;
   }
 }
 @media (max-width: 575px) {
   .tt-comments-layout .tt-item div[class^=tt-comments-level-] {
     flex-direction: column;
   }
   .tt-comments-layout .tt-item div[class^=tt-comments-level-] .tt-content {
     margin-top: 10px;
   }
   .tt-comments-layout .tt-item .tt-comments-level-2 {
     margin-top: 35px;
   }
   .tt-comments-layout .tt-item:not(:first-child) {
     margin-top: 10px;
   }
   .tt-comments-layout .tt-item:not(:last-child) {
     padding-bottom: 9px;
   }
   .tt-comments-layout .tt-comments-level-2 {
     margin-top: 2px;
     margin-left: 20px;
   }
 }
 
 .news-single {
   box-shadow: 0px 0px 20px rgba(52, 51, 55, 0.2);
 }
 @media (min-width: 1240px) {
   .news-single {
     margin-right: 22px;
   }
 }
 .news-single .news-single__layout {
   padding: 47px 50px 50px;
 }
 .news-single .news-single__img img {
   width: 100%;
   height: auto;
 }
 .news-single .news-single__data {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   margin-left: -46px;
 }
 .news-single .news-single__data .tt-col {
   margin-left: 46px;
 }
 .news-single .news-single__time {
   font-size: 15px;
   line-height: 25px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }
 .news-single .news-single__link {
   color: #5C5C5C;
   font-size: 15px;
   line-height: 24px;
 }
 .news-single .news-single__link .tt-icon {
   display: inline-block;
   margin-right: 7px;
   position: relative;
   top: 1px;
 }
 .news-single .news-single__title {
   font-family: "Poppins", sans-serif;
   font-size: 36px;
   line-height: 47px;
   letter-spacing: -0.03em;
   font-weight: bold;
   color: #3D3B42;
   padding: 0 0 12px 0;
   margin: 12px 0 0 0;
 }
 .news-single .news-single__title + p {
   margin-top: 0;
 }
 .news-single .news-single__subtitle {
   font-family: "Poppins", sans-serif;
   color: #3D3B42;
   font-weight: bold;
   font-size: 18px;
   line-height: 26px;
   margin: 0;
   padding: 0 0 15px 0;
 }
 .news-single .news-single__subtitle + p {
   margin-top: 0;
 }
 .news-single .news-single__subtitle.subtitle-top {
   margin-top: 35px;
 }
 @media (max-width: 1239px) {
   .news-single .news-single__layout {
     padding: 47px 30px 30px;
   }
   .news-single .news-single__time {
     font-size: 13px;
     line-height: 23px;
     letter-spacing: 0.2em;
   }
   .news-single .news-single__link {
     font-size: 13px;
     line-height: 22px;
   }
   .news-single .news-single__link .tt-icon {
     margin-right: 7px;
     top: 1px;
   }
   .news-single .news-single__title {
     font-size: 30px;
     line-height: 40px;
     letter-spacing: -0.03em;
     padding: 0 0 12px 0;
     margin: 12px 0 0 0;
   }
 }
 @media (max-width: 790px) {
   .news-single .news-single__title {
     font-size: 26px;
     line-height: 36px;
     letter-spacing: -0.03em;
     padding: 0 0 12px 0;
     margin: 12px 0 0 0;
   }
 }
 
 .news-single__boximg {
   margin-top: 37px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .news-single__boximg picture {
   display: block;
   margin-top: 8px;
 }
 .news-single__boximg .tt-img-layout {
   padding: 0 0 0 0px;
 }
 .news-single__boximg .tt-img-layout p:first-child {
   margin-top: 0;
 }
 @media (min-width: 791px) {
   .news-single__boximg .tt-item-img {
     flex: 0 0 50%;
     max-width: 50%;
     padding-right: 30px;
   }
   .news-single__boximg .tt-img-layout {
     flex: 0 0 50%;
     max-width: 50%;
   }
 }
 @media (max-width: 790px) {
   .news-single__boximg {
     flex-wrap: wrap;
   }
   .news-single__boximg .tt-item-img {
     width: 100%;
   }
   .news-single__boximg .tt-img-layout {
     width: 100%;
     margin-top: 16px;
   }
 }
 
 .tt-blockquote {
   position: relative;
   padding: 6px 0 3px 32px;
   margin: 0;
   font-size: 17px;
   line-height: 27px;
   color: #888888;
   font-style: italic;
 }
 .tt-blockquote:before {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 4px;
   height: 100%;
   border-radius: 10px;
   background: linear-gradient(270deg, #FF6600 0%, #7B16D9 100%);
 }
 .tt-blockquote.tt-blockquote-top {
   margin-top: 38px;
 }
 
 .news-single__meta {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-content: center;
   align-items: center;
 }
 .news-single__meta .news-single__meta__label {
   display: inline-block;
   margin-right: 15px;
   font-weight: bold;
   font-size: 18px;
   line-height: 36px;
   color: #3D3B42;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
 }
 .news-single__meta .tt-list-box {
   display: inline-block;
 }
 @media (max-width: 1024px) {
   .news-single__meta {
     flex-wrap: wrap;
   }
   .news-single__meta .tt-col:not(:first-child) {
     margin-top: 15px;
   }
 }
 
 #tt-pageContent .personal-box {
   /* background: url("../images/news/personal-box01__bg.jpg") 0 0 no-repeat; */
   background-size: cover;
   padding: 40px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: center;
   align-items: center;
   position: relative;
 }
 #tt-pageContent .personal-box:before {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 4px;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   -webkit-transform: rotate(-180deg);
   transform: rotate(-180deg);
 }
 #tt-pageContent .personal-box .personal-box__img {
   width: 131px;
   min-width: 131px;
   height: 131px;
   border-radius: 50%;
   overflow: hidden;
 }
 #tt-pageContent .personal-box .personal-box_description {
   color: #D3D3D5;
   padding: 0 0 0 30px;
 }
 #tt-pageContent .personal-box .personal-box__title {
   font-weight: bold;
   font-size: 18px;
   line-height: 36px;
   font-family: "Poppins", sans-serif;
   color: #ffffff;
   margin: 0;
   padding: 0 0 10px 0;
 }
 #tt-pageContent .personal-box .personal-box__title + p {
   margin-top: 0;
 }
 #tt-pageContent .personal-box.personal-box__top {
   margin-top: 50px;
 }
 #tt-pageContent .personal-box .tt-social-icon {
   margin-top: 19px;
 }
 #tt-pageContent .personal-box .tt-social-icon li a:not(:hover) {
   color: #ffffff;
 }
 @media (max-width: 1239px) {
   #tt-pageContent .personal-box {
     padding: 20px;
   }
   #tt-pageContent .personal-box .personal-box__img {
     width: 90px;
     min-width: 90px;
     height: 90px;
   }
   #tt-pageContent .personal-box .personal-box_description {
     padding-left: 20px;
   }
 }
 
 .form-single-post {
   margin-top: 24px;
 }
 .form-single-post .tt-form-title {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 24px;
   line-height: 31px;
   letter-spacing: -0.03em;
   color: #3D3B42;
   margin: 0;
   padding: 0 0 26px 0;
 }
 .form-single-post form {
   max-width: 652px;
 }
 @media (max-width: 790px) {
   .form-single-post .tt-form-title {
     font-size: 20px;
     line-height: 27px;
   }
 }
 
 .rightColumn {
   max-width: 350px;
 }
 
 /*---------------------------------------*/
 /*--------       9. Form         ------*/
 /*--------------------------------------*/
 /*
     form
 */
 /*Custom select Header*/
 .tt-select {
   opacity: 0;
 }
 
 .tt-skinSelect-02 .SumoSelect {
   cursor: pointer;
   font-size: 16px;
   line-height: 17px;
   letter-spacing: -0.01em;
   font-weight: bold;
   width: 38px;
   font-family: "Poppins", sans-serif;
   position: relative;
   top: -1px;
 }
 .tt-skinSelect-02 .SumoSelect > .optWrapper {
   border-radius: 0;
   border: none;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont {
   padding: 8px 12px 8px 2px;
   background: transparent;
   display: inline-flex;
   outline: none;
   border: none;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont > span.placeholder,
 .tt-skinSelect-02 .SumoSelect > .CaptionCont > span {
   font-style: normal;
   color: #ffffff;
   transition: all 0.2s ease-in-out;
   cursor: pointer;
   text-transform: uppercase;
   padding-right: 16px;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont > label {
   cursor: pointer;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont > label > i {
   display: none;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont > label:after {
   content: "\e907";
   font-family: "club";
   color: #ffffff;
   display: block;
   pointer-events: none;
   position: absolute;
   right: 17px;
   top: 7px;
   font-weight: normal;
   font-size: 5px;
   transition: color 0.15s ease-in-out;
 }
 .tt-skinSelect-02 .SumoSelect > .CaptionCont, .tt-skinSelect-02 .SumoSelect:focus > .CaptionCont {
   transition: border 0.2s ease-in-out;
   box-shadow: none;
   border: none;
   outline: none;
 }
 .tt-skinSelect-02 .SumoSelect:hover > .CaptionCont, .tt-skinSelect-02 .SumoSelect.open > .CaptionCont {
   box-shadow: none;
   border: none;
   outline: none;
 }
 .tt-skinSelect-02 .SumoSelect:hover > .CaptionCont > span {
   color: #FF1A43;
 }
 .tt-skinSelect-02 .SumoSelect:hover > .CaptionCont > label:after {
   color: #FF1A43;
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper {
   top: 100% !important;
   right: 0;
   min-width: 90px;
   background-color: #ffffff;
   color: #3D3B42;
   padding: 7px 0;
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper:before {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 2px;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper > .options li.opt.disabled {
   font-weight: bold;
   color: #FF1A43;
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper > .options li.opt.disabled * {
   opacity: 1;
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper > .options li.opt {
   text-align: left;
   padding: 2px 14px;
   border-color: transparent;
   transition: background-color 0.2s ease-in-out;
 }
 .tt-skinSelect-02 .SumoSelect .optWrapper > .options li.opt:hover {
   background: transparent;
   color: #FF1A43;
 }
 .tt-skinSelect-02.tt-select__popup-right .SumoSelect > .optWrapper {
   left: auto;
   right: 0;
 }
 
 .tt-skinSelect-01 .SumoSelect > .CaptionCont {
   border-color: rgba(0, 0, 0, 0.15);
   border-radius: 5px;
   position: relative;
   min-height: 41px;
   box-shadow: none;
 }
 .tt-skinSelect-01 .SumoSelect > .CaptionCont > label > i {
   display: none;
 }
 .tt-skinSelect-01 .SumoSelect > .CaptionCont > label:after {
   content: "";
   position: absolute;
   display: block;
   right: 21px;
   top: 19px;
   border: 3px solid transparent;
   border-top: 3px solid #3D3B42;
   transition: color 0.15s ease-in-out;
 }
 .tt-skinSelect-01 .SumoSelect .SelectBox {
   padding: 11px 12px 6px;
   font-family: "Roboto", sans-serif;
   color: #888888;
   font-size: 16px;
   line-height: 19px;
 }
 .tt-skinSelect-01 .SumoSelect > .optWrapper ul {
   padding-top: 10px;
   padding-bottom: 10px;
 }
 .tt-skinSelect-01 .SumoSelect .optWrapper > .options li.opt {
   padding: 1px 12px 1px;
   border-bottom: transparent;
   color: #888888;
   background: transparent;
 }
 .tt-skinSelect-01 .SumoSelect .optWrapper > .options li.opt:hover {
   color: #FF1A43;
 }
 .tt-skinSelect-01 .SumoSelect > .CaptionCont, .tt-skinSelect-01 .SumoSelect:focus > .CaptionCont {
   box-shadow: none;
 }
 .tt-skinSelect-01 .SumoSelect:hover > .CaptionCont, .tt-skinSelect-01 .SumoSelect.open > .CaptionCont {
   box-shadow: none;
   border-color: #FF1A43;
   outline: none;
 }
 
 /*
     form Default
 */
 /* placeholder */
 ::-webkit-input-placeholder {
   color: #888888;
 }
 
 ::-moz-placeholder {
   color: #888888;
 }
 
 :-moz-placeholder {
   color: #888888;
 }
 
 :-ms-input-placeholder {
   color: #888888;
 }
 
 ::-moz-placeholder, :-moz-placeholder {
   opacity: 1;
 }
 
 input:focus::-webkit-input-placeholder {
   color: transparent;
 }
 
 input:focus:-moz-placeholder {
   color: transparent;
 }
 
 input:focus::-moz-placeholder {
   color: transparent;
 }
 
 input:focus:-ms-input-placeholder {
   color: transparent;
 }
 
 textarea:focus::-webkit-input-placeholder {
   color: transparent;
 }
 
 textarea:focus:-moz-placeholder {
   color: transparent;
 }
 
 textarea:focus::-moz-placeholder {
   color: transparent;
 }
 
 textarea:focus:-ms-input-placeholder {
   color: transparent;
 }
 
 /* form-default */
 .form-default .form-group {
   position: relative;
 }
 .form-default .form-group label.error {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: stretch;
   align-items: center;
   position: absolute;
   top: 56px;
   left: 0;
   padding: 8px 12px 6px;
   background: red;
   color: #ffffff;
   font-size: 14px;
   line-height: 22px;
   z-index: 1;
   width: 100%;
 }
 .form-default .form-group label.error:after {
   content: "";
   position: absolute;
   top: -12px;
   left: 50%;
   border: 6px solid transparent;
   border-bottom: 6px solid #f8353e;
 }
 .form-default .pt-disabled {
   pointer-events: none;
   opacity: 0.5;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 .form-default button[type=submit] {
   margin-top: 14px;
 }
 .form-default .form-control {
   background: transparent;
   font-size: 16px;
   line-height: 25px;
   border: 1px solid #dddddd;
   color: #888888;
   padding: 13px 12px 12px 18px;
   box-shadow: none;
   outline: none;
   font-family: "Roboto", sans-serif;
   transition: all 0.3s;
   box-shadow: none;
   border-radius: 5px;
 }
 .form-default .form-control.is-invalid {
   border: 1px solid red;
 }
 .form-default .form-control:not(textarea) {
   height: 48px;
 }
 .form-default input.form-control,
 .form-default textarea.form-control {
   -webkit-appearance: none;
 }
 .form-default textarea.form-control {
   padding-top: 10px;
 }
 .form-default .form-control.error {
   border-color: red;
   box-shadow: none;
 }
 .form-default .form-control:focus {
   border-color: #FF1A43;
   background: transparent;
 }
 .form-default .pt-required {
   display: inline-block;
   float: right;
   position: relative;
   top: 4px;
   color: red;
   padding-left: 5px;
   font-size: 14px;
   line-height: 22px;
 }
 .form-default .invalid-feedback {
   margin-top: 2px;
   font-size: 14px;
   line-height: 24px;
 }
 
 .tt-subtitle + .form-default {
   margin-top: 5px;
 }
 
 /*---------------------------------------*/
 /*--------       10. Modal         ------*/
 /*--------------------------------------*/
 /*
 Modal
 */
 /* open modal mobile menu */
 body:not(.touch-device).mm-open {
   padding-right: 17px;
 }
 body:not(.touch-device).mm-open .tt-stuck-nav.stuck {
   padding-right: 17px;
 }
 
 /* open modal bootstrap */
 .modal-open {
   overflow: hidden;
 }
 
 body:not(.touch-device).modal-open {
   overflow-x: hidden;
   padding-right: 17px;
 }
 body:not(.touch-device).modal-open #tt-stuck.stuck {
   padding-right: 17px;
 }
 body:not(.touch-device).modal-open .tt-on-top {
   padding-right: 17px;
 }
 
 html.ie.gecko body.modal-open {
   padding-right: 0px !important;
 }
 
 /* open left col (*listing) */
 .modal {
   display: none;
   overflow: hidden;
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 11;
   -webkit-overflow-scrolling: touch;
   outline: none;
   text-align: center;
   padding: 0;
   transition: opacity 0.15s;
   opacity: 0;
 }
 
 .modal.show {
   opacity: 1;
 }
 
 @media (min-width: 320px) {
   .modal:before {
     content: "";
     display: inline-block;
     height: 100%;
     vertical-align: middle;
     margin-right: -4px;
   }
 }
 @media (min-width: 731px) {
   .modal-md {
     width: 567px;
   }
 }
 @media (max-width: 730px) {
   .modal-md {
     width: 528px;
   }
 }
 @media (max-width: 580px) {
   .modal-md {
     width: 420px;
   }
 }
 @media (max-width: 480px) {
   .modal-md {
     width: 315px;
   }
 }
 
 @media (min-width: 731px) {
   .modal-lg {
     width: 667px;
   }
 }
 @media (max-width: 730px) {
   .modal-lg {
     width: 528px;
   }
 }
 @media (max-width: 580px) {
   .modal-lg {
     width: 420px;
   }
 }
 @media (max-width: 480px) {
   .modal-lg {
     width: 320px;
   }
 }
 
 /* modal layout */
 .modal {
   display: none;
   overflow: hidden;
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 11;
   -webkit-overflow-scrolling: touch;
   outline: none;
 }
 .modal .modal-content {
   border-radius: 0;
   border: none;
 }
 .modal .modal-footer {
   border: none;
 }
 
 /*
     modal custom
 */
 .modal {
   display: none;
   overflow: hidden;
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 30;
   -webkit-overflow-scrolling: touch;
   outline: none;
 }
 .modal .modal-dialog {
   display: inline-block;
   vertical-align: middle;
 }
 @media (min-width: 576px) {
   .modal .modal-dialog {
     max-width: inherit;
   }
 }
 .modal .modal-body:not(.no-background) {
   background-color: #ffffff;
 }
 .modal .modal-body {
   position: relative;
   text-align: left;
   padding: 34px 56px 40px;
 }
 .modal .modal-body:before {
   content: "";
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   width: 100%;
   height: 4px;
   display: block;
   position: absolute;
   top: 0;
   left: 0;
 }
 .modal .modal-body .close {
   font-size: 21px;
   line-height: 1;
   position: absolute;
   top: 0;
   right: 0;
   display: inline-block;
   z-index: 7;
   padding: 18px 23px;
   color: #3D3B42;
   background: none;
   border: none;
   outline: none;
   cursor: pointer;
   transition: color 0.2s;
 }
 .modal .modal-body .close:hover {
   color: #FF1A43;
 }
 .modal .modal-body .modal-titleblock {
   padding-bottom: 22px;
 }
 .modal .modal-body .modal-titleblock .modal-title {
   font-size: 36px;
   line-height: 38px;
   color: #3D3B42;
   font-weight: bold;
   margin: 0;
   padding: 0;
   text-align: center;
   font-family: "Poppins", sans-serif;
   letter-spacing: -0.03em;
 }
 .modal .modal-body .modal-titleblock .modal-title__label {
   font-weight: 500;
   font-size: 24px;
   line-height: 25px;
   letter-spacing: -0.03em;
   color: #3D3B42;
   margin-top: 5px;
   font-family: "Poppins", sans-serif;
   margin-bottom: -2px;
 }
 @media (min-width: 1025px) {
   .modal .modal-body {
     padding: 34px 56px 40px;
   }
 }
 @media (max-width: 1024px) {
   .modal .modal-body {
     padding: 34px 30px 40px;
   }
 }
 @media (max-width: 580px) {
   .modal .modal-body {
     padding: 34px 23px 40px;
   }
   .modal .modal-body .modal-titleblock {
     padding-bottom: 20px;
   }
   .modal .modal-body .modal-titleblock .modal-title {
     font-size: 27px;
   }
   .modal .modal-body .modal-titleblock .modal-title__label {
     font-size: 20px;
     margin-top: 2px;
   }
 }
 @media (max-width: 480px) {
   .modal .modal-body {
     padding: 38px 20px 40px;
   }
   .modal .modal-body .modal-titleblock {
     padding-bottom: 20px;
   }
   .modal .modal-body .modal-titleblock .modal-title {
     font-size: 22px;
   }
   .modal .modal-body p {
     padding-bottom: 20px;
   }
 }
 .modal#modalBayTickets .modal-body {
   padding: 50px 0;
 }
 
 .modal:before {
   content: "";
   display: inline-block;
   height: 100%;
   vertical-align: middle;
   margin-right: -4px;
 }
 
 .modal-backdrop {
   background: #000000;
 }
 
 .modal-backdrop.show {
   opacity: 0.5;
 }
 
 /*modal-layout-dafault*/
 .modal-layout-dafault ::-webkit-input-placeholder {
   color: #888888;
 }
 .modal-layout-dafault ::-moz-placeholder {
   color: #888888;
 }
 .modal-layout-dafault :-moz-placeholder {
   color: #888888;
 }
 .modal-layout-dafault :-ms-input-placeholder {
   color: #888888;
 }
 .modal-layout-dafault input:not(.tt-search-input):focus::-webkit-input-placeholder {
   color: transparent;
 }
 .modal-layout-dafault input:not(.tt-search-input):focus:-moz-placeholder {
   color: transparent;
 }
 .modal-layout-dafault input:not(.tt-search-input):focus::-moz-placeholder {
   color: transparent;
 }
 .modal-layout-dafault input:not(.tt-search-input):focus:-ms-input-placeholder {
   color: transparent;
 }
 .modal-layout-dafault textarea:focus::-webkit-input-placeholder {
   color: transparent;
 }
 .modal-layout-dafault textarea:focus:-moz-placeholder {
   color: transparent;
 }
 .modal-layout-dafault textarea:focus::-moz-placeholder {
   color: transparent;
 }
 .modal-layout-dafault textarea:focus:-ms-input-placeholder {
   color: transparent;
 }
 .modal-layout-dafault .form-group {
   margin-bottom: 9px;
   position: relative;
 }
 .modal-layout-dafault .form-group > label {
   color: #3D3B42;
   font-size: 16px;
   line-height: 19px;
   display: inline-block;
   padding: 0;
   margin: 0;
   font-family: "Roboto", sans-serif;
 }
 .modal-layout-dafault .form-group .form-group__icon {
   position: absolute;
   right: 10px;
   bottom: 11px;
   display: block;
   pointer-events: none;
   color: #FF1A43;
 }
 .modal-layout-dafault .form-control {
   background: #ffffff;
   color: #888888;
   font-size: 16px;
   line-height: 19px;
   border: 1px solid rgba(0, 0, 0, 0.15);
   box-shadow: none;
   outline: none;
   transition: all 0.2s linear;
   border-radius: 5px;
   padding: 8px 12px 6px;
   font-family: "Roboto", sans-serif;
 }
 .modal-layout-dafault .form-control:not(textarea) {
   height: 41px;
 }
 .modal-layout-dafault input.form-control,
 .modal-layout-dafault textarea.form-control {
   -webkit-appearance: none;
 }
 .modal-layout-dafault textarea {
   min-height: 89px;
 }
 .modal-layout-dafault .form-control:focus {
   border-color: #FF1A43;
 }
 .modal-layout-dafault .row-btn {
   margin-top: 28px;
 }
 
 /*
     Modal Bay Tickets
 */
 .modal-baytickets {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
 }
 .modal-baytickets .tt-col-left {
   flex: 0 0 37%;
   max-width: 37%;
 }
 .modal-baytickets .tt-col-right {
   flex: 0 0 63%;
   max-width: 63%;
   padding: 0 50px 0 25px;
 }
 .modal-baytickets .baytickets__img img {
   width: 100%;
   height: auto;
 }
 .modal-baytickets .countdown-row {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: stretch;
   align-items: flex-start;
   border: 1px solid #e5e5e5;
   margin-top: 22px;
 }
 .modal-baytickets .countdown-row .countdown-section {
   position: relative;
   color: #3D3B42;
   width: 33.333%;
   text-align: center;
   letter-spacing: -0.03em;
   padding: 13px 5px;
 }
 .modal-baytickets .countdown-row .countdown-section:not(:first-child) {
   border-left: 1px solid #e5e5e5;
 }
 .modal-baytickets .countdown-row .countdown-section .countdown-amount {
   font-size: 36px;
   line-height: 40px;
   display: block;
   font-weight: 700;
   font-family: "Poppins", sans-serif;
 }
 .modal-baytickets .countdown-row .countdown-section .countdown-period {
   display: block;
   padding-top: 2px;
   font-size: 17px;
   line-height: 19px;
 }
 .modal-baytickets .row-btn {
   margin-left: -18px;
 }
 .modal-baytickets .row-btn [class^=tt-btn] {
   margin-top: 18px;
   margin-left: 18px;
 }
 .modal-baytickets .baytickets__timing {
   margin: 33px 0 0 50px;
   padding: 0;
 }
 .modal-baytickets .baytickets__timing dt {
   font-size: 16px;
   line-height: 17px;
   color: #3D3B42;
   letter-spacing: -0.01em;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
   padding: 0 0 8px 0;
 }
 .modal-baytickets .baytickets__timing dd {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   font-size: 36px;
   letter-spacing: -0.03em;
   font-weight: bold;
   font-family: "Poppins", sans-serif;
   color: #3D3B42;
   padding: 0 0 18px 0;
 }
 .modal-baytickets .baytickets__timing dd .tt-col > svg {
   position: relative;
   top: 0px;
 }
 .modal-baytickets .baytickets__timing dd .tt-col:not(:first-child) {
   margin-left: 16px;
 }
 .modal-baytickets .baytickets__timing dd:not(:last-child) {
   border-bottom: 1px solid #e6e6e6;
 }
 .modal-baytickets .baytickets__timing dd + dt {
   margin-top: 18px;
 }
 .modal-baytickets .baytickets__timer .tt-title {
   letter-spacing: 0.2em;
   text-transform: uppercase;
   font-size: 15px;
   color: #5C5C5C;
 }
 .modal-baytickets .baytickets__timer .pt-countdow-title {
   display: inline-block;
   font-size: 14px;
   line-height: 17px;
   color: red;
   padding-bottom: 10px;
 }
 .modal-baytickets .baytickets__description {
   margin-top: 25px;
 }
 .modal-baytickets .baytickets__social {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   margin-top: 13px;
   margin-left: -15px;
 }
 .modal-baytickets .baytickets__social > li > a {
   display: inline-block;
   padding: 5px 15px;
   color: #3D3B42;
   font-size: 15px;
   transition: color 0.2s linear;
 }
 .modal-baytickets .baytickets__social > li > a:hover {
   color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .modal-baytickets .baytickets__timing {
     margin: 23px 0 0 30px;
   }
   .modal-baytickets .baytickets__timing dd {
     font-size: 27px;
     padding-bottom: 12px;
   }
   .modal-baytickets .baytickets__timing dd .tt-col > svg {
     max-width: 20px;
     height: auto;
   }
   .modal-baytickets .baytickets__timing dd + dt {
     margin-top: 13px;
   }
   .modal-baytickets .countdown-row .countdown-section .countdown-amount {
     font-size: 30px;
   }
   .modal-baytickets .countdown-row .countdown-section .countdown-period {
     font-size: 16px;
   }
 }
 @media (min-width: 581px) {
   .modal-baytickets .tt-col-left {
     flex: 0 0 37%;
     max-width: 37%;
   }
   .modal-baytickets .tt-col-right {
     flex: 0 0 63%;
     max-width: 63%;
   }
 }
 @media (max-width: 580px) {
   .modal-baytickets .baytickets__timing {
     margin: 23px 0 0 20px;
   }
   .modal-baytickets .baytickets__timing dd {
     font-size: 22px;
     padding-bottom: 12px;
   }
   .modal-baytickets .baytickets__timing dd .tt-col > svg {
     max-width: 20px;
     height: auto;
   }
   .modal-baytickets .baytickets__timing dd .tt-col:not(:first-child) {
     margin-left: 10px;
   }
   .modal-baytickets .baytickets__timing dd + dt {
     margin-top: 13px;
   }
   .modal-baytickets .countdown-row {
     margin-top: 7px;
   }
   .modal-baytickets .countdown-row .countdown-section .countdown-amount {
     font-size: 20px;
     line-height: 30px;
   }
   .modal-baytickets .countdown-row .countdown-section .countdown-period {
     font-size: 14px;
     line-height: 19px;
     margin-top: 0;
   }
   .modal-baytickets .baytickets__description {
     margin-top: 15px;
     font-size: 14px;
     line-height: 23px;
   }
   .modal-baytickets .baytickets__timer .tt-title {
     font-size: 14px;
     letter-spacing: 0.1em;
   }
   .modal-baytickets .tt-col-right {
     padding: 0 40px 0 15px;
   }
 }
 @media (max-width: 480px) {
   .modal-baytickets .baytickets__timing {
     margin: 23px 0 0 10px;
   }
   .modal-baytickets .baytickets__timing {
     margin: 23px 0 0 20px;
   }
   .modal-baytickets .baytickets__timing dd {
     font-size: 18px;
     padding-bottom: 7px;
   }
   .modal-baytickets .baytickets__timing dd .tt-col > svg {
     max-width: 17px;
   }
   .modal-baytickets .baytickets__timing dd .tt-col:not(:first-child) {
     margin-left: 7px;
   }
   .modal-baytickets .baytickets__timing dd + dt {
     margin-top: 13px;
   }
 }
 
 form {
   position: relative;
 }
 
 .tt-modal-message {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   position: absolute;
   opacity: 0;
   transition: opacity 0.1s ease;
   z-index: 2;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: #fff;
   font-size: 19px;
   color: #FF1A43;
   pointer-events: none;
 }
 
 .tt-modal-message.tt-active {
   opacity: 1;
 }
 
 /*---------------------------------------*/
 /*--------     11. Back To Top     ------*/
 /*--------------------------------------*/
 /* Back to top */
 .tt-back-to-top {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   transition: 0.4s;
   width: 63px;
   height: 63px;
   color: #ffffff;
   font-size: 9px;
   line-height: 15px;
   position: fixed;
   bottom: -63px;
   right: 15px;
   z-index: 20;
   border-radius: 50%;
   padding: 0;
   transition: all 0.2s linear;
 }
 .tt-back-to-top:before, .tt-back-to-top:after {
   content: "";
   display: block;
   position: absolute;
   border-radius: 50%;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   pointer-events: none;
 }
 .tt-back-to-top:before {
   z-index: 2;
   opacity: 0;
   background: linear-gradient(270deg, #FF1A43 -3.76%, #7B16D9 100%);
   transition: opacity 0.2s linear;
 }
 .tt-back-to-top:after {
   background: #FF1A43;
   z-index: 0;
 }
 .tt-back-to-top i {
   position: relative;
   z-index: 3;
   color: #ffffff;
   pointer-events: none;
 }
 .tt-back-to-top:hover:before {
   opacity: 1;
 }
 @media (min-width: 790px) {
   .tt-back-to-top.pt-show {
     bottom: 50px;
   }
 }
 @media (max-width: 789px) {
   .tt-back-to-top.pt-show {
     bottom: 20px;
   }
 }
 .tt-back-to-top .pt-text {
   display: none;
 }
 
 /*---------------------------------------*/
 /*--------     12. Footer         --------*/
 /*---------------------------------------*/
 #tt-footer {
   position: relative;
   background-color: #ffffff;
   z-index: 2;
   overflow: hidden;
 }
 #tt-footer .footer-wrapper {
   background-color: #19181d;
   padding: 89px 0 95px;
   text-align: center;
   background-size: cover;
   background-repeat: no-repeat;
 }
 @media (min-width: 1024px) {
   #tt-footer .footer-wrapper {
     background-image: url("../images/footer.jpg");
   }
 }
 @media (max-width: 1024px) and (min-width: 576px) {
   #tt-footer .footer-wrapper {
     background-image: url("../images/footer-tablet.jpg");
     padding: 38px 0 45px;
   }
 }
 @media (max-width: 575px) {
   #tt-footer .footer-wrapper {
     background-image: url("../images/footer-mobile.jpg");
     padding: 38px 0 45px;
   }
 }
 @media (min-width: 1025px) {
   #tt-footer:not(.no-margin) .footer-wrapper {
     margin-top: 130px;
   }
 }
 @media (max-width: 1024px) {
   #tt-footer:not(.no-margin) .footer-wrapper {
     margin-top: 70px;
   }
 }
 @media (max-width: 790px) {
   #tt-footer:not(.no-margin) .footer-wrapper {
     margin-top: 49px;
   }
 }
 #tt-footer:before {
   content: "";
   display: block;
   background: linear-gradient(270deg, #FF1A43 0%, #7B16D9 100%);
   position: absolute;
   left: 50%;
   margin-top: 0px;
   border-radius: 3px;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
   z-index: 12;
   width: 4px;
   height: 63px;
 }
 @media (min-width: 1025px) {
   #tt-footer:before {
     width: 4px;
     height: 63px;
   }
 }
 @media (max-width: 1024px) {
   #tt-footer:before {
     width: 3px;
     height: 46px;
   }
 }
 @media (min-width: 1025px) {
   #tt-footer.no-margin:before {
     top: -32px;
   }
 }
 @media (max-width: 1024px) {
   #tt-footer.no-margin:before {
     top: -23px;
   }
 }
 @media (min-width: 1025px) {
   #tt-footer:not(.no-margin):before {
     top: -162px;
   }
 }
 @media (max-width: 1024px) {
   #tt-footer:not(.no-margin):before {
     top: -23px;
   }
 }
 /*
     Footer objects
 */
 .f-nav ul {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: flex-start;
   align-items: flex-start;
 }
 .f-nav ul > li > a {
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   font-size: 18px;
   line-height: 27px;
   color: #ffffff;
   display: inline-block;
   padding: 7px 28px;
   transition: color 0.2s;
 }
 .f-nav ul > li > a:hover {
   color: #FF1A43;
 }
 .f-nav ul > li.active > a {
   color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .f-nav ul > li > a {
     font-size: 16px;
     line-height: 24px;
     padding: 7px 17px;
     transition: color 0.2s;
   }
 }
 @media (max-width: 575px) {
   .f-nav ul > li {
     width: 100%;
   }
   .f-nav ul > li > a {
     padding: 7px 28px;
   }
 }
 
 .f-logo {
   margin-top: 20px;
 }
 .f-logo .tt-logo > * {
   width: 148px;
   height: auto;
 }
 @media (max-width: 1024px) {
   .f-logo {
     margin-top: 25px;
   }
   .f-logo .tt-logo > * {
     width: 94px;
     height: auto;
   }
 }
 @media (max-width: 575px) {
   .f-logo .tt-logo > * {
     width: 78px;
     height: auto;
   }
 }
 
 .f-col {
   margin-top: 26px;
 }
 .f-col [class^=col-] {
   margin-top: 25px;
 }
 @media (max-width: 1024px) {
   .f-col {
     margin-top: 11px;
   }
 }
 
 .f-info {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: flex-start;
   text-align: left;
   max-width: 300px;
   margin-left: auto;
   margin-right: auto;
   color: #D3D3D5;
 }
 .f-info .f-info__icon {
   width: 36px;
   min-width: 36px;
   color: #FF1A43;
   font-size: 20px;
 }
 .f-info .f-info__icon:before {
   position: relative;
   top: 4px;
   left: 2px;
 }
 .f-info .f-info__icon > * {
   max-width: 24px;
   height: auto;
   position: relative;
   top: 4px;
 }
 .f-info .f-info__content .tt-title {
   color: #ffffff;
   font-size: 18px;
   line-height: 27px;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   padding-bottom: 13px;
 }
 @media (max-width: 1024px) {
   .f-info .f-info__icon {
     width: 24px;
     min-width: 24px;
     font-size: 16px;
   }
   .f-info .f-info__icon > * {
     max-width: 15px;
     top: 1px;
   }
   .f-info .f-info__content {
     font-size: 15px;
     line-height: 24px;
   }
   .f-info .f-info__content .tt-title {
     font-size: 16px;
     line-height: 24px;
     padding-bottom: 5px;
   }
 }
 @media (max-width: 575px) {
   .f-info {
     margin-left: 20px;
     margin-right: 0;
   }
   .f-info .f-info__icon {
     width: 22px;
     min-width: 22px;
   }
   .f-info .f-info__icon > * {
     max-width: 14px;
     top: -1px;
   }
 }
 
 .f-social {
   margin-top: 46px;
 }
 .f-social ul {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-content: flex-start;
   align-items: flex-start;
 }
 .f-social ul > li > a {
   transition: color 0.2s;
   color: #ffffff;
   padding: 5px 22px;
   font-size: 19px;
 }
 .f-social ul > li > a:hover {
   color: #FF1A43;
 }
 @media (max-width: 1024px) {
   .f-social {
     margin-top: 36px;
   }
   .f-social ul > li > a {
     padding: 5px 19px;
     font-size: 18px;
   }
   .f-social ul > li > a > * {
     -webkit-transform: scale(0.9);
     transform: scale(0.9);
   }
 }
 @media (max-width: 575px) {
   .f-social {
     margin-top: 33px;
   }
 }
 
 .f-copyright {
   margin-top: 40px;
   color: #D3D3D5;
 }
 .f-copyright a {
   color: #FF1A43;
   transition: color 0.2s;
 }
 .f-copyright a:hover {
   color: #ffffff;
 }
 @media (max-width: 1024px) {
   .f-copyright {
     margin-top: 31px;
     font-size: 15px;
     line-height: 24px;
   }
 }
 @media (max-width: 575px) {
   .f-copyright {
     margin-top: 34px;
   }
 }
 .ms01{
  width: 100%;
  height: 900px;
 }


 .active{
  font-size: 20px;
  color: orange;
 }

 .zero{
  padding: 50px 0 0 0 ;
  margin: 0 auto 0 auto;
 }


 #mobile-menu {
  display: none;
}

#mobile-menu.open {
  display: block;
}

.activeheader{
  color: #c48612 !important;
  font-size: 14px !important;
}